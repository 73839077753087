import React from 'react'
import { Search as SearchIcon, Check as CheckIcon, CancelOutlined as CancelOutlinedIcon } from '@mui/icons-material'

import { useTheme } from '@mui/material'
import { EDecision } from '@src/data/types'

type Props = { decision?: EDecision }

const DecisonIcon = ({ decision }: Props) => {
  const theme = useTheme()
  return (
    <>
      {(decision === EDecision.manualRevision || decision === EDecision.pending || decision === EDecision.error) && (
        <SearchIcon color="warning" sx={{ fontSize: 60, mb: theme.spacing(1) }} />
      )}

      {decision === EDecision.accept && <CheckIcon color="success" sx={{ fontSize: 60, mb: theme.spacing(1) }} />}

      {decision === EDecision.declined && (
        <CancelOutlinedIcon color="error" sx={{ fontSize: 60, mb: theme.spacing(1) }} />
      )}
    </>
  )
}

export default DecisonIcon
