import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

let ai: ApplicationInsights | null = null
let rp: ReactPlugin | null = null

export default function initializeAppInsights(): ApplicationInsights {
  rp = new ReactPlugin()
  ai = new ApplicationInsights({
    config: {
      instrumentationKey: import.meta.env.VITE_APP_INSIGHT_INSTRUMENTAL_KEY,
      extensions: [rp],
    },
  })

  ai.loadAppInsights()
  return ai
}
