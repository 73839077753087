import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { DocumentDecision, EFinancingProgram } from '../../types'
import { DocumentPageDisplayInfo } from '../../types/DocumentPageDisplayInfo'
import { Document, DocumentPage, DocumentRequired } from '../../types/DocumentSchema'
import { DocumentTransformationResult } from '../../types/DocumentTransformationResult'
import {
  buildUrlWithIds,
  makeGetListWithIdsFromApi,
  makeUpdate,
  makeDelete,
  makeCreate,
  makeDownloadWithObject,
} from '../make-api'

const DOCUMENT_BASE_URL = '/Applications/{financingProgramId}/{creditApplicationId}/Documents'
const REQUIRED_DOCUMENT_BASE_URL = '/Applications/{financingProgramId}/{creditApplicationId}/RequiredDocuments'
const PRIVATE_DOCUMENT_BASE_URL = '/Applications/{financingProgramId}/{creditApplicationId}/PrivateDocuments'
const documentApi = {
  create: async (
    apiClient: AxiosInstance,
    data: DocumentRequired[],
    creditApplicationId: string,
    financingProgramId: EFinancingProgram,
  ): Promise<DocumentRequired[]> => {
    const payload: AxiosRequestConfig<DocumentRequired[]> = {
      method: 'POST',
      url: buildUrlWithIds(REQUIRED_DOCUMENT_BASE_URL, { creditApplicationId, financingProgramId }),
      data,
    }
    return (await apiClient.request<DocumentRequired[]>(payload)).data
  },

  updateRequiredDocument: makeUpdate<DocumentRequired>(REQUIRED_DOCUMENT_BASE_URL.concat('/{id}')),

  deleteRequiredDocument: makeDelete<DocumentRequired>(REQUIRED_DOCUMENT_BASE_URL.concat('/{id}')),

  getRequiredDocumentList: makeGetListWithIdsFromApi<DocumentRequired>(REQUIRED_DOCUMENT_BASE_URL),

  getDocumentList: makeGetListWithIdsFromApi<Document>(DOCUMENT_BASE_URL),

  getDocumentPageList: makeGetListWithIdsFromApi<DocumentPage>(DOCUMENT_BASE_URL.concat('/Pages')),

  uploadDocV2: async (
    apiClient: AxiosInstance,
    data: Document[],
    creditApplicationId: string,
    financingProgramId: EFinancingProgram,
  ): Promise<Document[]> => {
    const payload: AxiosRequestConfig<Document[]> = {
      method: 'POST',
      url: buildUrlWithIds(DOCUMENT_BASE_URL.concat('/GenerateSasTokenUrl'), {
        creditApplicationId,
        financingProgramId,
      }),
      data,
    }
    return (await apiClient.request<Document[]>(payload)).data
  },

  uploadDocPrivate: async (
    apiClient: AxiosInstance,
    data: FormData,
    creditApplicationId: string,
    financingProgramId: EFinancingProgram,
  ): Promise<Document> => {
    const payload: AxiosRequestConfig<FormData> = {
      method: 'POST',
      url: buildUrlWithIds(PRIVATE_DOCUMENT_BASE_URL, { creditApplicationId, financingProgramId }),
      data,
    }
    return (await apiClient.request<Document>(payload)).data
  },

  updatePrivateDocument: async (
    apiClient: AxiosInstance,
    data: FormData,
    creditApplicationId: string,
    document: Document,
  ): Promise<Document> => {
    const { id } = document
    const payload: AxiosRequestConfig<FormData> = {
      method: 'PUT',
      url: buildUrlWithIds(PRIVATE_DOCUMENT_BASE_URL.concat('/{id}'), { creditApplicationId, id }),
      data,
    }
    return (await apiClient.request<Document>(payload)).data
  },

  deletePrivateDocument: makeDelete<Document>(PRIVATE_DOCUMENT_BASE_URL.concat('/{id}')),

  getThumbnailsForSplit: (creditApplicationId: string, financingProgramId: EFinancingProgram) =>
    makeCreate<string[], DocumentPageDisplayInfo[]>(
      buildUrlWithIds(DOCUMENT_BASE_URL.concat('/GetThumbnailsForSplit'), { creditApplicationId, financingProgramId }),
    ),

  applyDocsTransformation: (creditApplicationId: string, financingProgramId: EFinancingProgram) =>
    makeCreate<Document[], DocumentTransformationResult>(
      buildUrlWithIds(DOCUMENT_BASE_URL.concat('/ApplyTransformation'), { creditApplicationId, financingProgramId }),
    ),

  quickMerge: (creditApplicationId: string, financingProgramId: EFinancingProgram) =>
    makeCreate<string[], DocumentTransformationResult>(
      buildUrlWithIds(DOCUMENT_BASE_URL.concat('/quickMerge'), { creditApplicationId, financingProgramId }),
    ),

  makeDecisions: (creditApplicationId: string, financingProgramId: EFinancingProgram) =>
    makeCreate<DocumentDecision[], DocumentTransformationResult>(
      buildUrlWithIds(DOCUMENT_BASE_URL.concat('/Decisions'), { creditApplicationId, financingProgramId }),
    ),

  downloadDocument: (creditApplicationId: string, financingProgramId: EFinancingProgram, id: string) =>
    makeDownloadWithObject(
      buildUrlWithIds(DOCUMENT_BASE_URL.concat('/{id}/Download'), {
        creditApplicationId,
        financingProgramId,
        id,
      }),
    ),
}

export default documentApi
