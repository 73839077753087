import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { uniqueId } from 'lodash-es'
import { CreditReportConsumerStatement } from '@src/data/types'
import { formatDate } from '@src/services/Formatter'
import Row from './tableRow'

type ConsumerStatementsProps = {
  consumerStatements: CreditReportConsumerStatement[]
}

const ConsumerStatements = ({ consumerStatements }: ConsumerStatementsProps) => {
  const { t } = useTranslation()
  return (
    <Row id="consumerStatements" name={t('creditReport.consumerStatements')}>
      {consumerStatements.length > 0 && (
        <TableContainer>
          <Table sx={{ display: 'contents' }} aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">{t('creditReport.InscriptionDate')}</TableCell>
                <TableCell align="left">{t('creditReport.PurgingDate')}</TableCell>
                <TableCell align="center">{t('creditReport.Statement')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {consumerStatements.map((item) => (
                <TableRow key={uniqueId()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" align="left">
                    {formatDate(item.reportedD)}
                  </TableCell>
                  <TableCell align="left"> {formatDate(item.toBePurgedD)} </TableCell>
                  <TableCell align="left"> {item.statement} </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Row>
  )
}

export default ConsumerStatements
