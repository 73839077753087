import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { TRootState } from '..'
import { ReportPayoutThatSkippedIncomeValidationDatas } from '../../types/ReportPayoutWithoutFlinkData'
import { apiStateSelectors } from '../ApiStateStore'

export const reportSlice = createSlice({
  name: 'report',
  initialState: {
    payoutThatSkippedIncomeValidationDatas: [] as ReportPayoutThatSkippedIncomeValidationDatas[],
  },
  reducers: {
    setPayoutThatSkippedIncomeValidationDatas: (
      state,
      action: PayloadAction<ReportPayoutThatSkippedIncomeValidationDatas[]>,
    ) => {
      state.payoutThatSkippedIncomeValidationDatas = action.payload
    },
  },
})

export default reportSlice.reducer

export const reportActions = reportSlice.actions

export const GET_PAYOUT_THAT_SKIPPED_INCOME_VALIDATION_DATAS = 'report/payoutThatSkippedIncomeValidation'

const reportSelectorsPartial = {
  isLoadingPayoutThatSkippedIncomeValidationDatas: (state: TRootState) =>
    apiStateSelectors.isLoading(state, GET_PAYOUT_THAT_SKIPPED_INCOME_VALIDATION_DATAS),
  getPayoutThatSkippedIncomeValidationDatas: (state: TRootState) => state.report.payoutThatSkippedIncomeValidationDatas,
}

export const reportSelectors = {
  ...reportSelectorsPartial,
}
