import { Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CreditApplication } from '../../../data/types'
import CustomerAccountInformation from './customerAccountInformation'

type Props = {
  creditApplication: CreditApplication
  userCanApproveCreditApplication: boolean
  editDisabled: boolean
}

const CustomerAccount = ({ creditApplication, userCanApproveCreditApplication, editDisabled }: Props) => {
  const isApplicant = true
  const { t } = useTranslation()
  return (
    <>
      <Typography variant="h6" component="h3" gutterBottom>
        {t('customerAccount.title')}
      </Typography>
      <CustomerAccountInformation
        editDisabled={editDisabled}
        applicant={creditApplication.applicant}
        userCanApproveCreditApplication={userCanApproveCreditApplication}
        creditApplicationId={creditApplication.id}
        financingProgramId={creditApplication.financingProgramId}
        isApplicant={isApplicant}
      />
      {creditApplication.coApplicant && (
        <CustomerAccountInformation
          editDisabled={editDisabled}
          applicant={creditApplication.coApplicant}
          userCanApproveCreditApplication={userCanApproveCreditApplication}
          creditApplicationId={creditApplication.id}
          financingProgramId={creditApplication.financingProgramId}
          isApplicant={!isApplicant}
        />
      )}
    </>
  )
}
export default React.memo(CustomerAccount)
