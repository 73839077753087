import { ImageList, ImageListItem } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import GridLayout from 'react-grid-layout'
import { orderBy } from 'lodash-es'
import { rotateLeft, rotateRight } from '../actions'
import { DocumentPageDisplayInfo } from '../../../data/types/DocumentPageDisplayInfo'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'
import { DocumentRequired, EApplicantType, EDocumentPageStatus } from '../../../data/types'
import { DocumentType } from '../../../data/types/DocumentType'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import Thumbnail from './thumbnail'

type Props = {
  documentPagesDisplayInfos: DocumentPageDisplayInfo[]
  setDocumentPagesDisplayInfos: React.Dispatch<React.SetStateAction<DocumentPageDisplayInfo[]>>
  documentTypes: Record<string, DocumentType>
  lang: keyof TranslatedEnum
  selectedPages: string[]
  setSelectedPages: React.Dispatch<React.SetStateAction<string[]>>
  setHasChange: React.Dispatch<React.SetStateAction<boolean>>
  pagesPerRow: number
  showBanners: boolean
  isDraggable: boolean
  isActionDisabled: boolean
  multipleRequiredDocs: DocumentRequired[]
}
const Thumbnails = ({
  setDocumentPagesDisplayInfos,
  documentPagesDisplayInfos: docsPageList,
  documentTypes,
  lang,
  selectedPages,
  setSelectedPages,
  setHasChange,
  pagesPerRow,
  showBanners,
  isDraggable,
  isActionDisabled,
  multipleRequiredDocs,
}: Props) => {
  const { t } = useTranslation()

  const updatePage = (page: DocumentPageDisplayInfo, index: number) => {
    setHasChange(true)
    setDocumentPagesDisplayInfos((pages) => {
      pages[index] = page
      return [...pages]
    })
  }

  const getTitle = (page: DocumentPageDisplayInfo) => {
    const concernedMultipleRequiredDoc = multipleRequiredDocs.find(
      (md) => md.typeId === page.documentType && md.applicantType === page.applicantType && md.subKey === page.subKey,
    )
    let label = `${documentTypes[page.documentType][lang]} ${
      concernedMultipleRequiredDoc?.labelSuffix ? `-${concernedMultipleRequiredDoc?.labelSuffix}` : ''
    }`

    const applicants = page.applicantType?.split(';') as EApplicantType[]

    if (applicants) {
      label = label.concat(
        ' (',
        t(`common.${applicants[0]}`),
        applicants.length > 1 ? ', '.concat(t(`common.${applicants[1]}`)) : '',
        ')',
      )
    }

    return label
  }

  const onImageClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { id } = event.currentTarget
    const selectedPageCurrentIndex = selectedPages.indexOf(id)

    if (selectedPageCurrentIndex === -1) setSelectedPages((prev) => [...prev, id])
    else setSelectedPages((prev) => prev.filter((i) => i !== id))
  }

  const onRotatePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const currentTarget = event.currentTarget as HTMLButtonElement
    const ariaLabel = currentTarget.getAttribute('aria-label')
    const ariaRowIndex = currentTarget.getAttribute('aria-rowindex')
    const ariaValueNow = currentTarget.getAttribute('aria-valuenow')
    const index = Number(ariaRowIndex)
    const rotation = Number(ariaValueNow)
    updatePage(
      {
        ...docsPageList[index],
        rotation: ariaLabel === 'rotateLeft' ? rotateLeft(rotation) : rotateRight(rotation),
      },
      index,
    )
  }

  const onDeletePage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const index = Number(value)
    updatePage(
      {
        ...docsPageList[index],
        pageStatus:
          (name as EDocumentPageStatus) !== EDocumentPageStatus.Deleted
            ? EDocumentPageStatus.Deleted
            : EDocumentPageStatus.New,
      },
      index,
    )
  }

  const getLayout = useMemo(() => {
    const layout: GridLayout.Layout[] = []
    let x = 0
    let y = 0
    const w = 1
    const h = 1
    docsPageList.forEach((p) => {
      layout.push({ i: p.id, x, y, w, h })

      x += 1

      if (x === pagesPerRow) {
        y += 1
        x = 0
      }
    })
    return layout
  }, [docsPageList, pagesPerRow])

  const getMaxRows = useMemo(() => {
    return Math.ceil(docsPageList.length / pagesPerRow)
  }, [pagesPerRow, docsPageList])

  const getRowHeight = useMemo(() => {
    switch (pagesPerRow) {
      case 1:
        return 1325
      case 2:
        return 681
      case 3:
        return 464
      case 4:
        return 355
      case 5:
        return 289
      case 6:
        return 245
      default:
        return 354
    }
  }, [pagesPerRow])

  const onLayoutChange = useCallback(
    (layout: GridLayout.Layout[]): void => {
      const toSort: { sortValue: number; id: string }[] = []
      layout.forEach((l) => {
        const newY = l.y * 100
        const sortValue = l.x + newY

        toSort.push({ sortValue, id: l.i })
      })

      const ret: DocumentPageDisplayInfo[] = []
      let pageNumberIterator = 1
      let previousDocId: string
      orderBy(toSort, 'sortValue').forEach((x) => {
        const concernedPage = docsPageList.find((p) => p.id === x.id)
        if (concernedPage) {
          if (previousDocId !== concernedPage.documentId) pageNumberIterator = 1
          previousDocId = concernedPage.documentId
          concernedPage.pageNumber = pageNumberIterator
          ret.push(concernedPage)
          pageNumberIterator += 1
        }
      })
      setDocumentPagesDisplayInfos(ret)
    },
    [docsPageList, setDocumentPagesDisplayInfos],
  )

  const onDragStop = () => {
    setHasChange(true)
  }

  return (
    <div>
      {isDraggable && (
        <GridLayout
          containerPadding={[0, 0]}
          className="layout"
          width={1000}
          cols={pagesPerRow}
          rowHeight={getRowHeight}
          maxRows={getMaxRows}
          layout={getLayout}
          compactType="horizontal"
          verticalCompact
          isBounded
          resizeHandles={['se']}
          onLayoutChange={(data: GridLayout.Layout[]) => onLayoutChange(data)}
          onDragStop={() => onDragStop()}
        >
          {docsPageList.map((page, index) => (
            <ImageListItem
              key={page.id}
              sx={{
                maxHeight: '100%',
                height: 'auto',
                '&:hover': {
                  cursor: 'grab',
                },
              }}
            >
              <Thumbnail
                page={page}
                onRotatePage={onRotatePage}
                onDeletePage={onDeletePage}
                getTitle={getTitle}
                onImageClicked={onImageClicked}
                showBanners={showBanners}
                index={index}
                selectedPages={selectedPages}
                isDraggable={isDraggable}
                isActionDisabled={isActionDisabled}
              />
            </ImageListItem>
          ))}
        </GridLayout>
      )}

      {!isDraggable && (
        <ImageList sx={{ width: '100%', padding: 0, margin: 0 }} rowHeight="auto" cols={pagesPerRow} gap={8}>
          {docsPageList.map((page, index) => (
            <ImageListItem key={page.id} sx={{ height: 'auto' }}>
              <Thumbnail
                page={page}
                onRotatePage={onRotatePage}
                onDeletePage={onDeletePage}
                getTitle={getTitle}
                onImageClicked={onImageClicked}
                showBanners={showBanners}
                index={index}
                selectedPages={selectedPages}
                isDraggable={isDraggable}
                isActionDisabled={isActionDisabled}
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </div>
  )
}

export default Thumbnails
