import { CreditDashboardEntry } from '../../types/CreditDashboardEntry'
import { makeGetListFromApi, makeGetSingleFromApi } from '../make-api'

const BASE_URL = '/CreditDashboardEntries'
const creditDashboardEntriesApi = {
  getByFilters: makeGetListFromApi<CreditDashboardEntry>(BASE_URL),
  getById: makeGetSingleFromApi<CreditDashboardEntry>(BASE_URL.concat('/{creditDashboardEntryId}')),
}

export default creditDashboardEntriesApi
