import * as React from 'react'
import { AppBar, Container, Toolbar, IconButton, useTheme, Link } from '@mui/material'
import {
  Brightness7 as Brightness7Icon,
  ViewList as ViewListIcon,
  Brightness4 as Brightness4Icon,
  Checklist as ChecklistIcon,
} from '@mui/icons-material'
import LanguagePicker from './components/LanguagePicker'
import UserMenu from './components/UserMenu'
import AppMenu from './components/AppMenu'
import ReportMenu from './components/ReportMenu'
import CompanySelector from './components/CompanySelector'

type Props = {
  hasAlertBanner?: boolean
  onToggleTheme: () => void
}

const Layout = ({ onToggleTheme, hasAlertBanner = false }: Props): JSX.Element => {
  const theme = useTheme()

  return (
    <AppBar
      position="sticky"
      sx={{ backgroundColor: 'rgb(0, 0, 89)', color: 'white', top: hasAlertBanner ? 48 : 0, mb: 10 }}
    >
      <Container maxWidth="xl" disableGutters>
        <Toolbar disableGutters>
          <div className="icebergBanner">
            <img src="/images/iceberg-no-bg.png" alt="Iceberg Finance" style={{ width: '110px', height: '40px' }} />
          </div>
          <Link href="/">
            <IconButton>
              <ViewListIcon sx={{ color: '#ffffff' }} />
            </IconButton>
          </Link>
          <Link href="/creditTasksDashboard">
            <IconButton>
              <ChecklistIcon sx={{ color: '#ffffff' }} />
            </IconButton>
          </Link>
          <span style={{ flexGrow: 1 }}>&nbsp;</span>
          <CompanySelector />
          <AppMenu />
          <ReportMenu />
          <UserMenu />
          <LanguagePicker />
          <IconButton onClick={onToggleTheme}>
            {theme.palette.mode === 'dark' ? (
              <Brightness7Icon sx={{ color: '#ffffff' }} />
            ) : (
              <Brightness4Icon sx={{ color: '#ffffff' }} />
            )}
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Layout
