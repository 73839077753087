import React, { useState, useCallback } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Card, CardContent, CardMedia, Stack, Tab, Tabs } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import { useAppDispatch, useAppSelector } from '../../data/store'
import { appSelectors } from '../../data/store/AppStore/app-store'
import { LoginCredentials, LoginCredentialsSchema } from '../../data/types/LoginCredentials'
import { userActions, userEffects, userSelectors } from '../../data/store/UserStore'
import LanguagePicker from '../App/components/LanguagePicker'
import ListMockedLoginDialog from './components/ListMockedLoginDialog'
import { EFinancingCompany, useDevTools } from '../../data/types'
import { loginRequest } from '../../authConfig'
import { InputTextField, PageError, TabPanel } from '../../components'

const LoginPage = () => {
  const navigate = useNavigate()
  const dispatchEffect = useSideEffect()
  const dispatch = useAppDispatch()
  const error = useAppSelector(appSelectors.getBusinessError)
  const [isLoginMockDialogOpen, setIsLoginMockDialogOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const tabIndex = useAppSelector(userSelectors.getLoginPreference)
  document.title = t('login.login')
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginCredentials>({
    mode: 'onBlur',
    defaultValues: LoginCredentialsSchema.getDefault(),
    resolver: yupResolver(LoginCredentialsSchema),
  })

  const onMockedLoginSelected = (username: string, companyIds: EFinancingCompany[]) => {
    const loginCreds: LoginCredentials = { username, password: 'ok', companyIds }
    dispatchEffect(userEffects.mockLogin(loginCreds)).catch(reportErrorToConsole)
  }

  const { instance } = useMsal()

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch(reportErrorToConsole)
  }

  const loginMerchant = (data: LoginCredentials) => {
    dispatchEffect(userEffects.mockLogin(data))
      .then(() => navigate('/', { replace: true }))
      .catch(reportErrorToConsole)
  }

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      dispatch(userActions.setLoginPreference(newValue))
      const { location } = window
      const updateSearch = new URLSearchParams(location.search)
      updateSearch.set('showTabIndex', encodeURIComponent(newValue))
      const newUrl = `${location.pathname}?${updateSearch.toString()}`
      navigate(newUrl, { replace: true })
    },
    [dispatch, navigate],
  )

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <ListMockedLoginDialog
        onMockedLoginSelected={onMockedLoginSelected}
        open={isLoginMockDialogOpen}
        onClose={() => setIsLoginMockDialogOpen(false)}
      />
      <Card>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LanguagePicker />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CardMedia sx={{ width: '200px' }} component="img" image="/images/iceberg-logo.png" alt="Iceberg Finance" />
        </div>
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab label={t('login.employees')} />
              <Tab label={t('login.merchants')} />
            </Tabs>
          </div>
          <TabPanel value={tabIndex} index={0}>
            <Stack spacing={3} sx={{ paddingLeft: '100px', paddingRight: '100px', paddingTop: '20px' }}>
              <Button type="button" onClick={handleLogin}>
                {t('login.login')}
              </Button>
              {useDevTools && (
                <Box sx={{ display: 'flex', 'align-self': 'center', p: 2 }}>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsLoginMockDialogOpen(true)}
                  >
                    Cas de test de login
                  </Button>
                </Box>
              )}
            </Stack>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <form onSubmit={handleSubmit(loginMerchant)}>
              <Stack spacing={3} sx={{ paddingLeft: '100px', paddingRight: '100px', paddingTop: '20px' }}>
                <div style={{ width: 320, height: 45 }}>
                  <PageError errors={error} />
                </div>
                <InputTextField {...register('username')} error={errors?.username} label={t('login.username')} />
                <InputTextField
                  {...register('password')}
                  error={errors?.password}
                  label={t('login.password')}
                  type="password"
                />
                <Button type="submit">{t('login.login')}</Button>
              </Stack>
            </form>
          </TabPanel>
        </CardContent>
      </Card>
    </Box>
  )
}

export default LoginPage
