import React from 'react'
import Typography from '@mui/material/Typography'
import { Box, Grid, useTheme } from '@mui/material'
import { t } from 'i18next'
import { NormsMessage, NormsMessageKey, ValueListItem } from '../../../data/types'
import { useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'

type Props = {
  messageTitle: string
  creditWarningMessages: NormsMessage[] | null
  size: string | null
}

const WarningMessageList = ({ creditWarningMessages, messageTitle, size }: Props) => {
  const theme = useTheme()
  const borderColor = theme.palette.divider

  const creditRefusalReasons = useAppSelector<ValueListItem[]>(appSelectors.getCreditRefusalReasons)

  const handleReasonsToDisplay = (x: NormsMessage) => {
    const reason = creditRefusalReasons.find((r) => r.id === x.id)

    return reason?.text ?? t(`CreditDecisionNorms.${x.message as NormsMessageKey}`)
  }

  return (
    <Grid item xs={12} md={12} lg={size === 'half' ? 6 : 12}>
      <Box
        sx={{
          border: '1px solid',
          borderColor,
          borderRadius: 2,
          p: 1,
        }}
      >
        <Typography>{messageTitle}</Typography>
        <ul>{creditWarningMessages?.map((x) => <li key={x.id}>{handleReasonsToDisplay(x)}</li>)}</ul>
      </Box>
    </Grid>
  )
}

export default WarningMessageList
