import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AutoRslaWorksheet } from '@src/data/types/AutoRslaWorksheet'
import type { TRootState } from '..'
import { apiStateSelectors } from '../ApiStateStore'

export const SAVE_AUTO_RSLA_WORKSHEET = 'autoRslaWorksheet/save'
export const GET_AUTO_RSLA_WORKSHEET = 'autoRslaWorksheet/getById'

export const AutoRslaWorksheetSlice = createSlice({
  name: 'autoRslaWorksheet',
  initialState: {
    current: null as AutoRslaWorksheet | null,
  },
  reducers: {
    setCurrent: (state, action: PayloadAction<AutoRslaWorksheet | null>) => {
      state.current = action.payload
    },
  },
})

export default AutoRslaWorksheetSlice.reducer

export const autoRslaWorksheetActions = AutoRslaWorksheetSlice.actions

const autoRslaWorksheetSelectorsPartial = {
  isSavingPersonalLoanWorksheet: (state: TRootState) => apiStateSelectors.isLoading(state, SAVE_AUTO_RSLA_WORKSHEET),
  getCurrent: (state: TRootState) => state.autoRslaWorksheet.current,
}

export const autoRslaWorksheetSelectors = {
  ...autoRslaWorksheetSelectorsPartial,
}
