import React from 'react'
import { uniqueId } from 'lodash-es'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'
import { formatDate } from '../../../../services/Formatter'
import Row from './tableRow'
import { CreditReportSpecialService } from '../../../../data/types/CreditReportSchema'

type SpecialServicesProps = {
  specialServices: CreditReportSpecialService[]
}

const SpecialServices = ({ specialServices }: SpecialServicesProps) => {
  const { t } = useTranslation()
  return (
    <Row id="specialServices" name={t('creditReport.specialServices')}>
      <TableContainer>
        <Table sx={{ display: 'contents' }} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{t('creditReport.registrationDate')}</TableCell>
              <TableCell align="left">{t('creditReport.memberNumber')}</TableCell>
              <TableCell align="left">{t('creditReport.memberName')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {specialServices.map((item) => (
              <TableRow key={uniqueId()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align="left">
                  {formatDate(item.dateReported)}
                </TableCell>
                <TableCell align="left">{item.customerNumber}</TableCell>
                <TableCell align="left">{item.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Row>
  )
}

export default SpecialServices
