import { CreditApplication, EFinancingProgram } from '../../types'
import { buildUrlWithIds, makeCreate, makeGetPdf } from '../make-api'

const baseUrl = '/Applications/{financingProgramId}/{creditApplicationId}/Contract'

const contractApi = {
  create: (creditApplicationId: string, financingProgramId: EFinancingProgram) =>
    makeCreate<CreditApplication>(buildUrlWithIds(baseUrl, { creditApplicationId, financingProgramId })),
  downloadCvtPDF: (creditApplicationId: string, financingProgramId: EFinancingProgram) =>
    makeGetPdf(buildUrlWithIds(baseUrl, { creditApplicationId, financingProgramId })),
}

export default contractApi
