import React, { useCallback, useMemo } from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import { Control, ControllerRenderProps, FieldError, useController } from 'react-hook-form'
import { addYears, format } from 'date-fns'
import { DatePicker, DateTimePicker as MuiDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import translateErrorCode from './TranslateError'

function makeDatePickerChangeHandler(onChange: ControllerRenderProps['onChange'], name: string, dateTimeFormat = true) {
  return (newDate: Date | null): Promise<void> => {
    const triggerEvent = {
      target: {
        name,
        value: '',
      },
    }

    if (newDate) {
      try {
        triggerEvent.target.value = format(newDate, dateTimeFormat ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd')
      } catch (e) {
        // means the date in invalid, put the string directly so that the validation might show an error
        triggerEvent.target.value = newDate.toString()
      }
    }

    onChange(triggerEvent)
    return Promise.resolve()
  }
}

type DateTimePickerProps = {
  error: FieldError | undefined
  name: string
  label: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>
  disableFuture?: boolean
  required?: boolean
  disablePast?: boolean
}

export const DateTimePicker = ({ name, control, label, disableFuture, required, disablePast }: DateTimePickerProps) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    field: { onChange, onBlur, ref, value: tVal },
    fieldState: { error },
  } = useController({ name, control })
  const value = tVal as string
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeHandler = useCallback(makeDatePickerChangeHandler(onChange, name), [name, onChange])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDateTimePicker
        views={['year', 'day', 'hours']}
        label={label}
        value={value}
        onChange={changeHandler}
        onAccept={onBlur}
        disableFuture={disableFuture}
        disablePast={disablePast}
        inputFormat="yyyy-MM-dd HH:mm"
        mask="____-__-__ __:__"
        reduceAnimations
        ampm={false}
        renderInput={(params: TextFieldProps) => (
          <TextField
            ref={ref}
            {...params}
            value={value}
            onBlur={onBlur}
            error={error !== undefined}
            helperText={translateErrorCode(error)}
            required={required}
            fullWidth
          />
        )}
      />
    </LocalizationProvider>
  )
}

type DatePickerProps = {
  error: FieldError | undefined
  name: string
  label: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>
  disableFuture?: boolean
  shouldDisableDate?: (date: Date) => boolean
  required?: boolean
  disablePast?: boolean
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
}

export const DatePickerInput = ({
  name,
  control,
  label,
  disableFuture,
  error,
  disablePast,
  disabled,
  shouldDisableDate,
  minDate: pMinDate,
  maxDate: pMaxDate,
  ...props
}: DatePickerProps) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    field: { onChange, onBlur, ref, value: tVal },
  } = useController({ name, control })
  const value = tVal as string

  const minDate = useMemo(() => pMinDate ?? new Date(0), [pMinDate])
  const maxDate = useMemo(() => pMaxDate ?? addYears(new Date(), 60), [pMaxDate])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeHandler = useCallback(makeDatePickerChangeHandler(onChange, name, false), [name, onChange])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        mask="____-__-__"
        inputFormat="yyyy-MM-dd"
        reduceAnimations
        disabled={disabled}
        disableFuture={disableFuture}
        disablePast={disablePast}
        shouldDisableDate={shouldDisableDate}
        onChange={changeHandler}
        onAccept={onBlur}
        minDate={minDate}
        maxDate={maxDate}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            onBlur={onBlur}
            error={Boolean(error)}
            helperText={translateErrorCode(error)}
            value={value}
            fullWidth
          />
        )}
        {...props}
        value={value && String(value).includes('T') ? value : `${value}T00:00:00`}
        ref={ref}
      />
    </LocalizationProvider>
  )
}

export { DatePickerInput as DatePicker }
export default DateTimePicker
