import { CreditApplicationTask, CreditTaskComment } from '../../types'

import { CreditTaskDto } from './CreditApplicationTaskDto'

export function transformCreditTaskToApi(creditTask: CreditApplicationTask): CreditTaskDto {
  const ret: CreditTaskDto = { ...creditTask } as CreditTaskDto

  return ret
}
export function transformCreditTaskFromApi(creditTask: CreditTaskDto): CreditApplicationTask {
  const ret = { ...creditTask } as CreditApplicationTask

  return ret
}

export function transformListCreditTasksWithCommentsFromApi(
  creditTasks: CreditTaskDto[],
  creditComments: CreditTaskComment[],
): CreditApplicationTask[] {
  const ret = [] as CreditApplicationTask[]
  creditTasks.forEach((x) => {
    const task = { ...x } as CreditApplicationTask
    const comments = creditComments.filter((y) => y.taskId === x.id)
    if (comments) task.comments = comments
    ret.push(task)
  })

  return ret
}
