import React from 'react'
import { Stack, Button, Typography, FormControl, Card, Paper, useTheme, IconButton, TextField } from '@mui/material'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import { useTranslation } from 'react-i18next'
import { CreditApplication } from '../../../data/types'

type UnassignedProp = {
  handleAddingMessageToCreditApplication: (message: string) => void
}

const AddMessageCreditApplicationComponent = ({ handleAddingMessageToCreditApplication }: UnassignedProp) => {
  const [message, setMessage] = React.useState('')
  const { t } = useTranslation()

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value)
  }

  const handleClick = () => {
    handleAddingMessageToCreditApplication(message)
    setMessage('')
  }

  return (
    <Stack>
      <FormControl>
        <TextField
          id="outlined-number"
          label={t('privateMessage.label')}
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          value={message}
          onChange={handleMessageChange}
          sx={{ ml: 1 }}
          multiline
          rows={10}
          style={{
            width: 400,
          }}
        />
        <Button sx={{ mt: 1, ml: 1 }} type="button" variant="outlined" onClick={handleClick}>
          {t('privateMessage.addMessage')}
        </Button>
      </FormControl>
    </Stack>
  )
}

type SideBarProps = {
  handleAddingMessageToCreditApplication: (message: string) => void
  handleSideBarOpen: (current: boolean) => void
  isTopSideBarOpen: boolean
  isSideBarOpen: boolean
  app: CreditApplication
  cardHeight: number
}

const CreditApplicationPrivateMessageSideBar = ({
  handleAddingMessageToCreditApplication,
  handleSideBarOpen,
  isTopSideBarOpen,
  isSideBarOpen,
  app,
  cardHeight,
}: SideBarProps) => {
  const theme = useTheme()
  const borderColor = theme.palette.divider
  const { t } = useTranslation()

  const calculateYOffest = () => {
    if (isTopSideBarOpen) return cardHeight * 0.06185 + 22.25
    return 20.7
  }

  return (
    <Card
      style={{
        position: 'fixed',
        left: isSideBarOpen ? 500 : 0,
        top: `${calculateYOffest()}em`,
        zIndex: 999,
        padding: 0,
        margin: 0,
        msTransitionDuration: '300ms',
        transitionProperty: 'all',
        overflowX: 'hidden',
        display: 'flex',
        border: '1px solid',
        borderRadius: 2,
        borderColor,
      }}
    >
      <IconButton onClick={() => handleSideBarOpen(isSideBarOpen)}>
        <QuestionAnswerIcon fontSize="medium" color="primary" />
      </IconButton>
      <Paper
        elevation={3}
        style={{
          msTransitionDuration: '300ms',
          transitionProperty: 'all',
          position: 'fixed',
          width: 500,
          height: 400,
          border: '1px solid',
          borderRadius: 2,
          borderColor,
          left: isSideBarOpen ? 0 : -500,
        }}
      >
        <Stack alignItems="center" padding={2}>
          <Typography fontWeight="bold" fontSize="h6.fontSize">{`${t('common.application')} #${
            app.referenceNumber
          }`}</Typography>
          <br />
          <AddMessageCreditApplicationComponent
            handleAddingMessageToCreditApplication={handleAddingMessageToCreditApplication}
          />
        </Stack>
      </Paper>
    </Card>
  )
}

export default React.memo(CreditApplicationPrivateMessageSideBar)
