import {
  B2cWorksheet,
  B2cWorksheetDecisionDTO,
  B2cWorksheetRevision,
  EditB2cWorksheetDto,
} from '../../types/B2cWorksheetSchema'
import {
  B2cWorksheetSlice,
  COMPUTE_B2C_FUNDING,
  GET_B2C_WORKSHEET_BY_ID,
  GET_B2C_WORKSHEET_REVISIONS,
  SAVE_B2C_WORKSHEET,
  SAVE_B2C_WORKSHEET_DECISION,
} from './b2c-worksheet-store'
import { makeGetById, makeGetListWithIds, makeSave } from '../effects/standard-side-effects'
import allApi from '../../api'
import { FundingInfoDto } from '../../types/FundingInfoDto'
import { FundingComputedDto } from '../../types/FundingComputedDto'

const b2cWorksheetEffects = {
  create: (data: EditB2cWorksheetDto) =>
    makeSave<EditB2cWorksheetDto, B2cWorksheet>(
      SAVE_B2C_WORKSHEET,
      allApi.b2cWorksheet.create,
      B2cWorksheetSlice.actions.setCurrent,
      data,
    ),
  update: (data: EditB2cWorksheetDto) =>
    makeSave<EditB2cWorksheetDto, B2cWorksheet>(
      SAVE_B2C_WORKSHEET,
      allApi.b2cWorksheet.update,
      B2cWorksheetSlice.actions.setCurrent,
      data,
    ),
  computeB2cFunding: (data: FundingInfoDto) =>
    makeSave<FundingInfoDto, FundingComputedDto>(
      COMPUTE_B2C_FUNDING,
      allApi.b2cWorksheet.computeB2cFunding,
      B2cWorksheetSlice.actions.setFundingComputed,
      data,
    ),
  updateDecision: (data: B2cWorksheetDecisionDTO) =>
    makeSave<B2cWorksheetDecisionDTO, B2cWorksheet>(
      SAVE_B2C_WORKSHEET_DECISION,
      allApi.b2cWorksheet.updateDecision,
      B2cWorksheetSlice.actions.setCurrent,
      data,
    ),
  getById: (id: string) =>
    makeGetById<B2cWorksheet>(
      GET_B2C_WORKSHEET_BY_ID,
      allApi.b2cWorksheet.getById,
      B2cWorksheetSlice.actions.setCurrent,
      {
        id,
      },
    ),
  getWorksheetRevisions: (id: string) =>
    makeGetListWithIds<B2cWorksheetRevision>(
      GET_B2C_WORKSHEET_REVISIONS,
      allApi.b2cWorksheet.getWorksheetRevisions,
      B2cWorksheetSlice.actions.setCurrentRevisions,
      { id },
    ),
}

export default b2cWorksheetEffects
