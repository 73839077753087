import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { TRootState } from '..'
import { apiStateSelectors } from '../ApiStateStore'
import { CreditDashboardEntry } from '../../types/CreditDashboardEntry'

export const creditDashboardEntrySlice = createSlice({
  name: 'creditTaskDashBoardEntry',
  initialState: {
    creditApplicationList: [] as CreditDashboardEntry[],
  },
  reducers: {
    setCreditApplicationList: (state, action: PayloadAction<CreditDashboardEntry[]>) => {
      state.creditApplicationList = action.payload
    },
  },
})

export default creditDashboardEntrySlice.reducer

export const creditDashboardEntryActions = creditDashboardEntrySlice.actions

export const GET_CREDIT_DASHBOARD_ENTRY = 'creditDashboard/getByFilters'

const creditApplicationSelectorsPartial = {
  isLoadingCreditApplicationList: (state: TRootState) => apiStateSelectors.isLoading(state, GET_CREDIT_DASHBOARD_ENTRY),
  getCreditApplicationList: (state: TRootState) => state.creditDashBoardEntry.creditApplicationList,
}

export const creditDashboardEntrySelectors = {
  ...creditApplicationSelectorsPartial,
}
