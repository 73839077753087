import React from 'react'
import { useTranslation } from 'react-i18next'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ListItem, ListItemText, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { DeleteForever } from '@mui/icons-material'
import { CreditApplicationMessage, User } from '../../../data/types'
import { formatDateTime } from '../../../services/Formatter'
import IconButtonWithTooltip from '../../../components/IconButtonWithTooltip'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

type Props = {
  messages: CreditApplicationMessage[]
  currentUser: User | null
  handleDeletingPrivateMessage: (message: CreditApplicationMessage) => void
  creditAppCreationDate: Date
}

const PrivateMessage = ({ messages, currentUser, handleDeletingPrivateMessage, creditAppCreationDate }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const borderColor = theme.palette.mode === 'dark' ? 'info.main' : 'info.light'
  const canDelete = (message: CreditApplicationMessage) => {
    if (currentUser?.id === message.createdByUserId)
      return (
        <IconButtonWithTooltip
          tooltip={t('common.delete')}
          icon={<DeleteForever />}
          onClick={() => handleDeletingPrivateMessage(message)}
        />
      )
    return ''
  }
  return (
    <>
      {messages.length > 0 && (
        <Accordion sx={{ mb: 2 }} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ borderColor, borderWidth: 1, borderStyle: 'solid' }}>
            <Typography sx={{ width: '100%', textAlign: 'center' }}>{t('common.privateMessage')}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ borderColor, borderWidth: 1, borderStyle: 'solid' }}>
            <Typography component="span">
              <TableContainer component={Paper}>
                <Table sx={{ width: '100%', maxHeight: 700 }} aria-label="customized table">
                  <TableBody>
                    {messages.map((message) => (
                      <StyledTableRow key={message.id}>
                        <StyledTableCell component="th" scope="row">
                          <ListItem secondaryAction={canDelete(message)}>
                            <ListItemText
                              primary={<Typography whiteSpace="pre-wrap">{message.content}</Typography>}
                              secondary={`${formatDateTime(message.createdOn)} - ${message.createdByUserFullname}${
                                message.createdOn < creditAppCreationDate
                                  ? ` - ${t('privateMessage.previousMessage')}`
                                  : ''
                              }`}
                            />
                          </ListItem>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
      {null}
    </>
  )
}

export default React.memo(PrivateMessage)
