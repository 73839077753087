import * as yup from './common/yup-extended'
import yupExtInt from './common/SchemaTypes'

export const banReasonSchema = yup.default.object({
  description: yup.default.string().required(),
  expirationDate: yup.default.string().isValidDate(),
})

export type BanReason = yup.default.InferType<typeof banReasonSchema>

export const banSchema = banReasonSchema.shape({
  id: yupExtInt.integer,
  firstName: yup.default.string().required(),
  lastName: yup.default.string().required(),
  birthDateString: yup.default.string().default('').isValidDate().required(),
  nas: yup.default.string().required(),
  financingProgramId: yup.default.string().required(),
})

export type Ban = yup.default.InferType<typeof banSchema>
