import { makeCreate, makeUpdate, makeGetSingleFromApi, makeGetListWithIdsFromApi } from '../make-api'
import { WorksheetDecision } from '../../types'
import { FundingInfoDto } from '../../types/FundingInfoDto'
import { B2cWorksheet, B2cWorksheetRevision, EditB2cWorksheetDto } from '../../types/B2cWorksheetSchema'
import { FundingComputedDto } from '../../types/FundingComputedDto'

const baseUrl = '/Applications/b2c/{creditApplicationId}/Worksheet'
const b2cWorksheetApi = {
  create: makeCreate<EditB2cWorksheetDto, B2cWorksheet>(baseUrl),
  update: makeUpdate<EditB2cWorksheetDto, B2cWorksheet>(baseUrl),
  computeB2cFunding: makeCreate<FundingInfoDto, FundingComputedDto>('Applications/ComputeFunding'),
  updateDecision: makeUpdate<WorksheetDecision, B2cWorksheet>(`${baseUrl}/Decision`),
  getById: makeGetSingleFromApi<B2cWorksheet>(baseUrl),
  getWorksheetRevisions: makeGetListWithIdsFromApi<B2cWorksheetRevision>(`${baseUrl}/Revisions`),
}

export default b2cWorksheetApi
