import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { AxiosInstance } from 'axios'
import type { TRootState } from '..'
import allApi from '../../api'
import { CreditApplicationMessage, EFinancingProgram } from '../../types'
import { apiStateActions } from '../ApiStateStore'
import { appActions } from '../AppStore'
import { makeDelete, makeGetListWithIds, makeSave } from '../effects/standard-side-effects'
import {
  DELETE_MESSAGE,
  GET_MESSAGE,
  messageSlice,
  SAVE_MESSAGE,
  SEND_REQUIRED_DOCUMENTS_MESSAGE,
} from './message-store'

const messageEffects = {
  getMessagesForCreditApplicationId: (creditApplicationId: string, financingProgramId: EFinancingProgram) =>
    makeGetListWithIds<CreditApplicationMessage>(
      GET_MESSAGE,
      allApi.message.getMessageList,
      messageSlice.actions.setMessageList,
      { creditApplicationId, financingProgramId },
    ),
  createMessage: (data: Partial<CreditApplicationMessage>) =>
    makeSave<CreditApplicationMessage>(SAVE_MESSAGE, allApi.message.create, messageSlice.actions.addMessage, data),
  sendRequiredDocumentsMessage: (data: string, financingProgramId: EFinancingProgram) => {
    return async (
      apiClient: AxiosInstance,
      dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
      /* select: TypedUseSelectorHook<TRootState>, */
    ): Promise<CreditApplicationMessage> => {
      let ret = <CreditApplicationMessage>{}
      try {
        // log api is loading
        dispatch(apiStateActions.logApiCallInitiated(SEND_REQUIRED_DOCUMENTS_MESSAGE))
        dispatch(appActions.setBusinessErrors([]))

        // perform actual request
        ret = await allApi.message.sendRequiredDocumentsMessage(apiClient, data, financingProgramId)
        dispatch(messageSlice.actions.addMessage(ret))
        return ret
      } finally {
        dispatch(apiStateActions.logApiCallCompleted(SEND_REQUIRED_DOCUMENTS_MESSAGE))
      }
    }
  },
  deleteMessage: (data: CreditApplicationMessage, financingProgramId: EFinancingProgram) =>
    makeDelete<CreditApplicationMessage>(
      DELETE_MESSAGE,
      allApi.message.deleteMessage(data.creditApplicationId, financingProgramId, data.id),
      messageSlice.actions.removeMessage,
      data,
    ),
}

export default messageEffects
