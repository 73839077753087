import yupExtInt from './common/SchemaTypes'
import * as yup from './common/yup-extended'
import {
  EApplicantType,
  EApplicantTypeList,
  ECreditApplicationStep,
  ECreditApplicationStepList,
  EDocumentPageStatus,
  EDocumentPageStatusList,
  EDocumentStatus,
  EDocumentStatusList,
  EDocumentType,
  EDocumentTypeList,
  EFinancingProgram,
  EFinancingProgramList,
} from './constants'

export const DocumentRequiredSchema = yup.default.object({
  id: yup.default.string().nullable(),
  creditApplicationId: yup.default.string().required(),
  financingProgramId: yup.default.string().required(),
  typeId: yupExtInt.numberEnum<EDocumentType>(EDocumentTypeList).required(),
  note: yup.default.string().nullable(),
  applicantType: yup.default
    .mixed<EApplicantType>()
    .oneOf(EApplicantTypeList)
    .nullable()
    .transform((_, val: string | null | undefined) => {
      if (val === '' || val === undefined) {
        return null
      }
      return val
    }),
  requiredBeforeStep: yup.default
    .mixed<ECreditApplicationStep>()
    .nullable()
    .transform((_, val: string | null | undefined) => {
      if (val === '' || val === undefined) {
        return null
      }
      return val
    })
    .oneOf(ECreditApplicationStepList),

  subKey: yup.default.string().nullable(),
  createdByUserId: yup.default.string().nullable(),
  createdByUserFullname: yup.default.string().nullable(),
  updatedByUserId: yup.default.string().nullable(),
  updatedByUserFullname: yup.default.string().nullable(),
  updatedOn: yup.default.date().nullable(),
  createdOn: yup.default.date().nullable(),
  versionTag: yup.default.string(),
  labelSuffix: yup.default.string().nullable(),
})

export type DocumentRequired = yup.default.InferType<typeof DocumentRequiredSchema>

export const DocumentPageSchema = yup.default.object({
  id: yup.default.string().required(),
  creditApplicationId: yup.default.string().required(),
  documentId: yup.default.string(),
  status: yup.default.mixed<EDocumentPageStatus>().oneOf(EDocumentPageStatusList),
  thumbnailUrl: yup.default.string().default(''),
  pageNumber: yupExtInt.integer.required(),
  rotation: yupExtInt.integer.required().default(0),
})

export type DocumentPage = yup.default.InferType<typeof DocumentPageSchema>

export const DocumentSchema = yup.default.object({
  id: yup.default.string().nullable(),
  creditApplicationId: yup.default.string().required(),
  financingProgramId: yup.default.mixed<EFinancingProgram>().oneOf(EFinancingProgramList).required(),
  applicantType: yup.default.mixed<EApplicantType>().nullable().oneOf(EApplicantTypeList),
  typeId: yup.default.mixed<EDocumentType>().required('numberRequired'),
  receivedOn: yup.default.date().nullable(),
  isPrivate: yup.default.boolean().nullable(),
  status: yup.default.mixed<EDocumentStatus>().oneOf(EDocumentStatusList),
  note: yup.default.string().nullable(),
  refusalReason: yup.default.string().nullable(),
  fileName: yup.default.string(),
  pages: yup.default.array(DocumentPageSchema).default([]),
  subKey: yup.default.string().nullable(),
  createdByUserId: yup.default.string().nullable(),
  createdByUserFullname: yup.default.string().nullable(),
  updatedByUserId: yup.default.string().nullable(),
  updatedByUserFullname: yup.default.string().nullable(),
  updatedOn: yup.default.date().nullable(),
  createdOn: yup.default.date().nullable(),
  versionTag: yup.default.string(),
  sasTokenUrl: yup.default.string().nullable(),
})

export type Document = yup.default.InferType<typeof DocumentSchema>
