import React from 'react'
import { CircularProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const PendingMessage = () => {
  const { t } = useTranslation()
  return (
    <Typography
      variant="body1"
      component="div"
      sx={{
        border: '2px solid',
        borderRadius: 2,
        m: 2,
        borderColor: 'warning.main',
        float: 'center',
        textAlign: 'center',
      }}
    >
      <CircularProgress size={14} sx={{ mr: 1 }} />
      {t('credit.normDecisionIsPending')}
      <CircularProgress size={14} sx={{ ml: 1 }} />
    </Typography>
  )
}

export default PendingMessage
