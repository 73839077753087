import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { findIndex } from 'lodash-es'
import type { TRootState } from '..'
import { RequiredExternalStep } from '../../types'

export const RequiredExternalStepSlice = createSlice({
  name: 'requiredExternalStep',
  initialState: {
    requiredExternalSteps: [] as RequiredExternalStep[],
  },
  reducers: {
    setRequiredExternalSteps: (state, action: PayloadAction<RequiredExternalStep[]>) => {
      state.requiredExternalSteps = action.payload
    },
    addRequiredExternalSteps: (state, action: PayloadAction<RequiredExternalStep>) => {
      const index = findIndex(state.requiredExternalSteps, { id: action.payload.id })
      if (index === -1) {
        state.requiredExternalSteps.push(action.payload)
      } else state.requiredExternalSteps[index] = action.payload
    },
    removeExternalSteps: (state, action: PayloadAction<RequiredExternalStep>) => {
      const index = findIndex(state.requiredExternalSteps, { id: action.payload.id })
      state.requiredExternalSteps.splice(index, 1)
    },
  },
})

export default RequiredExternalStepSlice.reducer

export const requiredExternalStepActions = RequiredExternalStepSlice.actions

export const GET_REQUIRED_EXTERNAL_STEPS = 'requiredExternalStep/getRequiredExternalSteps'
export const SAVE_REQUIRED_EXTERNAL_STEP = 'requiredExternalStep/save'
export const RESET_REQUIRED_EXTERNAL_STEP = 'requiredExternalStep/reset'

const requiredExternalStepPartial = {
  getRequiredExternalSteps: (state: TRootState) => state.requiredExternalStep.requiredExternalSteps,
}

export const requiredExternalStepSelectors = {
  ...requiredExternalStepPartial,
}
