import CopyAllIcon from '@mui/icons-material/CopyAll'
import EditIcon from '@mui/icons-material/Edit'
import HistoryIcon from '@mui/icons-material/History'
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined'
import { Box, Button, Grid, IconButton } from '@mui/material'
import Typography from '@mui/material/Typography'
import { reportErrorToConsole } from '@src/services/error-logger'
import CopyMessage from '@src/services/strings'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import IconButtonWithTooltip from '../../../components/IconButtonWithTooltip'
import { useAppSelector } from '../../../data/store'
import { creditEffects, creditSelectors } from '../../../data/store/CreditApplication'
import {
  Applicant,
  CreditApplication,
  CreditApplicationTask,
  EDecision,
  EExternalStepStatus,
  RequiredExternalStep,
  User,
} from '../../../data/types'
import FlinksHistoryDialog from './FlinksHistoryDialog'
import IncomeInformationTable from './IncomeInformationTable'
import IncomesHistoryDialog from './IncomesHistoryDialog'

type Props = {
  applicant: Applicant
  onOpenJobUpdateDialog: (applicant: 'applicant' | 'coApplicant') => void
  isApplicant: 'applicant' | 'coApplicant'
  jobTasks: CreditApplicationTask[]
  otherIncomeTasks: CreditApplicationTask[]
  creditApplicationId: string
  editDisabled: boolean
  handleSaveBankAccountRequest: (applicant: 'applicant' | 'coApplicant') => void
  handleResetBankAccountRequest: (applicant: 'applicant' | 'coApplicant') => void
  requiredExternalStep: RequiredExternalStep | undefined
  user: User | null
  applicationIsDraft: boolean
  creditApplication: CreditApplication
  worksheetIsApproved: boolean
}

const IncomeSection = ({
  applicant,
  onOpenJobUpdateDialog,
  isApplicant,
  jobTasks,
  otherIncomeTasks,
  creditApplicationId,
  editDisabled,
  handleSaveBankAccountRequest,
  handleResetBankAccountRequest,
  requiredExternalStep,
  user,
  applicationIsDraft,
  creditApplication,
  worksheetIsApproved,
}: Props) => {
  const { t } = useTranslation()
  const dispatchEffect = useSideEffect()
  const INCOME_HISTORY_DIALOG = 'OpenIncomeHistoryDialog'
  const INCOME_FLINKS_DIALOG = 'OpenIncomeFlinksDialog'
  const [currentOpenDialog, setCurrentOpenDialog] = useState<string>('')
  const incomesHistory = useAppSelector(creditSelectors.getIncomesHistory)
  const isLoadingHistory = useAppSelector(creditSelectors.isLoadingIncomesRevision)
  const externalReportedIncomesForApplicant = useAppSelector(creditSelectors.getFlinksIncomesForApplicant)
  const externalReportedIncomesForCoApplicant = useAppSelector(creditSelectors.getFlinksIncomesForCoApplicant)
  const status = requiredExternalStep?.status
  const externalReportedIncome = () => {
    return isApplicant === 'applicant' ? externalReportedIncomesForApplicant : externalReportedIncomesForCoApplicant
  }
  const closeDialogs = useCallback(() => {
    setCurrentOpenDialog('')
  }, [])

  const flinksButtonIsDisabled = (): boolean =>
    !user?.rights.canEditCreditApp ||
    applicationIsDraft ||
    creditApplication?.finalCreditDecision?.decision !== EDecision.accept ||
    editDisabled

  const getFlinksButtonLabel = () => {
    if (status !== EExternalStepStatus.Completed) {
      return status ? t('incomeSection.resendLink') : 'FLINKS'
    }
    return t('incomeSection.flinksDashboard')
  }

  return (
    <>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" columnSpacing={1}>
        <Grid item> {t(`common.${isApplicant}`)} :</Grid>
        <Grid>
          <IconButtonWithTooltip
            tooltip={t('incomeSection.incomeHistory')}
            icon={<HistoryIcon fontSize="medium" />}
            onClick={() => {
              dispatchEffect(
                creditEffects.getIncomesHistory(creditApplicationId, creditApplication.financingProgramId),
              ).catch(reportErrorToConsole)
              setCurrentOpenDialog(INCOME_HISTORY_DIALOG)
            }}
          />
          <IconButton
            onClick={() => onOpenJobUpdateDialog(isApplicant)}
            disabled={editDisabled || !user?.rights.canEditCreditApp}
          >
            <EditIcon
              fontSize="medium"
              color={editDisabled || !user?.rights.canEditCreditApp ? 'disabled' : 'primary'}
            />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="center" columnSpacing={1}>
        <Grid>
          <Button
            sx={{ mr: 1 }}
            variant="outlined"
            onClick={() => handleSaveBankAccountRequest(isApplicant)}
            disabled={flinksButtonIsDisabled() || worksheetIsApproved}
          >
            {getFlinksButtonLabel()}
          </Button>
        </Grid>

        <Grid>
          <Typography variant="body2" component="div" sx={{ border: '1px solid', borderRadius: 1, p: 0.85, mr: 1 }}>
            {t('incomeSection.flinksStatus')} :{' '}
            {requiredExternalStep?.status
              ? t(`bankAccountRequestStatus.${requiredExternalStep.status}`)
              : t('bankAccountRequestStatus.notStarted')}
          </Typography>
        </Grid>

        <Grid>
          <Button
            sx={{ mr: 1 }}
            variant="outlined"
            onClick={() => handleResetBankAccountRequest(isApplicant)}
            disabled={status !== EExternalStepStatus.Completed}
          >
            {t('incomeSection.reset')}
          </Button>
        </Grid>

        <Grid>
          <IconButtonWithTooltip
            tooltip={t('common.hoverCopyFlinksLink')}
            icon={<CopyAllIcon />}
            onClick={() => {
              const url = requiredExternalStep?.extraProperties.bankAccountRequestLink
              if (url) {
                CopyMessage(url).catch(reportErrorToConsole)
              }
            }}
          />
        </Grid>
        {externalReportedIncome() && (
          <Grid>
            <IconButtonWithTooltip
              tooltip={t('incomeSection.incomeFlinkHistory')}
              icon={<NewReleasesOutlinedIcon fontSize="medium" />}
              onClick={() => {
                dispatchEffect(
                  creditEffects.getIncomesHistory(creditApplicationId, creditApplication.financingProgramId),
                ).catch(reportErrorToConsole)
                setCurrentOpenDialog(INCOME_FLINKS_DIALOG)
              }}
            />
          </Grid>
        )}
      </Grid>
      <Box>
        <IncomeInformationTable
          incomes={applicant.currentJobs}
          otherIncomes={applicant.otherIncomes}
          jobTasks={jobTasks}
          otherIncomeTasks={otherIncomeTasks}
        />
      </Box>
      {incomesHistory && (
        <IncomesHistoryDialog
          open={currentOpenDialog === INCOME_HISTORY_DIALOG}
          onClose={closeDialogs}
          isLoadingHistory={isLoadingHistory}
          history={
            isApplicant === 'applicant'
              ? incomesHistory.applicantIncomesHistory
              : incomesHistory.coApplicantIncomesHistory
          }
          displayName={isApplicant !== 'applicant'}
        />
      )}
      {externalReportedIncome() && (
        <FlinksHistoryDialog
          open={currentOpenDialog === INCOME_FLINKS_DIALOG}
          onClose={closeDialogs}
          isLoadingHistory={isLoadingHistory}
          flinksData={externalReportedIncome()!}
        />
      )}
    </>
  )
}

export default IncomeSection
