import { Divider, FormControlLabel, Grid, Paper, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoanCustomer } from '../../../data/types/LoanCustomerSchema'
import { formatDate } from '../../../services/Formatter'

type Props = {
  customers: LoanCustomer[]
  handleSelectedCustomer: (e: React.ChangeEvent<HTMLInputElement>) => void
  selectedCustomer: string
}

const LoanDetails = ({ customers, handleSelectedCustomer, selectedCustomer }: Props) => {
  const { t } = useTranslation()

  return (
    <div style={{ textAlign: 'left', width: '55rem' }}>
      <RadioGroup value={selectedCustomer} onChange={handleSelectedCustomer}>
        <Paper elevation={3} sx={{ width: '100%', height: 'auto', padding: 2, marginBottom: 2, textAlign: 'center' }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography margin={1} sx={{ fontWeight: 'bold' }}>
              {t('customerAccount.newCustomer')}
            </Typography>
            <FormControlLabel control={<Radio name="item" sx={{ float: 'right' }} value="" />} label={undefined} />
          </Stack>
        </Paper>
        <Stack spacing={2} direction="column">
          {customers.map((item) => (
            <Paper
              elevation={3}
              sx={{ width: '100%', height: 'auto', paddingTop: 1, paddingLeft: 2, paddingRight: 0, paddingBottom: 1 }}
              key={item.id}
            >
              <Typography variant="h6" component="p">
                {`${item.firstName} ${item.lastName}`}
              </Typography>
              <Divider sx={{ width: '100%', marginBottom: 1 }} />
              <Grid container>
                <Grid item xs={4} width="100%">
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography component="p">{t('customerAccount.customerId')}: </Typography>
                      <Typography component="p">{t('credit.shortBirthdate')}: </Typography>
                      <Typography component="p">{t('creditReport.sin')}: </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography component="p">{item.id}</Typography>
                      <Typography component="p">{item.birthDate ? formatDate(item.birthDate) : 'N/A'}</Typography>
                      <Typography component="p">{item.sin ? item.sin : 'N/A'}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={7} width="50%">
                  <Stack direction="column" spacing={2}>
                    <Grid container>
                      <Grid item xs={2}>
                        <Typography component="p">{t('credit.homePhone')}: </Typography>
                        <Typography component="p">{t('credit.address')}: </Typography>
                        <Typography component="p">{t('editCreditApplication.information.email')}: </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography component="p">{item.homePhone ? item.homePhone : 'N/A'}</Typography>
                        <Typography component="p">
                          {item.address.street ? item.address.street : ''} {item.address.city} {item.address.country}
                        </Typography>
                        <Typography component="p">{item.email}</Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item xs={1} sx={{ marginTop: '2%' }}>
                  <RadioGroup value={selectedCustomer} onChange={handleSelectedCustomer} sx={{ margin: 0, padding: 0 }}>
                    <FormControlLabel
                      sx={{ margin: 0, padding: 0 }}
                      control={<Radio value={item.id} name="item" key={item.id} />}
                      label={undefined}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Stack>
      </RadioGroup>
    </div>
  )
}

export default React.memo(LoanDetails)
