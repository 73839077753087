import { FullCreditApplication } from '../../types'
import { makeGetSingleFromApi } from '../make-api'
import transformFullCreditApplicationFromApi from './full-credit-application-transform'

const baseUrl = '/Applications/{financingProgramId}/{id}/FullCreditApplication'
const fullCreditApplicationApi = {
  getById: makeGetSingleFromApi<FullCreditApplication>(baseUrl, transformFullCreditApplicationFromApi),
}

export default fullCreditApplicationApi
