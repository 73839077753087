import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material'
import { Cancel as CancelIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { range } from 'lodash-es'
import { IncomeHistoryEntry } from '../../../data/types/IncomesHistory'
import { FormatCurrency, formatDateTime } from '../../../services/Formatter'
import { PageSpinner } from '../../../components'

type Props = {
  onClose: () => void
  history: IncomeHistoryEntry[]
  open: boolean
  isLoadingHistory: boolean
  displayName: boolean
}

const IncomesHistoryDialog = ({ onClose, history, open, isLoadingHistory, displayName }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  let largeJobsArraySize = 0
  let largeOtherArraySize = 0

  history.forEach((item) => {
    if (item.jobs.length > largeJobsArraySize) largeJobsArraySize = item.jobs.length

    if (item.otherIncomes.length > largeOtherArraySize) largeOtherArraySize = item.otherIncomes.length
  })

  const getCellColor = (index: number, amount: number, isJobsIncome: boolean, arrayIndex: number): string => {
    let color = 'default'

    let previewHistory = history[arrayIndex]

    if (arrayIndex + 1 < history.length) previewHistory = history[arrayIndex + 1]

    if (
      isJobsIncome &&
      ((previewHistory.jobs.length > index && previewHistory.jobs[index].annualSalary !== amount) ||
        previewHistory.jobs.length <= index)
    )
      color = theme.palette.error.main

    if (
      !isJobsIncome &&
      ((previewHistory.otherIncomes.length > index && previewHistory.otherIncomes[index].annualAmount !== amount) ||
        previewHistory.otherIncomes.length <= index)
    )
      color = theme.palette.error.main

    return color
  }

  const totalIncomes = (income: IncomeHistoryEntry) => {
    let total = 0

    income.jobs.forEach((a) => {
      total += a.annualSalary
    })

    income.otherIncomes.forEach((a) => {
      total += a.annualAmount
    })

    return total
  }
  return (
    <Dialog
      onClose={onClose}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
      keepMounted
      transitionDuration={0}
      disablePortal
      BackdropProps={{ transitionDuration: 0 }}
    >
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title">
        {t('history.income')}
      </DialogTitle>
      <DialogContent>
        <PageSpinner isLoading={isLoadingHistory} />
        {!isLoadingHistory && (
          <TableContainer component={Paper} sx={{ mt: 2, maxHeight: 750 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center">{t('common.dateTime')}</TableCell>
                  <TableCell align="center">{t('common.by')}</TableCell>
                  {displayName && <TableCell align="center">{t('common.coApplicant')}</TableCell>}
                  {range(0, largeJobsArraySize, 1).map((i) => (
                    <TableCell key={i} align="center">
                      {t('common.income')} {i + 1}
                    </TableCell>
                  ))}
                  {range(0, largeOtherArraySize, 1).map((i) => (
                    <TableCell align="center" key={i}>
                      {t('common.otherIncome')} {i + 1}
                    </TableCell>
                  ))}
                  <TableCell align="center">Total</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {history.map((item, index) => (
                  <TableRow key={formatDateTime(item.updatedOn)}>
                    <TableCell align="center">{formatDateTime(item.updatedOn)}</TableCell>
                    <TableCell align="center">{item.updatedByUserFullname}</TableCell>
                    {displayName && <TableCell align="center">{item.applicantName}</TableCell>}
                    {range(0, largeJobsArraySize, 1).map((i) => (
                      <TableCell
                        key={i}
                        align="center"
                        sx={{
                          color: getCellColor(i, item.jobs.length > i ? item.jobs[i].annualSalary : 0, true, index),
                        }}
                      >
                        {item.jobs.length > i ? FormatCurrency(item.jobs[i].annualSalary) : ''}
                      </TableCell>
                    ))}
                    {range(0, largeOtherArraySize, 1).map((i) => (
                      <TableCell
                        align="center"
                        key={i}
                        sx={{
                          color: getCellColor(
                            i,
                            item.otherIncomes.length > i ? item.otherIncomes[i].annualAmount : 0,
                            false,
                            index,
                          ),
                        }}
                      >
                        {item.otherIncomes.length > i ? FormatCurrency(item.otherIncomes[i].annualAmount) : ''}
                      </TableCell>
                    ))}
                    <TableCell align="center">{FormatCurrency(totalIncomes(item))}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="warning" startIcon={<CancelIcon />} onClick={onClose}>
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(IncomesHistoryDialog)
