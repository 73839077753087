import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FundingComputedDto } from '@src/data/types/FundingComputedDto'
import translate from '@src/services/translate'
import type { TRootState } from '..'
import {
  EPaymentPlan,
  EWorksheetDecision,
  NormsMessage,
  PersonalLoanWorksheet,
  PersonalWorksheetRevision,
} from '../../types'
import { ParameterizedMessage } from '../../types/ParameterizedMessage'
import { apiStateSelectors } from '../ApiStateStore'
import { appSelectors } from '../AppStore'

export const PersonalLoanWorksheetSlice = createSlice({
  name: 'personalLoanWorksheet',
  initialState: {
    current: null as PersonalLoanWorksheet | null,
    computedInfo: {} as FundingComputedDto,
    currentRevisions: [] as PersonalWorksheetRevision[],
  },
  reducers: {
    setCurrent: (state, action: PayloadAction<PersonalLoanWorksheet | null>) => {
      state.current = action.payload
    },
    setFundingComputed: (state, action: PayloadAction<FundingComputedDto | null>) => {
      if (action.payload)
        state.computedInfo = {
          ...state.computedInfo,
          ...action.payload,
        }
    },
    setCurrentRevisions: (state, action: PayloadAction<PersonalWorksheetRevision[]>) => {
      state.currentRevisions = action.payload.sort((a, b) => {
        if (!a.updatedOn) return -1
        if (!b.updatedOn) return 1
        return a.updatedOn < b.updatedOn ? 1 : -1
      })
    },
  },
})

export default PersonalLoanWorksheetSlice.reducer

export const personalLoanWorksheetActions = PersonalLoanWorksheetSlice.actions

export const SAVE_PERSONAL_LOAN_WORKSHEET = 'personalLoanWorksheet/save'
export const COMPUTE_PERSONAL_LOAN_FUNDING = 'personalLoanWorksheet/computePersonalLoanFunding'
export const GET_PERSONAL_LOAN_WORKSHEET_REVISIONS = 'personalLoanWorksheet/revisions'
export const SAVE_PERSONAL_WORKSHEET_DECISION = 'personalLoanWorksheet/saveDecision'
export const GET_PERSONAL_WORKSHEET_BY_ID = 'personalLoanWorksheet/getPersonalWorksheetById'

const personalLoanWorksheetSelectorsPartial = {
  isSavingPersonalLoanWorksheet: (state: TRootState) =>
    apiStateSelectors.isLoading(state, SAVE_PERSONAL_LOAN_WORKSHEET),
  isComputePersonalLoanFunding: (state: TRootState) =>
    apiStateSelectors.isLoading(state, COMPUTE_PERSONAL_LOAN_FUNDING),
  getCurrent: (state: TRootState) => state.personalLoanWorksheet.current,
  getCurrentRevisions: (state: TRootState) => state.personalLoanWorksheet.currentRevisions,
  getComputedInfo: (state: TRootState) => state.personalLoanWorksheet.computedInfo,
  getTotalfees: (state: TRootState) => state.personalLoanWorksheet.computedInfo?.lenderFee || 0,
  getTotalInsurance: (state: TRootState) =>
    (state.personalLoanWorksheet.computedInfo?.insuranceFee || 0) +
    (state.personalLoanWorksheet.computedInfo?.insuranceTax || 0),
  getTotalObligation: (state: TRootState) =>
    (state.personalLoanWorksheet.current?.amountRequested || 0) +
    (state.personalLoanWorksheet.computedInfo?.totalInterestAmount || 0) +
    (state.personalLoanWorksheet.computedInfo?.lenderFee || 0) +
    (state.personalLoanWorksheet.computedInfo?.insuranceFee || 0) +
    (state.personalLoanWorksheet.computedInfo?.insuranceTax || 0),
  worksheetIsApproved: (state: TRootState) =>
    state.personalLoanWorksheet.current?.finalDecision.decision === EWorksheetDecision.Approved,
  hasConfirmedPromotion: (state: TRootState) =>
    state.personalLoanWorksheet.current !== null &&
    state.personalLoanWorksheet.current?.paymentPlanId !== EPaymentPlan.regularDailyInterests,
  isCreated: (state: TRootState) => state.personalLoanWorksheet.current !== null,
}

function getParameterizedNormMessage(message: ParameterizedMessage): string {
  const translatedMessage = translate([`worksheet.norms.${message.id}`])

  let messageSuffix = ' | '
  message.parameters.forEach((p, i) => {
    if (i >= 1) messageSuffix = messageSuffix.concat(', ')

    const suffix = translate(`worksheet.paramNames.${p.name}`, { val: p.value })
    messageSuffix = messageSuffix.concat(suffix)
  })

  return translatedMessage.concat(messageSuffix)
}

const getCurrentWorksheetNormWarningMessages = createSelector(
  [personalLoanWorksheetSelectorsPartial.getCurrent, appSelectors.getCurrentLang],
  (currentWorksheet) => {
    const worksheetNormMessages: NormsMessage[] = []
    currentWorksheet?.normsDecision?.warningMessages.forEach((wr) => {
      const builtMessage = getParameterizedNormMessage(wr)
      const worksheetNormMessage: NormsMessage = {
        id: wr.id,
        message: builtMessage,
        applicantType: null,
      }
      worksheetNormMessages.push(worksheetNormMessage)
    })

    return worksheetNormMessages
  },
)

const getCurrentWorksheetNormRefusalMessages = createSelector(
  [personalLoanWorksheetSelectorsPartial.getCurrent, appSelectors.getCurrentLang],
  (currentWorksheet) => {
    const worksheetNormMessages: NormsMessage[] = []
    currentWorksheet?.normsDecision?.refusalMessages.forEach((wr) => {
      const builtMessage = getParameterizedNormMessage(wr)
      const worksheetNormMessage: NormsMessage = {
        id: wr.id,
        message: builtMessage,
        applicantType: null,
      }
      worksheetNormMessages.push(worksheetNormMessage)
    })

    return worksheetNormMessages
  },
)

export const personalLoanWorksheetSelectors = {
  ...personalLoanWorksheetSelectorsPartial,
  getCurrentWorksheetNormWarningMessages,
  getCurrentWorksheetNormRefusalMessages,
}
