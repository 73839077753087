import React from 'react'
import { useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { CreditApplication, EOtherIncomeType } from '../../../data/types'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'
import { FormatCurrencyNoDecimals } from '../../../services/Formatter'

type Props = { otherIncomeId: string | undefined; isForApplicant: boolean; creditApplication: CreditApplication }

const OtherIncomeTaskDescription = ({ otherIncomeId, isForApplicant, creditApplication }: Props) => {
  const otherIncomeTypeEnum = useAppSelector(appSelectors.getOtherIncomeTypesEnum)
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum

  const getOtherIncomeFromId = () => {
    if (otherIncomeId) {
      const otherIncomes = isForApplicant
        ? creditApplication.applicant.otherIncomes
        : creditApplication.coApplicant?.otherIncomes
      return otherIncomes?.find((x) => x.id === otherIncomeId)
    }
    return undefined
  }

  const otherIncomeJob = getOtherIncomeFromId()
  return (
    <div>
      {otherIncomeJob?.typeId &&
        otherIncomeTypeEnum[otherIncomeJob?.typeId][lang].concat(
          `${
            otherIncomeJob?.typeId === EOtherIncomeType.other ? ` : ${otherIncomeJob.description}` : ''
          } (${FormatCurrencyNoDecimals(otherIncomeJob?.annualAmount)})`,
        )}
    </div>
  )
}

export default OtherIncomeTaskDescription
