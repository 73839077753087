/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosHeaders, AxiosInstance, AxiosRequestConfig, RawAxiosResponseHeaders } from 'axios'
import download from 'downloadjs'
import { get, isArray, isObject } from 'lodash-es'

let reportVersionDeprecated: () => void

export const setReportVersionDeprecated = (callback: typeof reportVersionDeprecated) => {
  reportVersionDeprecated = callback
}
export interface TransformFunc {
  (data: any): any
}

const applyTransform = <T = any>(data: T | T[], transform: TransformFunc | undefined) => {
  if (!transform) return data

  if (isArray(data)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data.map((x) => transform(x))
  }
  if (data) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return transform(data)
  }

  return data
}

export interface GetSingleFromApi<T extends object> {
  (apiClient: AxiosInstance, ids: Record<string, string | number>): Promise<T>
}

export interface GetWithFiltersFromApi<T extends object> {
  (apiClient: AxiosInstance, filters?: Record<string, string | number | string[] | number[]>): Promise<T>
}

export interface GetListFromApi<T extends object> {
  (apiClient: AxiosInstance, filters?: Record<string, string | number | string[] | number[]>): Promise<T[]>
}

export interface GetStringListFromApi {
  (apiClient: AxiosInstance): Promise<string[]>
}

export interface GetListWithIdsFromApi<T extends object> {
  (
    apiClient: AxiosInstance,
    ids: Record<string, string>,
    filters?: Record<string, string | number | string[] | number[]>,
  ): Promise<T[]>
}

export interface SaveToApi<TInput extends object, TOutput extends object = TInput> {
  (apiClient: AxiosInstance, data: Partial<TInput>): Promise<TOutput>
}

export interface DeleteToApi<TInput extends object> {
  (apiClient: AxiosInstance, data: Partial<TInput>): Promise<void>
}

export interface DeleteWithReturnToApi<TInput extends object, TOutput extends object = TInput> {
  (apiClient: AxiosInstance, data: Partial<TInput>): Promise<TOutput>
}

export interface DownloadFromApi {
  (fileName: string): Promise<void>
}

export interface DownloadWithObjectFromApi<TInput extends object> {
  (apiClient: AxiosInstance, data: Partial<TInput>): Promise<void>
}

const checkVersionAndUpdateState = (headers: RawAxiosResponseHeaders | (RawAxiosResponseHeaders & AxiosHeaders)) => {
  if (headers instanceof AxiosHeaders) {
    const latestVersion = headers.get('X-Requestor-Latest-Version')
    const currentVersion = import.meta.env.VITE_VERSION

    if (latestVersion && currentVersion && latestVersion !== currentVersion) {
      reportVersionDeprecated()
    }
  }
}

const serializeParameter = (params: Record<string, string | number | string[] | number[]> | undefined) => {
  const searchParams = new URLSearchParams()
  if (params)
    Object.keys(params).forEach((key) => {
      const v = params[key]
      if (v.toString()) searchParams.append(key, v.toString())
    })
  return searchParams.toString()
}

export function buildUrlWithIds(baseUrl: string, ids: Record<string, unknown>): string {
  let newUrl = baseUrl
  const paramNames = baseUrl.match(/[^{}]+(?=})/g)
  paramNames?.forEach((name) => {
    const value = get(ids, name)
    if (value === undefined || value === null || value === '') {
      throw new Error(`parameter ${name} for url ${baseUrl} was given a missing value.`)
    } else if (isObject(ids[name])) {
      throw new Error(`parameter ${name} for url ${baseUrl} was given an object instead of a direct value.`)
    } else {
      newUrl = newUrl.replace(`{${name}}`, value as string)
    }
  })
  return newUrl
}

export const makeGetSingleFromApi = <T extends object>(
  baseUrl: string,
  fromApi?: TransformFunc | undefined,
  hostname?: string,
): GetSingleFromApi<T> => {
  return async (apiClient: AxiosInstance, ids: Record<string, string | number>): Promise<T> => {
    const payload: AxiosRequestConfig<T> = { method: 'GET', url: buildUrlWithIds(baseUrl, ids) }

    if (hostname) payload.baseURL = hostname

    const response = await apiClient.request(payload)
    checkVersionAndUpdateState(response.headers)
    return applyTransform(response.data, fromApi) as T
  }
}

export const makeGetWithFiltersFromApi = <T extends object>(
  baseUrl: string,
  fromApi?: TransformFunc | undefined,
): GetWithFiltersFromApi<T> => {
  return async (
    apiClient: AxiosInstance,
    filters?: Record<string, string | number | string[] | number[]>,
  ): Promise<T> => {
    const payload: AxiosRequestConfig<T> = { method: 'GET', url: baseUrl.concat(`?${serializeParameter(filters)}`) }
    const response = await apiClient.request<T>(payload)
    return applyTransform(response.data, fromApi) as T
  }
}

export const makeGetListFromApi = <T extends object>(
  baseUrl: string,
  fromApi?: TransformFunc | undefined,
): GetListFromApi<T> => {
  return async (
    apiClient: AxiosInstance,
    filters?: Record<string, string | number | string[] | number[]>,
  ): Promise<T[]> => {
    const payload: AxiosRequestConfig<T> = { method: 'GET', url: baseUrl.concat(`?${serializeParameter(filters)}`) }
    const response = await apiClient.request(payload)
    return applyTransform(response.data, fromApi) as T[]
  }
}
export const makeGetStringListFromApi = (baseUrl: string, fromApi?: TransformFunc | undefined) => {
  return async (apiClient: AxiosInstance): Promise<string[]> => {
    const payload: AxiosRequestConfig<string> = {
      method: 'GET',
      url: baseUrl,
    }
    const response = await apiClient.request<string[]>(payload)
    return applyTransform(response.data, fromApi) as string[]
  }
}

export const makeGetListWithIdsFromApi = <T extends object>(
  baseUrl: string,
  fromApi?: TransformFunc | undefined,
): GetListWithIdsFromApi<T> => {
  return async (
    apiClient: AxiosInstance,
    ids: Record<string, string>,
    filters?: Record<string, string | number | string[] | number[]>,
  ): Promise<T[]> => {
    const payload: AxiosRequestConfig<T> = {
      method: 'GET',
      url: buildUrlWithIds(baseUrl, ids).concat(`?${serializeParameter(filters)}`),
    }
    const response = await apiClient.request<T>(payload)
    return applyTransform(response.data, fromApi) as T[]
  }
}

export const makeCreate = <TInput extends object, TOutput extends object = TInput>(
  baseUrl: string,
  toApi?: TransformFunc | undefined,
  fromApi?: TransformFunc | undefined,
): SaveToApi<TInput, TOutput> => {
  return async (apiClient: AxiosInstance, data: Partial<TInput>): Promise<TOutput> => {
    const payload: AxiosRequestConfig<TInput[]> = {
      method: 'POST',
      url: buildUrlWithIds(baseUrl, data),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data: applyTransform(data, toApi),
    }
    const response = await apiClient.request(payload)
    return applyTransform(response.data, fromApi) as TOutput
  }
}

export const makeUpdate = <TInput extends object, TOutput extends object = TInput>(
  baseUrl: string,
  toApi?: TransformFunc | undefined,
  fromApi?: TransformFunc | undefined,
): SaveToApi<TInput, TOutput> => {
  return async (apiClient: AxiosInstance, data: Partial<TInput>): Promise<TOutput> => {
    const payload: AxiosRequestConfig<TInput> = {
      url: buildUrlWithIds(baseUrl, data),
      method: 'PUT',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data: applyTransform(data, toApi),
    }
    const response = await apiClient.request(payload)
    return applyTransform(response.data, fromApi) as TOutput
  }
}

export const makeDelete = <TInput extends object>(
  baseUrl: string,
  toApi?: TransformFunc | undefined,
): DeleteToApi<TInput> => {
  return async (apiClient: AxiosInstance, data: Partial<TInput>): Promise<void> => {
    const payload: AxiosRequestConfig<TInput> = {
      method: 'DELETE',
      url: buildUrlWithIds(baseUrl, data),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data: applyTransform(data, toApi),
    }
    await apiClient.request(payload)
    return Promise.resolve()
  }
}

export const makeDeleteWithReturn = <TInput extends object, TOutput extends object = TInput>(
  baseUrl: string,
  toApi?: TransformFunc | undefined,
  fromApi?: TransformFunc | undefined,
): DeleteWithReturnToApi<TInput, TOutput> => {
  return async (apiClient: AxiosInstance, data: Partial<TInput>): Promise<TOutput> => {
    const payload: AxiosRequestConfig<TInput> = {
      method: 'DELETE',
      url: buildUrlWithIds(baseUrl, data),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data: applyTransform(data, toApi),
    }
    const response = await apiClient.request(payload)
    return applyTransform(response.data, fromApi) as TOutput
  }
}

const callApiForDownload = async (payload: AxiosRequestConfig, apiClient: AxiosInstance) => {
  await apiClient.request({ ...payload, responseType: 'blob' }).then((response) => {
    const contentDisposition = response.headers['content-disposition'] as string | undefined
    const filenameHeader = contentDisposition?.split(';').find((n: string) => n.includes('filename='))
    if (filenameHeader) {
      const filename = filenameHeader.replace('filename=', '').trim()
      download(response.data as Blob, filename)
    }
  })
  return Promise.resolve()
}

export const makeDownloadWithObject = <TInput extends object>(url: string): DownloadWithObjectFromApi<TInput> => {
  return async (apiClient: AxiosInstance, data: Partial<TInput>) => {
    const payload: AxiosRequestConfig = {
      method: 'POST',
      url: buildUrlWithIds(url, data),
      data,
    }
    return callApiForDownload(payload, apiClient)
  }
}

export const makeGetPdf = <TInput extends object>(url: string): DownloadWithObjectFromApi<TInput> => {
  return async (apiClient: AxiosInstance, data: Partial<TInput>) => {
    const payload: AxiosRequestConfig = {
      method: 'GET',
      url: buildUrlWithIds(url, data),
      data,
    }
    return callApiForDownload(payload, apiClient)
  }
}

export const makeGetCsv = (url: string) => {
  return async (apiClient: AxiosInstance) => {
    const payload: AxiosRequestConfig = {
      method: 'GET',
      url,
    }
    return callApiForDownload(payload, apiClient)
  }
}

export const getActionName = (apiName: string, actionName: string) => `${apiName}/${actionName}`
export const createEntityApi = <T extends object>(baseUrl: string, fromApi?: TransformFunc, toApi?: TransformFunc) => {
  const urlWithId = baseUrl.endsWith('/') ? `${baseUrl}{id}` : `${baseUrl}/{id}`
  return {
    getById: makeGetSingleFromApi<T>(urlWithId, fromApi),
    create: makeCreate<T>(baseUrl, toApi, fromApi),
    update: makeUpdate<T>(urlWithId, toApi, fromApi),
    delete: makeDelete<T>(urlWithId, toApi),
  }
}
