import { IFinanceBaseWorksheet } from '../data/types/IFinanceBaseWorksheetSchema'

export function computeTotalInsurance(worksheet: IFinanceBaseWorksheet) {
  return (worksheet?.insuranceFee ?? 0) + (worksheet?.insuranceTax ?? 0)
}

export function computeTotalObligation(worksheet: IFinanceBaseWorksheet) {
  return (
    computeTotalInsurance(worksheet) +
    (worksheet?.amountRequested ?? 0) +
    (worksheet?.totalInterestAmount ?? 0) +
    (worksheet?.lenderFee ?? 0)
  )
}
