import { AxiosRequestConfig, AxiosInstance } from 'axios'
import { Vehicle } from '../../types'

const vehicleDecoderApi = {
  decode: async (
    client: AxiosInstance,
    vin: string,
    userId: string,
    merchantProvinceIso: string,
  ): Promise<Vehicle[]> => {
    const payload: AxiosRequestConfig = {
      baseURL: import.meta.env.VITE_VEHICLE_DECODER_API_URL,
      method: 'GET',
      url: `vin-decoder/v1/decode?vin=${vin}&userid=${userId}&stateiso=${merchantProvinceIso}`,
    }

    const response = await client.request(payload)
    return response.data as Vehicle[]
  },
}

export default vehicleDecoderApi
