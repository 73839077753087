import allApi from '../../api'
import { FullCreditApplication, RequiredExternalStep, RequiredExternalStepDto } from '../../types'
import { makeSave } from '../effects/standard-side-effects'
import {
  RequiredExternalStepSlice,
  RESET_REQUIRED_EXTERNAL_STEP,
  SAVE_REQUIRED_EXTERNAL_STEP,
} from './required-external-step-store'

const requiredExternalStepEffects = {
  createBankAccountRequest: (data: Partial<RequiredExternalStepDto>) =>
    makeSave<RequiredExternalStepDto, RequiredExternalStep>(
      SAVE_REQUIRED_EXTERNAL_STEP,
      allApi.bankAccountRequest.create,
      RequiredExternalStepSlice.actions.addRequiredExternalSteps,
      data,
    ),
  resendUrlForBankAccountRequest: (data: Partial<RequiredExternalStepDto>) =>
    makeSave<RequiredExternalStepDto, RequiredExternalStep>(
      SAVE_REQUIRED_EXTERNAL_STEP,
      allApi.bankAccountRequest.resendUrl,
      RequiredExternalStepSlice.actions.addRequiredExternalSteps,
      data,
    ),
  resetPersonalLoanBankAccountRequest: (data: Partial<RequiredExternalStepDto>) =>
    makeSave<RequiredExternalStepDto, FullCreditApplication>(
      RESET_REQUIRED_EXTERNAL_STEP,
      allApi.bankAccountRequest.reset,
      null,
      data,
    ),
}

export default requiredExternalStepEffects
