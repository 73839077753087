import React from 'react'
import { CreditApplication } from '../../../data/types'
import { FormatCurrencyNoDecimals } from '../../../services/Formatter'

type Props = { jobIncomeId: string; isForApplicant: boolean; creditApplication: CreditApplication }

const JobIncomeTaskDescription = ({ jobIncomeId, isForApplicant, creditApplication }: Props) => {
  const getJobFromId = () => {
    const jobs = isForApplicant ? creditApplication.applicant.currentJobs : creditApplication.coApplicant?.currentJobs
    return jobs?.find((x) => x.id === jobIncomeId)
  }

  const job = getJobFromId()
  if (job?.jobTitle) return <div>{job.jobTitle.concat(` (${FormatCurrencyNoDecimals(job.annualSalary)})`)}</div>
  return <div />
}

export default JobIncomeTaskDescription
