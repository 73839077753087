import allApi from '../../api'
import { makeGetById } from '../effects/standard-side-effects'
import { GET_VEHICLE_MAX_TERM, vehicleSlice } from './vehicle-store'

const vehicleEffects = {
  getMaxTerm: (data: { year: number; mileage: number; plan?: string }) =>
    makeGetById(GET_VEHICLE_MAX_TERM, allApi.vehicle.getMaxTerm, vehicleSlice.actions.setMaxTerm, data),
}

export default vehicleEffects
