import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import FormControl from '@mui/material/FormControl'
import { useParams } from 'react-router-dom'
import { ActionsDialog, InputTextField, SelectComponent } from '../../../components'
import { ECreditApplicationStepList, EFinancingProgram } from '../../../data/types'
import { DocumentRequired, DocumentRequiredSchema } from '../../../data/types/DocumentSchema'
import { reportErrorToConsole } from '../../../services/error-logger'
import { TRootState, useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'

type Props = {
  open: boolean
  defaultValue: DocumentRequired | undefined
  onConfirm: (data: DocumentRequired) => void
  onCancel: () => void
}

const DocumentsRequestDialog = ({ open, defaultValue, onConfirm, onCancel }: Props) => {
  const { t } = useTranslation()
  const { financingProgramId } = useParams<{ financingProgramId: EFinancingProgram }>()
  const documentTypes = useAppSelector((state: TRootState) => appSelectors.getDocumentTypes(state, financingProgramId))
  const applicantTypeEnum = useAppSelector(appSelectors.getApplicantTypeEnum)
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum

  const {
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<DocumentRequired>({
    mode: 'onBlur',
    defaultValues: defaultValue,
    resolver: yupResolver(DocumentRequiredSchema),
  })

  React.useEffect(() => {
    reset({ ...defaultValue, financingProgramId })
  }, [defaultValue, financingProgramId, reset])

  return (
    <ActionsDialog
      title={`${t('document.request')} : ${documentTypes[getValues('typeId')][lang]}`}
      open={open}
      onCancel={() => {
        onCancel()
      }}
      onConfirm={handleSubmit(onConfirm, reportErrorToConsole)}
    >
      <TableContainer component={Paper} sx={{ minWidth: '100vh', maxHeight: '75vh', mt: 2 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="right">
                <FormControl>
                  <InputTextField
                    disabled
                    label={t('document.docType')}
                    value={defaultValue?.typeId ? documentTypes[defaultValue?.typeId][lang] : null}
                    error={errors.typeId}
                  />
                </FormControl>
              </TableCell>
              <TableCell sx={{ minWidth: '15%' }} align="left">
                <InputTextField
                  disabled
                  label={`${t('common.applicant')}(s)`}
                  value={defaultValue?.applicantType ? applicantTypeEnum[defaultValue?.applicantType][lang] : null}
                  error={errors.applicantType}
                />
              </TableCell>
              <TableCell sx={{ minWidth: '15%' }} align="left">
                <SelectComponent
                  items={ECreditApplicationStepList.map((value) => ({
                    label: `enum.eCreditApplicationSteps.${value}`,
                    value,
                  }))}
                  {...register('requiredBeforeStep')}
                  error={errors.requiredBeforeStep}
                  label={t('common.requiredFor') as string}
                />
              </TableCell>
              <TableCell align="left" sx={{ width: '35%' }}>
                <InputTextField label={t('common.comment')} {...register('note')} error={errors.note} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </ActionsDialog>
  )
}

export default React.memo(DocumentsRequestDialog)
