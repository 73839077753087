import React from 'react'
import { Link, Typography, IconButton, Tooltip } from '@mui/material'
import PhoneIcon from '@mui/icons-material/Phone'
import { formatPhone } from '../../../services/Formatter'

interface PhoneNumberProps {
  phone: string
  phoneExt?: string
  applyPhoneFormatting: boolean
  contacts?: string[]
}

const PhoneNumber = ({ phone, phoneExt = '', applyPhoneFormatting, contacts }: PhoneNumberProps) => {
  const formattedPhone = applyPhoneFormatting ? formatPhone(phone) : phone
  const telephoneLink = phoneExt ? `tel:${formattedPhone},${phoneExt}` : `tel:${formattedPhone}`
  const contactList = contacts ?? []
  return (
    <Typography>
      {contactList.length > 0 ? (
        <Tooltip
          title={
            <div>
              {contactList.map((contact) => (
                <Typography key={contact} variant="body2">
                  {contact}
                </Typography>
              ))}
            </div>
          }
          arrow
        >
          <IconButton style={{ paddingLeft: '0' }}>
            <PhoneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton style={{ paddingLeft: '0' }}>
          <PhoneIcon fontSize="small" />
        </IconButton>
      )}
      <Link href={telephoneLink}>
        {formattedPhone} {phoneExt && `ext.${phoneExt}`}
      </Link>
    </Typography>
  )
}

export default PhoneNumber
