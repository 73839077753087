import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { Alert, Grid, Stack, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { ActionsDialog, buildSelectValueListFromEnum, InputTextField, SelectComponent } from '../../../components'
import {
  NormsMessage,
  ValueListItem,
  WorksheetDecision,
  worksheetDecisionSchema,
  AutoWorksheet,
  CreditApplication,
  EWorksheetDecision,
  EWorksheetDecisionList,
} from '../../../data/types'
import MultipleCheckboxList from '../../../components/MultipleCheckboxList'
import { FormatCurrency } from '../../../services/Formatter'

type Props = {
  title: string
  onConfirm: (data: WorksheetDecision) => void
  onCancel: () => void
  open: boolean
  defaultValue: WorksheetDecision
  worksheetWarnings: NormsMessage[] | null
  worksheet: AutoWorksheet
  creditApplication: CreditApplication
}

const EditAutoWorksheetDecisionDialog = ({
  onConfirm,
  onCancel,
  title,
  open,
  defaultValue,
  worksheetWarnings,
  worksheet,
  creditApplication,
}: Props) => {
  const { register, handleSubmit, reset, watch, control } = useForm<WorksheetDecision>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: defaultValue,
    resolver: yupResolver(worksheetDecisionSchema),
  })
  const { t } = useTranslation()
  const [acceptWarningMessage, setAcceptWarningsMessage] = useState<boolean>(false)
  React.useEffect(() => {
    reset(defaultValue)
  }, [defaultValue, reset])
  const [resetList, setReset] = React.useState<boolean>(false)
  const valueList: ValueListItem[] = worksheetWarnings
    ? worksheetWarnings.map((value) => ({ id: value.id, text: value.message }))
    : []

  const disabledReasonIds: string[] = [
    'ineligibleMechanicalWarranty',
    'ineligibleInsurance',
    'totalAmountFinancedTooLow',
    'totalAftermarketServicePercentageOfVehiclePriceThreshold',
    'effectiveRateTooHigh',
  ]
  const decision = watch('decision')
  const paymentFrequenceColor = () => {
    if ((creditApplication.finalCreditDecision.maxPmtAmount ?? 0) < (worksheet.paymentAmountForFrequency ?? 0))
      return 'error'
    return 'success'
  }

  const canApprove = defaultValue.cantApproveReasonCodes.length === 0
  const showCheckBox = worksheetWarnings != null && worksheetWarnings.length > 0
  const disableConfirmationButton = () => {
    if (decision === EWorksheetDecision.Approved) {
      if (!canApprove) return true
      if (showCheckBox && !acceptWarningMessage) return true
    }
    return false
  }
  const eWorkSheetDecision = useMemo(
    () =>
      buildSelectValueListFromEnum(EWorksheetDecisionList, 'enum.eWorkSheetDecision', undefined, [
        EWorksheetDecision.Pending,
      ]),
    [],
  )

  const maxPayment = useMemo(() => {
    return [
      { value: 0, label: t('editDecisionDialog.valueCannotChange') },
      { value: 10, label: t('editDecisionDialog.maxPaymentAddition', '', { value: '10' }) },
      { value: 20, label: t('editDecisionDialog.maxPaymentAddition', '', { value: '20' }) },
    ]
  }, [t])

  const maxTerm = useMemo(() => {
    return [
      { value: 0, label: t('editDecisionDialog.valueCannotChange') },
      { value: 6, label: t('editDecisionDialog.termAddition', '', { value: '6' }) },
      { value: 12, label: t('editDecisionDialog.termAddition', '', { value: '12' }) },
    ]
  }, [t])

  return (
    <ActionsDialog
      disabled={disableConfirmationButton()}
      title={title}
      open={open}
      onCancel={() => {
        reset(defaultValue)
        setReset(!resetList)
        onCancel()
      }}
      onConfirm={handleSubmit(onConfirm)}
    >
      <Grid container marginTop={2} spacing={2}>
        <Grid item xs={12}>
          <SelectComponent
            items={eWorkSheetDecision}
            label={t('worksheet.decision') as string}
            {...register('decision')}
          />
        </Grid>
        {decision === EWorksheetDecision.Declined && (
          <Stack>
            <MultipleCheckboxList
              name="refusalReasonCodes"
              control={control}
              title={t('worksheet.refusalReason')}
              content={valueList}
              defaultValues={defaultValue.refusalReasonCodes}
              disabledElementsIds={disabledReasonIds}
              reset={resetList}
            />
            <InputTextField sx={{ mt: 2 }} label={t('worksheet.reason')} {...register('otherReason')} />
          </Stack>
        )}

        {(decision === EWorksheetDecision.Approved || decision === EWorksheetDecision.ApprovedWithCondition) &&
          !canApprove && (
            <Grid item xs={12} sx={{ mt: 1 }}>
              <div>
                {defaultValue?.cantApproveReasonCodes.map((error) => (
                  <Alert severity="error" key={error}>
                    {t(`enum.eFinancingRefusalApproveMessage.${error}`)}
                  </Alert>
                ))}
              </div>
            </Grid>
          )}
        {decision === EWorksheetDecision.Approved && canApprove && showCheckBox && (
          <Grid item xs={12} sx={{ mt: 1 }}>
            <div>
              {worksheetWarnings.map((message) => (
                <Alert severity="warning" key={message.id}>
                  {message.message}
                </Alert>
              ))}
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptWarningMessage}
                  onChange={(event) => setAcceptWarningsMessage(event.target.checked)}
                />
              }
              label={t('editDecisionDialog.acceptWarningMessage')}
            />
          </Grid>
        )}
        {decision === EWorksheetDecision.ApprovedWithCondition && canApprove && (
          <Grid container item xs={12} sx={{ mt: 1 }}>
            <Grid item xs={6} sx={{ mt: 1, pr: 1 }}>
              <SelectComponent
                items={maxTerm}
                label={t('editDecisionDialog.maxTermDuration') as string}
                {...register('maxTermDuration')}
              />
            </Grid>
            <Grid item xs={6} sx={{ mt: 1 }}>
              <SelectComponent
                items={maxPayment}
                label={t('editDecisionDialog.maxPmtAmount') as string}
                {...register('maxPmtAmount')}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <InputTextField
                label={t('editDecisionDialog.maxTotalAmountFinanced')}
                {...register('maxTotalAmountFinanced')}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Typography variant="h6">Financement</Typography>
            </Grid>
            <Grid item xs={3} sx={{ mt: 1 }}>
              <Typography variant="body2">
                {t('editDecisionDialog.maxTermDuration')}: {worksheet?.term} {t('worksheet.month')}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ mt: 1 }}>
              <Typography variant="body2">
                {t('worksheet.totalAmountFinanced')}: {FormatCurrency(worksheet?.totalAmountFinanced)}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ mt: 1 }}>
              <Typography variant="body2">
                {t('credit.maxPayment')}: {FormatCurrency(creditApplication.finalCreditDecision.maxPmtAmount)}{' '}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ mt: 1 }}>
              <Typography variant="body2" color={paymentFrequenceColor()}>
                {t('worksheet.paymentAmount')}: {FormatCurrency(worksheet?.paymentAmountForFrequency)}{' '}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </ActionsDialog>
  )
}

export default EditAutoWorksheetDecisionDialog
