import React from 'react'
import { Select, MenuItem, SelectChangeEvent } from '@mui/material'
import { EFinancingCompany } from '@src/data/types'
import { useAppDispatch, useAppSelector } from '@src/data/store'
import { userActions, userSelectors } from '@src/data/store/UserStore'
import { useNavigate, useLocation } from 'react-router-dom'

const CompanySelector = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(userSelectors.getUser)
  const selectedCompany = useAppSelector(userSelectors.selectedCompany)
  const availableCompanies = user?.companyIds || []
  const navigate = useNavigate()
  const location = useLocation()
  const updateSearch = new URLSearchParams(location.search)

  const showCompanySelector =
    availableCompanies.includes(EFinancingCompany.IFinance) && availableCompanies.includes(EFinancingCompany.Iceberg)

  const handleChange = (event: SelectChangeEvent<EFinancingCompany>) => {
    const company = event.target.value as EFinancingCompany
    dispatch(userActions.setSelectedCompany(company))
    updateSearch.set('financingCompanyId', company)
    const newUrl = `${location.pathname}?${updateSearch.toString()}`
    navigate(newUrl, { replace: true })
  }

  if (!showCompanySelector || availableCompanies.length === 0) {
    return null
  }

  return (
    <Select
      sx={{
        color: '#ffffff',
        border: '1px solid #ffffff',
        '& .MuiSvgIcon-root': {
          color: '#ffffff',
        },
      }}
      value={selectedCompany}
      onChange={handleChange}
    >
      {availableCompanies.map((company) => (
        <MenuItem key={company} value={company}>
          {company}
        </MenuItem>
      ))}
    </Select>
  )
}

export default CompanySelector
