import { Typography, useTheme } from '@mui/material'
import React from 'react'
import { CreditReport } from '../../../data/types/CreditReportSchema'

type Props = {
  applicantCreditReport: CreditReport | null
  accountNumber: string
}

const ProofOfReleaseSummary = ({ accountNumber, applicantCreditReport }: Props) => {
  const theme = useTheme()
  const trade = applicantCreditReport?.trades.find((elm) => elm.accountNumber === accountNumber)
  return (
    <Typography
      key={accountNumber}
      sx={{ backgroundColor: theme.palette.action.selected, marginBottom: 3 }}
      component="p"
    >
      {trade?.accountNumber} - {trade?.creditorName}
    </Typography>
  )
}

export default ProofOfReleaseSummary
