import { Constants } from '@src/data/types'
import { FundingComputedDto } from '@src/data/types/FundingComputedDto'
import { FundingInfoDto } from '@src/data/types/FundingInfoDto'

export const canComputeFunding = (computeDto: FundingInfoDto): boolean => {
  return (
    computeDto.amountRequested >= Constants.MinimumLoanAmount &&
    !!computeDto.paymentFrequency &&
    !!computeDto.paymentPlanId &&
    !!computeDto.deliveryOn &&
    !!computeDto.firstPaymentOn &&
    !!computeDto.term &&
    !!computeDto.interestRate &&
    !!computeDto.stateIso
  )
}

export const getEmptyComputedFundingDto = (): FundingComputedDto => {
  return {
    lenderFee: 0,
    lenderFeeRate: 0,
    totalInterestAmount: 0,
    insuranceFee: 0,
    insuranceTax: 0,
    paymentForFrequency: 0,
    effectiveRate: 0,
    requestId: '',
  }
}
