import { makeCreate, makeUpdate } from '../make-api'
import { RequiredExternalStepDto, RequiredExternalStep, FullCreditApplication } from '../../types'
import transformFullCreditApplicationFromApi from '../full-credit-application/full-credit-application-transform'

const baseUrl = '/Applications/{financingProgramId}/{creditApplicationId}/BankAccountRequest'
const bankAccountRequestApi = {
  create: makeCreate<RequiredExternalStepDto, RequiredExternalStep>(baseUrl),
  resendUrl: makeCreate<RequiredExternalStepDto, RequiredExternalStep>(baseUrl.concat('/ResendUrl')),
  reset: makeUpdate<RequiredExternalStepDto, FullCreditApplication>(
    baseUrl.concat('/reset'),
    undefined,
    transformFullCreditApplicationFromApi,
  ),
}

export default bankAccountRequestApi
