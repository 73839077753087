import { AutoRslaWorksheet, EditAutoRslaWorksheetDto } from '@src/data/types/AutoRslaWorksheet'
import { AutoRslaWorksheetSlice, GET_AUTO_RSLA_WORKSHEET, SAVE_AUTO_RSLA_WORKSHEET } from './auto-rsla-worksheet-store'
import { makeGetById, makeSave } from '../effects/standard-side-effects'
import allApi from '../../api'

const autoRslaWorksheetEffects = {
  getById: (id: string) =>
    makeGetById<AutoRslaWorksheet>(
      GET_AUTO_RSLA_WORKSHEET,
      allApi.autoRslaWorsheet.getById,
      AutoRslaWorksheetSlice.actions.setCurrent,
      {
        id,
      },
    ),
  create: (data: EditAutoRslaWorksheetDto) =>
    makeSave<EditAutoRslaWorksheetDto, AutoRslaWorksheet>(
      SAVE_AUTO_RSLA_WORKSHEET,
      allApi.autoRslaWorsheet.create,
      AutoRslaWorksheetSlice.actions.setCurrent,
      data,
    ),
  update: (data: EditAutoRslaWorksheetDto) =>
    makeSave<EditAutoRslaWorksheetDto, AutoRslaWorksheet>(
      SAVE_AUTO_RSLA_WORKSHEET,
      allApi.autoRslaWorsheet.update,
      AutoRslaWorksheetSlice.actions.setCurrent,
      data,
    ),
}

export default autoRslaWorksheetEffects
