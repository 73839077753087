import { ReportPayoutThatSkippedIncomeValidationDatas } from '../../types/ReportPayoutWithoutFlinkData'
import { makeGetListFromApi } from '../make-api'

const REPORT_PAYOUT_THAT_SKIPPED_INCOME_VALIDATION = '/Report/GetPayoutThatSkippedIncomeValidation'

const reportApi = {
  getPayoutThatSkippedIncomeValidation: makeGetListFromApi<ReportPayoutThatSkippedIncomeValidationDatas>(
    REPORT_PAYOUT_THAT_SKIPPED_INCOME_VALIDATION,
  ),
}

export default reportApi
