import { EIFinanceTeam, ELoanPurpose } from '@src/data/types'
import allApi from '../../api'
import { makeGetList } from '../effects/standard-side-effects'
import { creditDashboardEntrySlice, GET_CREDIT_DASHBOARD_ENTRY } from './credit-dashboard-entry-store'
import { CreditDashboardEntry } from '../../types/CreditDashboardEntry'

const creditDashboardEntryEffects = {
  getCreditApplicationListByFilters: (filters?: Record<string, string | number | string[] | number[]>) => {
    const modifiedFilters = { ...filters }

    if (modifiedFilters.team) {
      switch (modifiedFilters.team) {
        case EIFinanceTeam.IFinance:
          modifiedFilters.loanPurposeIds = [ELoanPurpose.GoodsAndServices, ELoanPurpose.Veterinary]
          break
        case EIFinanceTeam.Medicard:
          modifiedFilters.loanPurposeIds = [ELoanPurpose.Dentistry, ELoanPurpose.Medical, ELoanPurpose.Aesthetics]
          break
        default:
          modifiedFilters.loanPurposeIds = []
      }
    }

    return makeGetList<CreditDashboardEntry>(
      GET_CREDIT_DASHBOARD_ENTRY,
      allApi.creditDashboardEntries.getByFilters,
      creditDashboardEntrySlice.actions.setCreditApplicationList,
      modifiedFilters,
    )
  },
}

export default creditDashboardEntryEffects
