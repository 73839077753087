import React from 'react'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Stack, Typography } from '@mui/material'
import { RemoveRedEye as RemoveRedEyeIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { EFinancingProgram } from '@src/data/types'
import Row from './tableRow'
import { CreditReport } from '../../../../data/types/CreditReportSchema'
import DimensionPlusSection from './dimensionPlusSection'
import OnlineDasSection from './onlineDasSection'

type ScoreProps = {
  creditReport: CreditReport
  financingProgramId: EFinancingProgram
}
const Score = ({ creditReport, financingProgramId }: ScoreProps) => {
  const [viewOnlineDas, setViewOnlineDas] = React.useState(false)
  const { t } = useTranslation()
  return (
    <Row id="score" name={t('creditReport.scores')}>
      <TableContainer>
        <Table sx={{ display: 'contents' }} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell align="left">{t('creditReport.result')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                BNI
              </TableCell>
              <TableCell align="left">{creditReport.bni}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                ERS 2.0
              </TableCell>
              <TableCell align="left">{creditReport.ers}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <DimensionPlusSection creditReport={creditReport} financingProgramId={financingProgramId} />
      <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
        <Typography variant="subtitle2" gutterBottom component="div">
          {t('creditReport.dasOnline')}
        </Typography>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          onClick={() => setViewOnlineDas(!viewOnlineDas)}
        >
          {viewOnlineDas ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
        </IconButton>
      </Stack>
      <OnlineDasSection
        creditReport={creditReport}
        financingProgramId={financingProgramId}
        viewOnlineDas={viewOnlineDas}
      />
    </Row>
  )
}

export default Score
