import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { CreditApplication } from '../types'
import { Refinancing } from '../types/RefinancingSchema'
import { LoanRefinancingOutputDto } from './credit-application/LoanRefinancingInputDto'
import { makeGetListFromApi, makeUpdate } from './make-api'

export const GET_LOANS = '/Refinancing/GetLoansForRefinancing/{creditApplicationId}'
export const SET_LOANS = '/Refinancing/{id}/refinancing'

const refinancingApi = {
  getLoans: makeGetListFromApi<LoanRefinancingOutputDto>(GET_LOANS),
  update: makeUpdate<Refinancing, CreditApplication>(SET_LOANS),
}

export const getLoansApi = {
  loans: async (client: AxiosInstance, creditApplicationId: string): Promise<LoanRefinancingOutputDto[]> => {
    const payload: AxiosRequestConfig = {
      method: 'GET',
      url: `/Refinancing/GetLoansForRefinancing/${creditApplicationId}`,
    }

    const response = await client.request(payload)
    return response.data as LoanRefinancingOutputDto[]
  },
}

export default refinancingApi
