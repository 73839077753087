import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material'
import { Cancel as CancelIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { AutoWorksheetRevision, EFinancingProgram, EWorksheetStatus } from '../../../data/types/index'
import { formatDate, formatTime, FormatCurrency } from '../../../services/Formatter'
import { TRootState, useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'

type RowProps = {
  newWorksheetRevision: AutoWorksheetRevision
  oldWorksheetRevision: AutoWorksheetRevision
}

const AutoWorksheetHistoryRow = ({ newWorksheetRevision, oldWorksheetRevision }: RowProps) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const GetColorAfterComparingValues = (
    newValue: string | undefined | number | null,
    oldValue: string | undefined | number | null,
  ) => {
    if (newValue === oldValue)
      return theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black
    return theme.palette.mode === 'dark' ? theme.palette.error.dark : theme.palette.error.light
  }

  const oldProductValues =
    (oldWorksheetRevision.accidentAndHealthInsurance ?? 0) +
    (oldWorksheetRevision.extendedService ?? 0) +
    (oldWorksheetRevision.gapInsurance ?? 0) +
    (oldWorksheetRevision.lifeInsurance ?? 0) +
    (oldWorksheetRevision.taxesOnInsurance ?? 0)

  const newProductValues =
    (newWorksheetRevision.accidentAndHealthInsurance ?? 0) +
    (newWorksheetRevision.extendedService ?? 0) +
    (newWorksheetRevision.gapInsurance ?? 0) +
    (newWorksheetRevision.lifeInsurance ?? 0) +
    (newWorksheetRevision.taxesOnInsurance ?? 0)

  const autoPlanEnum = useAppSelector((state: TRootState) =>
    appSelectors.getPlanEnumForProgram(state, EFinancingProgram.Auto),
  )

  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum

  return (
    <TableRow key={newWorksheetRevision.id}>
      <TableCell align="center">{newWorksheetRevision.updatedByUserFullname}</TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(newWorksheetRevision.vehicle.vin, oldWorksheetRevision.vehicle.vin),
        }}
      >
        {newWorksheetRevision.vehicle.vin}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(newWorksheetRevision.vehicle.make, oldWorksheetRevision.vehicle.make),
        }}
      >
        {newWorksheetRevision.vehicle.make}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(newWorksheetRevision.vehicle.model, oldWorksheetRevision.vehicle.model),
        }}
      >
        {newWorksheetRevision.vehicle.model}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(newWorksheetRevision.vehicle.series, oldWorksheetRevision.vehicle.series),
        }}
      >
        {newWorksheetRevision.vehicle.series}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(newWorksheetRevision.price, oldWorksheetRevision.price),
        }}
      >
        {FormatCurrency(newWorksheetRevision.price)}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(
            newWorksheetRevision.vehicleAccAndFees,
            oldWorksheetRevision.vehicleAccAndFees,
          ),
        }}
      >
        {FormatCurrency(newWorksheetRevision.vehicleAccAndFees)}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(newProductValues, oldProductValues),
        }}
      >
        {FormatCurrency(newProductValues)}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(newWorksheetRevision.cashDown, oldWorksheetRevision.cashDown),
        }}
      >
        {FormatCurrency(newWorksheetRevision.cashDown)}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(
            newWorksheetRevision.totalAmountFinanced,
            oldWorksheetRevision.totalAmountFinanced,
          ),
        }}
      >
        {FormatCurrency(newWorksheetRevision.totalAmountFinanced)}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(newWorksheetRevision.term, oldWorksheetRevision.term),
        }}
      >
        {`${newWorksheetRevision.term ?? 0}  ${t('worksheet.month')}`}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(
            newWorksheetRevision.paymentAmountForFrequency,
            oldWorksheetRevision.paymentAmountForFrequency,
          ),
        }}
      >
        {FormatCurrency(newWorksheetRevision.paymentAmountForFrequency)}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(newWorksheetRevision.financingPlan, oldWorksheetRevision.financingPlan),
        }}
      >
        {newWorksheetRevision.financingPlan !== undefined
          ? autoPlanEnum[newWorksheetRevision.financingPlan][lang]
          : 'N/A'}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(newWorksheetRevision.margin, oldWorksheetRevision.margin),
        }}
      >
        {FormatCurrency(newWorksheetRevision?.margin)}
      </TableCell>
      <TableCell align="center">
        <>
          {formatDate(newWorksheetRevision.updatedOn)}
          <br />
          {formatTime(newWorksheetRevision.updatedOn)}
        </>
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(newWorksheetRevision.status, oldWorksheetRevision.status),
        }}
      >
        {newWorksheetRevision.status === EWorksheetStatus.Active
          ? newWorksheetRevision.status
          : newWorksheetRevision?.finalDecision?.decision}
      </TableCell>
      <TableCell />
    </TableRow>
  )
}

type TableProps = {
  worksheetRevisions: AutoWorksheetRevision[]
}

const AutoWorksheetHistoryTable = ({ worksheetRevisions }: TableProps) => {
  const { t } = useTranslation()
  return (
    <Paper elevation={3} sx={{ borderRadius: 2, p: 2, mt: 3, mb: 3 }}>
      <TableContainer sx={{ mt: 2, maxHeight: 800 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">{t('worksheetHistory.user')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.vin')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.make')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.model')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.series')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.retailPrice')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.accessories')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.products')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.cashdown')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.totalFinanced')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.term')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.payment')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.plan')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.margin')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.DateTime')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.status')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {worksheetRevisions.length !== 0 &&
              worksheetRevisions.map((worksheetRevision, index, worksheetRevisionsArray) => (
                <AutoWorksheetHistoryRow
                  newWorksheetRevision={worksheetRevision}
                  oldWorksheetRevision={
                    worksheetRevisionsArray[index + 1 === worksheetRevisionsArray.length ? index : index + 1]
                  }
                  key={worksheetRevision.id}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

type Props = {
  onClose: () => void
  worksheetRevisions: AutoWorksheetRevision[]
  open: boolean
}

const AutoWorksheetHistoryDialog = ({ onClose, worksheetRevisions, open }: Props) => {
  const { t } = useTranslation()
  return (
    <Dialog
      onClose={onClose}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
      keepMounted
      transitionDuration={0}
      disablePortal
      BackdropProps={{ transitionDuration: 0 }}
    >
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title">
        {t('worksheetHistory.worksheetHistory')}
      </DialogTitle>
      <DialogContent>
        <AutoWorksheetHistoryTable worksheetRevisions={worksheetRevisions} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="warning" startIcon={<CancelIcon />} onClick={onClose}>
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(AutoWorksheetHistoryDialog)
