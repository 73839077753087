import React from 'react'
import { Button, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Gavel as GavelIcon } from '@mui/icons-material'
import { AutoWorksheet, EDecision, EWorksheetStatus } from '../../../data/types'
import DecisonIcon from '../../CreditDecisionPage/components/decisonIcon'

type Props = {
  worksheet: AutoWorksheet
  onClick: () => void
  editDisabled: boolean
  userCanEditWorksheet: boolean
}

const WorksheetDecision = ({ worksheet, onClick, editDisabled, userCanEditWorksheet }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <>
      <Typography variant="h6" component="h3" gutterBottom>
        {t('credit.finalDecision')}
      </Typography>
      <DecisonIcon decision={worksheet.finalDecision.decision as unknown as EDecision} />
      <Typography
        variant="subtitle1"
        sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
        component="p"
      >
        {t('credit.maxPayment')}: {worksheet.paymentAmountForFrequency} / {t('worksheet.monthLowerCase')}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
        component="p"
      >
        {t('worksheet.maxTerme')}: TODO
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
        component="p"
      >
        {t('worksheet.maxFinancing')}: TODO
      </Typography>
      <Button
        variant="contained"
        startIcon={<GavelIcon />}
        size="large"
        sx={{ mt: 2 }}
        onClick={onClick}
        disabled={worksheet.status !== EWorksheetStatus.Active || editDisabled || !userCanEditWorksheet}
      >
        {t('credit.decision')}
      </Button>
    </>
  )
}

export default WorksheetDecision
