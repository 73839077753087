import { EFinancingProgram, SelectValueListItem } from '../data/types'
import { TranslatedEnum } from '../data/types/TranslatedEnum'

export default function buildSelectValueListFromEnum(
  srcEnum: (string | number)[],
  labelPrefix: string,
  emptyValue: string | number | undefined = undefined,
  excludeValue: string[] | number[] | undefined = undefined,
): SelectValueListItem[] {
  const prefix = labelPrefix ? `${labelPrefix}.` : ''
  let ret = srcEnum.map((item) => ({ label: `${prefix}${item}`, value: item }))

  if (emptyValue !== undefined) {
    ret.unshift({ label: '', value: emptyValue })
  }
  if (excludeValue !== undefined) {
    ret = ret.filter((el) => {
      return !excludeValue.includes(el.value as never)
    })
  }
  return ret
}

export function buildSelectValueListFromEnumWithTranslation(
  srcEnum: Record<string, TranslatedEnum>,
  language: keyof TranslatedEnum,
  validFinancingProgramsForCompany: EFinancingProgram[],
  emptyValue: string | undefined = undefined,
  excludeValue: string[] | number[] | undefined = undefined,
): SelectValueListItem[] {
  let ret = Object.values(srcEnum).map((item: TranslatedEnum) => {
    return { label: item[language], value: item.id }
  })

  if (emptyValue !== undefined) {
    ret.unshift({ label: '', value: emptyValue })
  }
  if (excludeValue !== undefined) {
    ret = ret.filter((el) => {
      return !excludeValue.includes(el.value as never)
    })
  }
  if (validFinancingProgramsForCompany === undefined) {
    return ret
  }
  ret = ret.filter((el) => {
    return validFinancingProgramsForCompany.includes(el.value as EFinancingProgram)
  })
  return ret
}
