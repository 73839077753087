import { Stack, Typography } from '@mui/material'
import React from 'react'
import { Vehicle } from '../../../data/types'
import { formatNumber } from '../../../services/Formatter'

type Props = {
  vehicle: Vehicle
  title: string
}

const VehicleInformation = ({ vehicle, title }: Props) => {
  return (
    <Stack padding={2} direction="column" alignItems="flex-start" justifyContent="space-between">
      <Typography variant="body1" component="div" sx={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Typography variant="body1" component="div">
        {vehicle.vin}
      </Typography>
      <Typography variant="body1" component="div">
        {vehicle.make} {vehicle.model}
      </Typography>
      <Typography variant="body1" component="div">
        {vehicle.bodyStyle}
      </Typography>
      <Typography variant="body1" component="div">
        {vehicle.includes.map((value) => ` ${value}`)}
      </Typography>
      <Typography variant="body1" component="div">
        {formatNumber(vehicle.mileage)} KM
      </Typography>
    </Stack>
  )
}

export default VehicleInformation
