import { alpha, Badge, Button, Checkbox, ImageListItemBar, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import React from 'react'
import {
  RotateLeft as RotateLeftIcon,
  RotateRight as RotateRightIcon,
  Delete as DeleteIcon,
  DeleteOutline as DeleteOutlineIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { IconButtonWithTooltip } from '../../../components'
import { EDocumentPageStatus } from '../../../data/types'
import { DocumentPageDisplayInfo } from '../../../data/types/DocumentPageDisplayInfo'

type Props = {
  page: DocumentPageDisplayInfo
  onRotatePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onDeletePage: (event: React.ChangeEvent<HTMLInputElement>) => void
  getTitle: (page: DocumentPageDisplayInfo) => string
  onImageClicked: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  showBanners: boolean
  index: number
  selectedPages: string[]
  isDraggable: boolean
  isActionDisabled: boolean
}

const Thumbnail = ({
  page,
  onRotatePage,
  onDeletePage,
  getTitle,
  onImageClicked,
  showBanners,
  index,
  selectedPages,
  isDraggable,
  isActionDisabled,
}: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <div style={{ width: 'auto', height: 'auto' }}>
      {isDraggable && (
        <img
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'fill',
            transform: `rotate(${page.rotation}deg)`,
          }}
          src={page.src}
          srcSet={page.src}
          alt="Loading"
          loading="lazy"
        />
      )}
      {!isDraggable && (
        <Button
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'auto',
            cursor: 'pointer',
            p: 0,
          }}
          id={page.id}
          disabled={isActionDisabled}
          onClick={onImageClicked}
        >
          <img
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'fill',
              transform: `rotate(${page.rotation}deg)`,
            }}
            src={page.src}
            srcSet={page.src}
            alt="Loading"
            loading="lazy"
          />
        </Button>
      )}
      {showBanners && (
        <div>
          <ImageListItemBar
            title={
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <IconButtonWithTooltip
                  tooltip={t('document.rotateLeft')}
                  icon={<RotateLeftIcon sx={{ color: 'rgba(255, 255, 255, 0.54)' }} />}
                  aria-label="rotateLeft"
                  aria-rowindex={index}
                  aria-valuenow={page.rotation}
                  onClick={onRotatePage}
                  disabled={isActionDisabled}
                />

                <Checkbox
                  checked={page.pageStatus === EDocumentPageStatus.Deleted}
                  color="error"
                  icon={<DeleteOutlineIcon />}
                  checkedIcon={<DeleteIcon />}
                  value={index}
                  name={page.pageStatus}
                  onChange={onDeletePage}
                  disabled={isActionDisabled}
                />

                <IconButtonWithTooltip
                  tooltip={t('document.rotateRight')}
                  icon={<RotateRightIcon sx={{ color: 'rgba(255, 255, 255, 0.54)' }} />}
                  aria-label="rotateRight"
                  aria-rowindex={index}
                  aria-valuenow={page.rotation}
                  onClick={onRotatePage}
                  disabled={isActionDisabled}
                />

                <Typography variant="body2" position="absolute" right={0} marginRight="0.5rem" marginTop="2.5rem">
                  {`${page.pageNumber}`}
                </Typography>
              </Stack>
            }
          />
          <ImageListItemBar
            sx={{
              cursor: 'pointer',
              backgroundColor:
                page.pageStatus === EDocumentPageStatus.Deleted ? alpha(theme.palette.error.main, 0.5) : '',
            }}
            title={
              <Tooltip title={<span>{getTitle(page)}</span>} arrow placement="top">
                <span> {getTitle(page)}</span>
              </Tooltip>
            }
            position="top"
            actionIcon={
              <Stack direction="row" justifyContent="center">
                <div>
                  {page.pageStatus === EDocumentPageStatus.New && (
                    <Badge color="primary" badgeContent="NEW" sx={{ mr: 3 }} />
                  )}
                </div>

                <div>
                  {selectedPages.findIndex((x) => x === page.id) !== -1 && (
                    <Badge
                      color="primary"
                      badgeContent={selectedPages.findIndex((x) => x === page.id) + 1}
                      sx={{ mr: 2 }}
                    >
                      <CheckCircleIcon color="primary" />
                    </Badge>
                  )}
                </div>
              </Stack>
            }
          />
        </div>
      )}
    </div>
  )
}

export default Thumbnail
