import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User, EFinancingCompany, SELECTED_COMPANY_LOCAL_STORAGE } from '@src/data/types'
import type { TRootState } from '..'
import { apiStateSelectors } from '../ApiStateStore'

interface UserStore {
  user: User | null
  needToAuthenticate: boolean
  prefersDarkMode: boolean
  loginPreference: number
  selectedCompany: EFinancingCompany | null
}

const initialState: UserStore = {
  user: null,
  needToAuthenticate: false,
  prefersDarkMode: false,
  loginPreference: 0,
  selectedCompany: null,
}

const PREFERS_DARK_SETTING = 'darkMode'
const LOGIN_PREFERENCE = 'loginPreference'

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setMustAuthenticate: (state, action: PayloadAction<boolean>) => {
      state.needToAuthenticate = action.payload
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.needToAuthenticate = false
      state.user = action.payload
    },
    loadPreferences: (state) => {
      const darkModePref = localStorage.getItem(PREFERS_DARK_SETTING)
      if (darkModePref) state.prefersDarkMode = darkModePref === 'true'
      else {
        state.prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
      }
      const loginPreference = localStorage.getItem(LOGIN_PREFERENCE)
      if (loginPreference) state.loginPreference = Number(loginPreference)
    },
    setDarkModePreference: (state, action: PayloadAction<boolean>) => {
      state.prefersDarkMode = action.payload
      localStorage.setItem(PREFERS_DARK_SETTING, action.payload.toString())
    },
    setLoginPreference: (state, action: PayloadAction<number>) => {
      state.loginPreference = action.payload
      localStorage.setItem(LOGIN_PREFERENCE, action.payload.toString())
    },
    setSelectedCompany: (state, action: PayloadAction<EFinancingCompany>) => {
      state.selectedCompany = action.payload
      localStorage.setItem(SELECTED_COMPANY_LOCAL_STORAGE, action.payload.toString())
    },
  },
})

export default userSlice.reducer

// Action creators are generated for each case reducer function
export const userActions = userSlice.actions

// effect actions
export const LOGIN = 'user/login'

// Other code such as selectors can use the imported `RootState` type
export const userSelectors = {
  getUser: (state: TRootState) => state.user.user,
  needToAuthenticate: (state: TRootState) => state.user.needToAuthenticate,
  selectedCompany: (state: TRootState) => state.user.selectedCompany,
  isIcebergGrDevs: (state: TRootState) => state.user.user?.roles?.includes('IcebergGrDevs') ?? false,
  isLoggingIn: (state: TRootState) => apiStateSelectors.isLoading(state, LOGIN),
  isDarkModeEnabled: (state: TRootState) => state.user.prefersDarkMode,
  getLoginPreference: (state: TRootState) => state.user.loginPreference,
}
