import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { EFinancingProgram } from '@src/data/types'
import { CreditApplicationMessage } from '../../types/CreditApplicationMessage'
import { buildUrlWithIds, makeCreate, makeDelete, makeGetListWithIdsFromApi } from '../make-api'

const MESSAGE_BASE_URL = '/Applications/{financingProgramId}/{creditApplicationId}/Messages'
const messageApi = {
  create: makeCreate<CreditApplicationMessage>(MESSAGE_BASE_URL),
  getMessageList: makeGetListWithIdsFromApi<CreditApplicationMessage>(MESSAGE_BASE_URL),

  sendRequiredDocumentsMessage: async (
    apiClient: AxiosInstance,
    creditApplicationId: string,
    financingProgramId: EFinancingProgram,
  ): Promise<CreditApplicationMessage> => {
    const payload: AxiosRequestConfig<CreditApplicationMessage> = {
      method: 'POST',
      url: buildUrlWithIds(MESSAGE_BASE_URL.concat('/SendRequiredDocumentsMessage'), {
        creditApplicationId,
        financingProgramId,
      }),
    }

    const response = await apiClient.request(payload)
    return response.data as CreditApplicationMessage
  },

  deleteMessage: (creditApplicationId: string, financingProgramId: EFinancingProgram, id: string) =>
    makeDelete<CreditApplicationMessage>(
      buildUrlWithIds(MESSAGE_BASE_URL.concat('/{id}'), {
        creditApplicationId,
        financingProgramId,
        id,
      }),
    ),
}

export default messageApi
