import React from 'react'
import { Box, Divider, IconButton, List, ListItem, ListItemText, ListSubheader } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DownloadIcon from '@mui/icons-material/Download'
import { useTranslation } from 'react-i18next'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import reportAllApplicationEffect from '@src/data/store/report/reportAllApplication-effects'
import reportBenoitEffect from '@src/data/store/report/reportBenoit-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import reportActEffect from '@src/data/store/report/reportAct-effects'

const Reports = (): JSX.Element => {
  const { t } = useTranslation()

  const dispatchEffect = useSideEffect()

  const handleAllCreditApplicationsReportClick = async () => {
    dispatchEffect(reportAllApplicationEffect.getReportAllApplication()).catch(reportErrorToConsole)
  }

  const handleReportBenoit = async () => {
    dispatchEffect(reportBenoitEffect.getReportBenoit()).catch(reportErrorToConsole)
  }

  const handleReportAct = async () => {
    dispatchEffect(reportActEffect.getReportAct()).catch(reportErrorToConsole)
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <List
        sx={{ minWidth: 350, maxWidth: '100%' }}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {t('report.allReport')}
          </ListSubheader>
        }
      >
        <ListItem
          secondaryAction={
            <IconButton href="/reports/payoutThatSkippedIncomeValidation">
              <VisibilityIcon />
            </IconButton>
          }
        >
          <ListItemText primary={t('report.payoutWithoutFlinks.title')} />
        </ListItem>
        <Divider />
        <ListItem
          secondaryAction={
            <IconButton onClick={() => handleAllCreditApplicationsReportClick()}>
              <DownloadIcon />
            </IconButton>
          }
        >
          <ListItemText primary={t('report.reportAllApplication')} />
        </ListItem>
        <Divider />
        <ListItem
          secondaryAction={
            <IconButton onClick={() => handleReportBenoit()}>
              <DownloadIcon />
            </IconButton>
          }
        >
          <ListItemText primary={t('report.reportBenoit')} />
        </ListItem>
        <Divider />
        <ListItem
          secondaryAction={
            <IconButton onClick={() => handleReportAct()}>
              <DownloadIcon />
            </IconButton>
          }
        >
          <ListItemText primary={t('report.reportAct')} />
        </ListItem>
      </List>
    </Box>
  )
}

export default Reports
