import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { nanoid } from 'nanoid'
import { useTranslation } from 'react-i18next'
import { CreditReportLocalInquiry } from '@src/data/types/CreditReportSchema'
import { formatDate } from '@src/services/Formatter'
import Row from './tableRow'

type LocalInquiriesProps = {
  localInquiries: CreditReportLocalInquiry[]
}

const LocalInquiries = ({ localInquiries }: LocalInquiriesProps) => {
  const { t } = useTranslation()
  return (
    <Row id="localInquiries" name={t('creditReport.localInquiries')}>
      <TableContainer>
        <Table sx={{ display: 'contents' }} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">{t('creditReport.number')}</TableCell>
              <TableCell align="left">{t('creditReport.name')}</TableCell>
              <TableCell align="left">{t('creditReport.phoneNumber')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {localInquiries.map((item) => (
              <TableRow key={nanoid()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align="left">
                  {formatDate(item.date)}
                </TableCell>
                <TableCell align="left">{item.customerNumber}</TableCell>
                <TableCell align="left">{item.name}</TableCell>
                <TableCell align="left">{item.phone}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Row>
  )
}

export default LocalInquiries
