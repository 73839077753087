import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  Typography,
} from '@mui/material'
import { ArrowBack as ArrowBackIcon, Refresh as RefreshIcon } from '@mui/icons-material'
import { Trans, useTranslation } from 'react-i18next'
import { appActions } from '../../../data/store/AppStore/app-store'
import { Conflict } from '../../../data/types/Conflict'
import { formatDateTime } from '../../../services/Formatter'
import { useAppDispatch } from '../../../data/store'

type Props = {
  conflict: Conflict | null
  open: boolean
}

const ConflictDialog = ({ conflict, open }: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  return (
    <Paper>
      <Dialog open={open} fullWidth onClose={() => dispatch(appActions.setConflict(null))}>
        <DialogTitle>{t('conflict.modificationConflict')}</DialogTitle>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <Typography marginBottom={2}>
              <Trans
                i18nKey="conflict.cantSaveModifiedBy"
                values={{
                  userFullName: conflict?.userFullName,
                  updatedOn: formatDateTime(conflict?.updatedOn),
                }}
              />
            </Typography>
            <Typography>{`${t('conflict.closeOrReload')}.`}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => dispatch(appActions.setConflict(null))}
            startIcon={<ArrowBackIcon />}
          >
            {t('conflict.backToWork')}
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={() => window.location.reload()}
            startIcon={<RefreshIcon />}
          >
            {t('conflict.reloadPage')}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default ConflictDialog
