import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
} from '@mui/material'
import { creditEffects } from '@src/data/store/CreditApplication'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { EFinancingProgram } from '@src/data/types'
import React, { useCallback, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface CopyLoanAsDraftDialogProps {
  open: boolean
  onClose: () => void
  financingProgramId: EFinancingProgram
  creditApplicationId: string
}

const defaultValues = {
  copyMessages: true,
  copyDocuments: true,
  copyCoapplicant: true,
}

const CopyLoanAsDraftDialog = ({
  open,
  onClose,
  financingProgramId,
  creditApplicationId,
}: CopyLoanAsDraftDialogProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatchEffect = useSideEffect()
  const [loading, setLoading] = useState(false)
  const onSubmit: SubmitHandler<typeof defaultValues> = useCallback(
    (formData) => {
      setLoading(true)
      dispatchEffect(
        creditEffects.copyCreditApplicationAsDraft({
          creditApplicationId,
          financingProgramId,
          ...formData,
        }),
      )
        .then((data) => {
          navigate(`/Applications/${data.financingProgramId}/${data.id}/edit`)
        })
        .catch(() => {
          setLoading(false)
        })
    },
    [financingProgramId, creditApplicationId, dispatchEffect, navigate],
  )
  const { control, handleSubmit } = useForm({
    defaultValues,
  })
  const handleClose = useCallback(() => {
    if (!loading) {
      onClose()
    }
  }, [loading, onClose])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(onSubmit),
      }}
    >
      <DialogTitle>{t('editCreditApplication.copyAsDraft.title')}</DialogTitle>
      <DialogContent>
        <Stack>
          <FormControlLabel
            control={
              <Controller
                name="copyMessages"
                control={control}
                render={({ field: { ref, value, ...fields } }) => (
                  <Checkbox inputRef={ref} checked={value} {...fields} />
                )}
              />
            }
            label={t('editCreditApplication.copyAsDraft.copyMessages')}
          />
          <FormControlLabel
            control={
              <Controller
                name="copyDocuments"
                control={control}
                render={({ field: { ref, value, ...fields } }) => (
                  <Checkbox inputRef={ref} checked={value} {...fields} />
                )}
              />
            }
            label={t('editCreditApplication.copyAsDraft.copyDocuments')}
          />
          <FormControlLabel
            control={
              <Controller
                name="copyCoapplicant"
                control={control}
                render={({ field: { ref, value, ...fields } }) => (
                  <Checkbox inputRef={ref} checked={value} {...fields} />
                )}
              />
            }
            label={t('editCreditApplication.copyAsDraft.copyCoapplicant')}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          {t('common.cancel')}
        </Button>
        <Button type="submit" disabled={loading}>
          {t('common.copy')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(CopyLoanAsDraftDialog)
