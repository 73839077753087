/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { Collapse, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CreditReport } from '@src/data/types/CreditReportSchema'
import { EFinancingProgram } from '@src/data/types'

type OnlineDasProps = {
  creditReport: CreditReport
  financingProgramId: EFinancingProgram
  viewOnlineDas: boolean
}

const OnlineDasAutoSection = (creditReport: CreditReport) => {
  const { t } = useTranslation()

  return (
    <>
      <ListItemText primary={`IQAL005 : ${creditReport.iqal005}`} secondary={t('creditReport.onlineDas.iqal005')} />
      <ListItemText primary={`TCNC023 : ${creditReport.tcnc023}`} secondary={t('creditReport.onlineDas.tcnc023')} />
      <ListItemText primary={`TCAM034 : ${creditReport.tcam034}`} secondary={t('creditReport.onlineDas.tcam034')} />
      <ListItemText primary={`TCAF022 : ${creditReport.tcaf022}`} secondary={t('creditReport.onlineDas.tcaf022')} />
      <ListItemText primary={`OTXX004 : ${creditReport.otxx004}`} secondary={t('creditReport.onlineDas.otxx004')} />
      <ListItemText primary={`TCAL036 : ${creditReport.tcal036}`} secondary={t('creditReport.onlineDas.tcal036')} />
      <ListItemText primary={`F2DY4142 : ${creditReport.f2dy4142}`} secondary={t('creditReport.onlineDas.f2dy4142')} />
      <ListItemText primary={`TCTT072 : ${creditReport.tctt072}`} secondary={t('creditReport.onlineDas.tctt072')} />

      <ListItemText primary={`TCTT073 : ${creditReport.tctt073}`} secondary={t('creditReport.onlineDas.tctt073')} />

      <ListItemText primary={`TCTT029 : ${creditReport.tctt029}`} secondary={t('creditReport.onlineDas.tctt029')} />

      <ListItemText primary={`TCAL023 : ${creditReport.tcal023}`} secondary={t('creditReport.onlineDas.tcal023')} />

      <ListItemText primary={`TCAF023 : ${creditReport.tcaf023}`} secondary={t('creditReport.onlineDas.tcaf023')} />

      <ListItemText primary={`PRXX001 : ${creditReport.prxx001}`} secondary={t('creditReport.onlineDas.prxx001')} />

      <ListItemText primary={`TCAF030 : ${creditReport.tcaf030}`} secondary={t('creditReport.onlineDas.tcaf030')} />
      <ListItemText primary={`inqam005 : ${creditReport.inqam005}`} secondary={t('creditReport.onlineDas.inqam005')} />
      <ListItemText primary={`TCAF108 : ${creditReport.tcaf108}`} secondary={t('creditReport.onlineDas.tcaf108')} />
      <ListItemText primary={`FBXY004 : ${creditReport.fbxy004}`} secondary={t('creditReport.onlineDas.fbxy004')} />
      <ListItemText primary={`FBVY025 : ${creditReport.fbvy025}`} secondary={t('creditReport.onlineDas.fbvy025')} />
      <ListItemText primary={`OTXX013 : ${creditReport.otxx013}`} secondary={t('creditReport.onlineDas.otxx013')} />
    </>
  )
}

const OnlineDasPersonalLoanSection = (creditReport: CreditReport) => {
  const { t } = useTranslation()

  return (
    <>
      <ListItemText primary={`TCAL023 : ${creditReport.tcal023}`} secondary={t('creditReport.onlineDas.tcal023')} />
      <ListItemText primary={`TCAL031 : ${creditReport.tcal031}`} secondary={t('creditReport.onlineDas.tcal031')} />
      <ListItemText primary={`TCMG034 : ${creditReport.tcmg034}`} secondary={t('creditReport.onlineDas.tcmg034')} />
      <ListItemText primary={`TCNC031 : ${creditReport.tcnc031}`} secondary={t('creditReport.onlineDas.tcnc031')} />
      <ListItemText primary={`INQAX005 : ${creditReport.inqax005}`} secondary={t('creditReport.onlineDas.inqax005')} />
      <ListItemText primary={`TCTE031 : ${creditReport.tcte031}`} secondary={t('creditReport.onlineDas.tcte031')} />
    </>
  )
}

const OnlineDasSection = ({ creditReport, financingProgramId, viewOnlineDas }: OnlineDasProps) => {
  return (
    <Collapse in={viewOnlineDas} timeout="auto" unmountOnExit>
      {financingProgramId === EFinancingProgram.Auto && OnlineDasAutoSection(creditReport)}
      {financingProgramId === EFinancingProgram.Personal && OnlineDasPersonalLoanSection(creditReport)}
    </Collapse>
  )
}

export default OnlineDasSection
