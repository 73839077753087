import { makeCreate, makeGetListWithIdsFromApi, makeGetSingleFromApi, makeUpdate } from '../make-api'
import { WorksheetDecision, VehicleEvaluationDTO, AutoWorksheet, AutoWorksheetRevision } from '../../types'

const baseUrl = '/Applications/auto/{creditApplicationId}/worksheet'
const autoWorksheetApi = {
  getById: makeGetSingleFromApi<AutoWorksheet>(baseUrl),
  create: makeCreate<AutoWorksheet>(baseUrl),
  update: makeUpdate<AutoWorksheet>(baseUrl),
  updateVehicleEvaluationDTO: makeUpdate<VehicleEvaluationDTO, AutoWorksheet>(`${baseUrl}/VehicleEvaluation`),
  updateDecision: makeUpdate<WorksheetDecision, AutoWorksheet>(`${baseUrl}/Decision`),
  getWorksheetRevisions: makeGetListWithIdsFromApi<AutoWorksheetRevision>(`${baseUrl}/Revisions`),
}

export default autoWorksheetApi
