import { AnySchema } from 'yup'
import * as yup from './common/yup-extended'
import { EProvince, EProvinceList } from './constants'

export const BaseAddressSchema = yup.default.object({
  civicNumber: yup.default.string().nullable().default(''),
  street: yup.default.string().nullable().default(''),
  apartment: yup.default.string().nullable().nullable(),
  suite: yup.default.string().nullable().nullable(),
  city: yup.default.string().nullable().default(''),
  postalCode: yup.default.string().nullable().default('').IsValidCanadianPostalCode(),
  country: yup.default.string().nullable().default('Canada'),
  stateIso: yup.default.mixed<EProvince>().when({
    is: (value: string | null) => {
      return !!value
    },
    then: yup.default
      .mixed<EProvince>()
      .nullable()
      .default('' as EProvince)
      .oneOf(EProvinceList),
    otherwise: yup.default
      .mixed<EProvince>()
      .nullable()
      .default('' as EProvince),
  }),
  note: yup.default.string().nullable().default(''),
  years: yup.default.number().nullable().min(0).default(0),
  months: yup.default.number().nullable().min(0).default(0),
})

export type BaseAddress = yup.default.InferType<typeof BaseAddressSchema>

export const AddressSchema = BaseAddressSchema.when((value: BaseAddress, schema: typeof BaseAddressSchema) => {
  const modifiedFields: Record<string, AnySchema> = {}
  if (value?.civicNumber || value?.apartment || value?.suite || value?.street || value?.city || value?.postalCode) {
    modifiedFields.civicNumber = schema.fields.civicNumber.required()
    modifiedFields.street = schema.fields.street.required()
    modifiedFields.city = schema.fields.city.required()
    modifiedFields.postalCode = schema.fields.postalCode.required()
    modifiedFields.stateIso = schema.fields.stateIso.required()
  }
  return schema.shape(modifiedFields)
})

export type Address = yup.default.InferType<typeof AddressSchema>

export const FullAddressSchema = BaseAddressSchema.shape({
  civicNumber: BaseAddressSchema.fields.civicNumber.required(),
  street: BaseAddressSchema.fields.street.required(),
  city: BaseAddressSchema.fields.city.required(),
  postalCode: BaseAddressSchema.fields.postalCode.required(),
  stateIso: BaseAddressSchema.fields.stateIso.required(),
  country: BaseAddressSchema.fields.country.required(),
})

export type FullAddress = yup.default.InferType<typeof FullAddressSchema>
