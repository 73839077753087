import { AutoRslaWorksheet, EditAutoRslaWorksheetDto } from '@src/data/types/AutoRslaWorksheet'
import { makeCreate, makeGetSingleFromApi, makeUpdate } from '../make-api'

const baseUrl = '/Applications/auto-rsla/{creditApplicationId}/Worksheet'
const autoRslaWorksheetApi = {
  getById: makeGetSingleFromApi<AutoRslaWorksheet>(baseUrl),
  create: makeCreate<EditAutoRslaWorksheetDto, AutoRslaWorksheet>(baseUrl),
  update: makeUpdate<EditAutoRslaWorksheetDto, AutoRslaWorksheet>(baseUrl),
}

export default autoRslaWorksheetApi
