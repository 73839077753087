import type { AutoWorksheet, AutoWorksheetRevision, VehicleEvaluationDTO, AutoWorksheetDecisionDTO } from '../../types'
import allApi from '../../api'
import { makeGetById, makeGetListWithIds, makeSave } from '../effects/standard-side-effects'
import {
  GET_AUTO_WORKSHEET_BY_ID,
  AutoworksheetSlice,
  SAVE_AUTO_WORKSHEET,
  SAVE_VEHICLE_INTERNAL_EVALUATION,
  SAVE_DECISION,
  GET_AUTO_WORKSHEET_REVISIONS,
} from './auto-worksheet-store'

const worksheetEffects = {
  getById: (id: string) =>
    makeGetById<AutoWorksheet>(
      GET_AUTO_WORKSHEET_BY_ID,
      allApi.worksheet.getById,
      AutoworksheetSlice.actions.setCurrent,
      {
        id,
      },
    ),
  create: (creditApplicationId: string) =>
    makeSave<AutoWorksheet>(SAVE_AUTO_WORKSHEET, allApi.worksheet.create, AutoworksheetSlice.actions.setCurrent, {
      creditApplicationId,
    }),
  update: (data: Partial<AutoWorksheet>) =>
    makeSave<AutoWorksheet>(SAVE_AUTO_WORKSHEET, allApi.worksheet.update, AutoworksheetSlice.actions.setCurrent, data),
  updateVehicleEvaluationDTO: (data: VehicleEvaluationDTO) =>
    makeSave<VehicleEvaluationDTO, AutoWorksheet>(
      SAVE_VEHICLE_INTERNAL_EVALUATION,
      allApi.worksheet.updateVehicleEvaluationDTO,
      AutoworksheetSlice.actions.setCurrent,
      data,
    ),
  updateDecision: (data: AutoWorksheetDecisionDTO) =>
    makeSave<AutoWorksheetDecisionDTO, AutoWorksheet>(
      SAVE_DECISION,
      allApi.worksheet.updateDecision,
      AutoworksheetSlice.actions.setCurrent,
      data,
    ),
  getWorksheetRevisions: (id: string) =>
    makeGetListWithIds<AutoWorksheetRevision>(
      GET_AUTO_WORKSHEET_REVISIONS,
      allApi.worksheet.getWorksheetRevisions,
      AutoworksheetSlice.actions.setCurrentRevisions,
      { id },
    ),
}

export default worksheetEffects
