import { Box, Grid, Stack, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Vehicle } from '../../../data/types'
import { FormatCurrency, formatNumber } from '../../../services/Formatter'

type Props = {
  vehicle: Vehicle
}

const TradeInVehicleSummary = ({ vehicle }: Props) => {
  const { t } = useTranslation()

  return (
    <div aria-hidden="true">
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Typography variant="h6" component="h6" sx={{ paddingLeft: 2, paddingTop: 2 }}>
          {t('worksheet.tradeInVehicle')}
        </Typography>
      </Box>
      <Grid container justifyContent="space-between" alignItems="center">
        <Stack direction="row">
          <Stack padding={2} direction="column" alignItems="flex-start">
            <Typography variant="body1" component="div">
              {vehicle.vin}
            </Typography>
            <Typography variant="body1" component="div">
              {vehicle.make} {vehicle.model}
            </Typography>
            <Typography variant="body1" component="div">
              {vehicle.bodyStyle}
            </Typography>
            <Typography variant="body1" component="div">
              {vehicle.includes.map((value) => ` ${value}`)}
            </Typography>
            <Typography variant="body1" component="div">
              {formatNumber(vehicle.mileage)} KM
            </Typography>
          </Stack>

          <Stack padding={2} direction="column">
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Typography variant="body1">{t('worksheet.allowance')}:</Typography>
              <Typography variant="body1">{FormatCurrency(vehicle.allowance)}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Typography variant="body1" component="div">
                {t('worksheet.lienAmount')}:
              </Typography>
              <Typography variant="body1" component="div">
                {FormatCurrency(vehicle.lienAmount)}
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <InfoIcon fontSize="small" />
              <Typography>{vehicle.balanceOwedTo}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Typography variant="body1" component="div">
                {t('worksheet.netTradeInAllowance')}:
              </Typography>
              <Typography variant="body1" component="div">
                {FormatCurrency(vehicle.netTradeInAllowance)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </div>
  )
}

export default TradeInVehicleSummary
