import React from 'react'
import { useTranslation } from 'react-i18next'
import { CreditApplication, CreditApplicationTask } from '../../../data/types'
import { EApplicantType, ETaskType } from '../../../data/types/constants'
import JobIncomeTaskDescription from './JobIncomeTaskDescription'
import OtherIncomeTaskDescription from './OtherIncomeTaskDescription'

type Props = { task: CreditApplicationTask; creditApplication: CreditApplication }

const TaskDescription = ({ task, creditApplication }: Props) => {
  const { t } = useTranslation()
  const isForApplicant = task.applicantType === EApplicantType.Applicant
  const renderComponentForSpecificType = () => {
    switch (task.typeId) {
      case ETaskType.ConfirmOtherIncome:
        return (
          <OtherIncomeTaskDescription
            otherIncomeId={task.subKey ? task.subKey : ''}
            isForApplicant={isForApplicant}
            creditApplication={creditApplication}
          />
        )
      case ETaskType.ConfirmJobIncome:
        return (
          <JobIncomeTaskDescription
            jobIncomeId={task.subKey ? task.subKey : ''}
            isForApplicant={isForApplicant}
            creditApplication={creditApplication}
          />
        )
      case ETaskType.VerifyWhyCvtSignatureWasDeclined:
        return <div>{t('tasks.electronicSignatureDeclineComment')}</div>
      default:
        return (
          <div>
            {task.labelSuffix} {task.labelSuffix2}
          </div>
        )
    }
  }

  return (
    <div>
      {renderComponentForSpecificType()}
      <div>{task.description}</div>
    </div>
  )
}

export default TaskDescription
