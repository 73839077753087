import allApi from '../../api'
import { makeGetList, makeGetWithFilters, makeGetStringList, makeSave } from '../effects/standard-side-effects'
import {
  creditTaskDashboardEntrySlice,
  GET_CREDIT_TASKS_DASHBOARD_ENTRY,
  GET_STATUS_COUNT,
  GET_TRUSTEE_NAMES_LIST,
  RESERVE_CREDIT_TASK,
} from './credit-task-dashboard-entry-store'
import { CreditTaskDashboardEntry } from '../../types/CreditTaskDashboardEntry'
import { CreditApplicationReservation, CreditApplicationReservationDto } from '../../types/CreditApplicationReservation'
import { StatusCount } from '../../types'

const creditTaskDashboardEntryEffects = {
  getTaskListByFilters: (filters?: Record<string, string | number | string[] | number[]>) =>
    makeGetList<CreditTaskDashboardEntry>(
      GET_CREDIT_TASKS_DASHBOARD_ENTRY,
      allApi.creditTaskDashboardEntries.getByFilters,
      creditTaskDashboardEntrySlice.actions.setTaskList,
      filters,
    ),
  getStatusCount: (filters?: Record<string, string | number | string[] | number[]>) =>
    makeGetWithFilters<StatusCount>(
      GET_STATUS_COUNT,
      allApi.creditTaskDashboardEntries.getStatusCount,
      creditTaskDashboardEntrySlice.actions.setStatusCount,
      filters,
    ),
  getTrusteeNames: () =>
    makeGetStringList(
      GET_TRUSTEE_NAMES_LIST,
      allApi.creditTaskDashboardEntries.getTrusteeNames,
      creditTaskDashboardEntrySlice.actions.setTrusteeNames,
    ),
  reserve: (data: CreditApplicationReservationDto) =>
    makeSave<CreditApplicationReservationDto, CreditApplicationReservation>(
      RESERVE_CREDIT_TASK,
      allApi.credit.createCreditApplicationLock,
      creditTaskDashboardEntrySlice.actions.setReserve,
      data,
    ),
}

export default creditTaskDashboardEntryEffects
