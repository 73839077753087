import { FundingComputedDto } from '@src/data/types/FundingComputedDto'
import { FundingInfoDto } from '@src/data/types/FundingInfoDto'
import allApi from '../../api'
import type {
  EditPersonalLoanWorksheetDto,
  PersonalLoanWorksheet,
  PersonalWorksheetDecisionDTO,
  PersonalWorksheetRevision,
} from '../../types'
import { makeGetById, makeGetListWithIds, makeSave } from '../effects/standard-side-effects'
import {
  COMPUTE_PERSONAL_LOAN_FUNDING,
  GET_PERSONAL_LOAN_WORKSHEET_REVISIONS,
  GET_PERSONAL_WORKSHEET_BY_ID,
  PersonalLoanWorksheetSlice,
  SAVE_PERSONAL_LOAN_WORKSHEET,
  SAVE_PERSONAL_WORKSHEET_DECISION,
} from './personal-loan-worksheet-store'

const personalLoanWorksheetEffects = {
  create: (data: EditPersonalLoanWorksheetDto) =>
    makeSave<EditPersonalLoanWorksheetDto, PersonalLoanWorksheet>(
      SAVE_PERSONAL_LOAN_WORKSHEET,
      allApi.personalLoan.create,
      PersonalLoanWorksheetSlice.actions.setCurrent,
      data,
    ),
  update: (data: EditPersonalLoanWorksheetDto) =>
    makeSave<EditPersonalLoanWorksheetDto, PersonalLoanWorksheet>(
      SAVE_PERSONAL_LOAN_WORKSHEET,
      allApi.personalLoan.update,
      PersonalLoanWorksheetSlice.actions.setCurrent,
      data,
    ),
  computePersonalLoanFunding: (data: FundingInfoDto) =>
    makeSave<FundingInfoDto, FundingComputedDto>(
      COMPUTE_PERSONAL_LOAN_FUNDING,
      allApi.personalLoan.computePersonalLoanFunding,
      PersonalLoanWorksheetSlice.actions.setFundingComputed,
      data,
    ),
  getWorksheetRevisions: (creditApplicationId: string) =>
    makeGetListWithIds<PersonalWorksheetRevision>(
      GET_PERSONAL_LOAN_WORKSHEET_REVISIONS,
      allApi.personalLoan.getWorksheetRevisions,
      PersonalLoanWorksheetSlice.actions.setCurrentRevisions,
      { creditApplicationId },
    ),
  updateDecision: (data: PersonalWorksheetDecisionDTO) =>
    makeSave<PersonalWorksheetDecisionDTO, PersonalLoanWorksheet>(
      SAVE_PERSONAL_WORKSHEET_DECISION,
      allApi.personalLoan.updateDecision,
      PersonalLoanWorksheetSlice.actions.setCurrent,
      data,
    ),
  getById: (creditApplicationId: string) =>
    makeGetById<PersonalLoanWorksheet>(
      GET_PERSONAL_WORKSHEET_BY_ID,
      allApi.personalLoan.getById,
      PersonalLoanWorksheetSlice.actions.setCurrent,
      {
        creditApplicationId,
      },
    ),
}

export default personalLoanWorksheetEffects
