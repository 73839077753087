import * as React from 'react'
import FormControl from '@mui/material/FormControl'
import Checkbox from '@mui/material/Checkbox'
import { FormControlLabel, FormGroup, List, Paper, Typography } from '@mui/material'
import { Control, useController } from 'react-hook-form'
import { ValueListItem } from '../data/types/ValueListItem'

type Props = {
  title: string
  content: ValueListItem[]
  defaultValues?: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>
  name: string
  disabledElementsIds?: string[]
  reset: boolean
}

const MultipleCheckboxList = ({
  title,
  content,
  defaultValues = [],
  control,
  name,
  disabledElementsIds = [],
  reset,
}: Props) => {
  const [selectedElements, setSelectedElements] = React.useState<string[]>(defaultValues ?? [])

  const {
    field: { onChange },
  } = useController({ name, control })

  React.useEffect(() => {
    if (defaultValues) {
      setSelectedElements(defaultValues)
      onChange(defaultValues)
    }
  }, [defaultValues, reset, onChange])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val: string[] = []
    const retName = event.target.name
    if (event.target.checked) {
      setSelectedElements((prevState) => {
        val = [...prevState, retName]
        return val
      })
    } else {
      setSelectedElements((prevState) => {
        val = prevState.filter((x) => x !== retName)
        return val
      })
    }

    onChange(val)
  }

  return (
    <div>
      <FormControl fullWidth sx={{ minWidth: 120 }}>
        <Typography sx={{ mb: '1rem' }}>{title}</Typography>
        <FormControl>
          <Paper>
            <List sx={{ maxHeight: '24rem', overflow: 'auto', padding: '1rem', textAlign: 'left' }}>
              <FormGroup>
                {content?.map((element) => (
                  <FormControlLabel
                    key={element.id}
                    control={
                      <Checkbox
                        onChange={handleChange}
                        name={element.id}
                        checked={selectedElements.findIndex((x: string) => x === element.id) > -1}
                        disabled={
                          disabledElementsIds
                            ? disabledElementsIds.findIndex((x: string) => x === element.id) > -1
                            : false
                        }
                      />
                    }
                    label={element.text}
                  />
                ))}
              </FormGroup>
            </List>
          </Paper>
        </FormControl>
      </FormControl>
    </div>
  )
}

export default MultipleCheckboxList
