import { AvailableCredit } from '../types'
import { makeGetSingleFromApi } from './make-api'

export const GET_AVAILABLE_CREDIT = 'Applications/{financingProgramId}/{id}/availablecredit'

const availableCreditApi = {
  getAvailableCredit: makeGetSingleFromApi<AvailableCredit>(GET_AVAILABLE_CREDIT),
}

export default availableCreditApi
