import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Cancel as CancelIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { FormatCurrency, formatDateTime } from '../../../services/Formatter'
import { PageSpinner } from '../../../components'
import { FlinksIncome } from '../../../data/types/FlinksIncome'

type Props = {
  onClose: () => void
  flinksData: FlinksIncome
  open: boolean
  isLoadingHistory: boolean
}

const FlinksHistoryDialog = ({ onClose, flinksData, open, isLoadingHistory }: Props) => {
  const { t } = useTranslation()

  return (
    <Dialog
      onClose={onClose}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
      keepMounted
      transitionDuration={0}
      disablePortal
      BackdropProps={{ transitionDuration: 0 }}
    >
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title">
        {t('incomeSection.flinksData')}
      </DialogTitle>
      <DialogContent>
        <PageSpinner isLoading={isLoadingHistory} />
        {!isLoadingHistory && (
          <TableContainer component={Paper} sx={{ mt: 2, maxHeight: 750 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center">{t('common.dateTime')}</TableCell>
                  <TableCell align="center">{t('incomeSection.accountHolderName')}</TableCell>
                  <TableCell align="center">{t('incomeSection.monthlyAverageNetEmployerIncome')}</TableCell>
                  <TableCell align="center">{t('incomeSection.monthlyAverageNetGovernmentIncome')}</TableCell>
                  <TableCell align="center">{t('incomeSection.socialAssistanceIncome90Days')}</TableCell>
                  <TableCell align="center">{t('incomeSection.annualAverageGrossIncome')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">{formatDateTime(flinksData.createdOn)}</TableCell>
                  <TableCell align="center">
                    {flinksData.accountHolderName ?? t('incomeSection.accountHolderNameMismatch')}
                  </TableCell>
                  <TableCell align="center">{FormatCurrency(flinksData.monthlyAverageNetEmployerIncome)}</TableCell>
                  <TableCell align="center">{FormatCurrency(flinksData.monthlyAverageNetGovernmentIncome)}</TableCell>
                  <TableCell align="center">{FormatCurrency(flinksData.socialAssistanceIncome90Days)}</TableCell>
                  <TableCell align="center">{FormatCurrency(flinksData.annualAverageGrossIncome)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="warning" startIcon={<CancelIcon />} onClick={onClose}>
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(FlinksHistoryDialog)
