import * as yup from 'yup'
import {
  EApplicantType,
  EApplicantTypeList,
  ECreditApplicationStep,
  ECreditApplicationStepList,
  ETasksStatus,
  ETasksStatusList,
  ETaskType,
} from './constants'

export const creditTaskCommentSchema = yup.object({
  id: yup.string().default(null).nullable(),
  taskId: yup.string().default(null).nullable(),
  creditApplicationId: yup.string().default(null).nullable(),
  financingProgramId: yup.string(),
  value: yup.string().required().default('').nullable(false),
  createdOn: yup.date(),
  createdByUserId: yup.string().default(null).nullable(),
  createdByUserFullname: yup.string(),
  updatedByUserFullname: yup.string(),
  updatedByUserId: yup.string().default(null).nullable(),
  updatedOn: yup.date(),
})

export const creditTaskSchema = yup.object({
  id: yup.string().default(null).nullable(), // le default null est nécessaire pour le createEntityAdapter
  creditApplicationId: yup.string(),
  financingProgramId: yup.string(),
  typeId: yup.mixed<ETaskType>().required('numberRequired'), // on pourrait mettre la liste complète des types ici, mais ca serait overkill puisqu'on la recoit du serveur
  status: yup.mixed<ETasksStatus>().default(ETasksStatus.ToDo).oneOf(ETasksStatusList),
  description: yup.string().nullable().default(''),
  applicantType: yup
    .mixed<EApplicantType>()
    .nullable()
    .oneOf([...EApplicantTypeList, null]),
  comments: yup.array(creditTaskCommentSchema).required().default([]),
  reminderOn: yup
    .date()
    .transform((_, val: Date | string | number | null | undefined) => {
      if (val === '' || val === null || val === undefined) return null
      return new Date(val)
    })
    .nullable()
    .default(null)
    .min(new Date(), 'common.errors.reminderDateFuture'),
  requiredBeforeStep: yup
    .mixed<ECreditApplicationStep>()
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((val) => (val === '' ? null : val))
    .default(null)
    .oneOf(ECreditApplicationStepList),
  createdByUserId: yup.string(),
  versionTag: yup.string(),
  subKey: yup.string().nullable().default(''),
  labelSuffix: yup.string().nullable().default(''),
  subKey2: yup.string().nullable().default(''),
  labelSuffix2: yup.string().nullable().default(''),
})

export type CreditApplicationTask = yup.InferType<typeof creditTaskSchema> & {
  updatedOn?: Date | null
  updatedByUserFullname?: string | null
  financingProgramId?: string | null
}
export type CreditTaskComment = yup.InferType<typeof creditTaskCommentSchema>
