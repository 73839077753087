import { FundingInfoDto } from '@src/data/types/FundingInfoDto'
import { FundingComputedDto } from '@src/data/types/FundingComputedDto'
import { makeCreate, makeGetListWithIdsFromApi, makeUpdate, makeGetSingleFromApi } from '../make-api'
import {
  EditPersonalLoanWorksheetDto,
  PersonalLoanWorksheet,
  PersonalWorksheetRevision,
  WorksheetDecision,
} from '../../types'

const personalLoanWorksheetBaseUrl = '/Applications/personal/{creditApplicationId}/PersonalLoanWorksheet'
const baseUrl = '/Applications/personal/{creditApplicationId}/Worksheet'

const personalLoanWorksheetApi = {
  getById: makeGetSingleFromApi<PersonalLoanWorksheet>(personalLoanWorksheetBaseUrl),
  create: makeCreate<EditPersonalLoanWorksheetDto, PersonalLoanWorksheet>(personalLoanWorksheetBaseUrl),
  update: makeUpdate<EditPersonalLoanWorksheetDto, PersonalLoanWorksheet>(personalLoanWorksheetBaseUrl),
  computePersonalLoanFunding: makeCreate<FundingInfoDto, FundingComputedDto>('Applications/ComputeFunding'),
  getWorksheetRevisions: makeGetListWithIdsFromApi<PersonalWorksheetRevision>(`${baseUrl}/Revisions`),
  updateDecision: makeUpdate<WorksheetDecision, PersonalLoanWorksheet>(`${baseUrl}/Decision`),
}

export default personalLoanWorksheetApi
