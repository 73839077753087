import CopyAllIcon from '@mui/icons-material/CopyAll'
import { Grid, IconButton } from '@mui/material'
import { InputTextField } from '@src/components'
import { AutoWorksheet, CreditApplication, EPlanType, Merchant } from '@src/data/types'
import CopyMessage from '@src/services/strings'
import { useTranslation } from 'react-i18next'

type Props = {
  worksheet: AutoWorksheet
  creditApplication: CreditApplication
  merchant: Merchant | null
}

const ProgramSection = ({ worksheet, creditApplication, merchant }: Props): JSX.Element | null => {
  const { t } = useTranslation()
  let plan = worksheet.brokerRequestedFinancingPlan ?? creditApplication.normsCreditDecision?.planName
  plan = plan ?? EPlanType.default

  return (
    <Grid container item spacing={2} sx={{ display: 'flex', flexDirection: 'row', mt: 3, p: 2 }}>
      <Grid item md={4} sx={{ flexDirection: 'row' }}>
        <InputTextField label={t('worksheet.program')} disabled value={plan} />
      </Grid>
      <Grid item md={4} sx={{ flexDirection: 'row' }}>
        <InputTextField
          label="Province"
          disabled
          value={merchant?.province ? t(`enum.eProvince.${merchant?.province}`) : ''}
        />
      </Grid>
      <Grid item md={4} sx={{ flexDirection: 'row' }}>
        <InputTextField
          label={t('editCreditApplication.address.postalCode')}
          value={merchant?.zipCode}
          disabled
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => merchant?.zipCode && CopyMessage(merchant?.zipCode)}>
                <CopyAllIcon />
              </IconButton>
            ),
          }}
        />
      </Grid>
    </Grid>
  )
}

export default ProgramSection
