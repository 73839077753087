import React from 'react'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { IconButton, TableCell, TableRow } from '@mui/material'

type Props = {
  fileName: string
  onDeleteDoc: () => void
}

const VehicleEvaluationFileRow = ({ fileName, onDeleteDoc }: Props) => {
  return (
    <TableRow sx={{ minWidth: '40vh' }}>
      <TableCell align="left">{fileName}</TableCell>
      <TableCell align="right">
        <IconButton aria-label="delete" component="label" onClick={onDeleteDoc}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default VehicleEvaluationFileRow
