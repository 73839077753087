import { DocumentType } from '../../types/DocumentType'
import { TaskType } from '../../types/TaskType'
import { TranslatedEnum } from '../../types/TranslatedEnum'
import { DocumentTypeDto } from './DocumentTypeDto'
import { TaskTypeDto } from './TaskTypeDto'
import { TranslatedEnumDto } from './TranslatedEnumDto'

export function transformTranslatedEnumFromApi(
  translatedValue: Record<string, Record<string, TranslatedEnumDto>>,
): Record<string, Record<string, TranslatedEnum>> {
  const ret: Record<string, Record<string, TranslatedEnum>> = {}
  const keysOfDictionnaries = Object.keys(translatedValue)
  keysOfDictionnaries.forEach((key) => {
    const currentDic = translatedValue[key]
    const dic: Record<string, TranslatedEnum> = {}
    const keysInCurrentDic = Object.keys(currentDic)
    keysInCurrentDic.forEach((currentDicKey) =>
      Object.assign(dic, {
        [currentDicKey]: {
          id: currentDic[currentDicKey].id,
          fr: currentDic[currentDicKey].nameFr,
          en: currentDic[currentDicKey].nameEn,
        },
      }),
    )
    Object.assign(ret, {
      [key]: dic,
    })
  })
  return ret
}

export function transformDocumentTypeFromApi(
  documentTypeValue: Record<string, DocumentTypeDto>,
): Record<string, DocumentType> {
  const dic: Record<string, DocumentType> = {}
  const keysInCurrentDic = Object.keys(documentTypeValue)
  keysInCurrentDic.forEach((currentDicKey) =>
    Object.assign(dic, {
      [currentDicKey]: {
        id: documentTypeValue[currentDicKey].id,
        canBeAddedManually: documentTypeValue[currentDicKey].canBeAddedManually,
        mustSpecifyApplicant: documentTypeValue[currentDicKey].mustSpecifyApplicant,
        isInternal: documentTypeValue[currentDicKey].isInternal,
        canHaveMultiple: documentTypeValue[currentDicKey].canHaveMultiple,
        restrictedToFinancingProgramIds: documentTypeValue[currentDicKey].restrictedToFinancingProgramIds,
        fr: documentTypeValue[currentDicKey].nameFr,
        en: documentTypeValue[currentDicKey].nameEn,
      },
    }),
  )
  return dic
}

export function transformTaskTypeFromApi(TaskTypeValue: Record<string, TaskTypeDto>): Record<string, TaskType> {
  const dic: Record<string, TaskType> = {}
  const keysInCurrentDic = Object.keys(TaskTypeValue)
  keysInCurrentDic.forEach((currentDicKey) =>
    Object.assign(dic, {
      [currentDicKey]: {
        id: TaskTypeValue[currentDicKey].id,
        canBeAddedManually: TaskTypeValue[currentDicKey].canBeAddedManually,
        mustSpecifyApplicant: TaskTypeValue[currentDicKey].mustSpecifyApplicant,
        statusIsManagedByTheSystem: TaskTypeValue[currentDicKey].statusIsManagedByTheSystem,
        restrictedToFinancingProgramIds: TaskTypeValue[currentDicKey].restrictedToFinancingProgramIds,
        fr: TaskTypeValue[currentDicKey].nameFr,
        en: TaskTypeValue[currentDicKey].nameEn,
      },
    }),
  )
  return dic
}
