import { Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { Control, FieldError, FieldErrorsImpl, Merge, UseFormRegister, UseFormReset } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { EditAutoRslaWorksheetDto } from '@src/data/types/AutoRslaWorksheet'
import { DatePicker, InputTextField, SelectComponent } from '../../../components'
import { CreditApplication, EPaymentFrequencyList, SelectValueListItem } from '../../../data/types'
import { formatDate } from '../../../services/Formatter'

interface Props {
  control: Control<EditAutoRslaWorksheetDto>
  currentCreditApplication: CreditApplication
  worksheet: EditAutoRslaWorksheetDto
  reset: UseFormReset<EditAutoRslaWorksheetDto>
  register: UseFormRegister<EditAutoRslaWorksheetDto>
  errors: Merge<FieldError, FieldErrorsImpl<EditAutoRslaWorksheetDto>> | undefined
  creditApplicationInterestRate: number
}

const TransactionForm = ({ control, worksheet, reset, register, errors, creditApplicationInterestRate }: Props) => {
  const { t } = useTranslation()

  React.useEffect(() => {
    const today = formatDate(new Date())
    if (worksheet) {
      reset({
        ...worksheet,
        deliveryOn: formatDate(worksheet.deliveryOn) < today ? today : worksheet.deliveryOn,
      })
    }
  }, [worksheet, reset])

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const term = [...Array(13)].map((_, i) => 6 * (i + 1) + 6)

  return (
    <>
      <Divider style={{ fontSize: 25 }}>
        <Typography variant="h4" component="span" gutterBottom>
          Transaction
        </Typography>
      </Divider>

      <Grid container item xs={12} spacing={3} sx={{ mt: 1, p: 2 }}>
        <Grid item xs={12} md={3}>
          <InputTextField
            disabled
            label={t('worksheetCommon.amountRequested')}
            error={errors?.amountRequested}
            {...register('amountRequested')}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <InputTextField
            label={t('worksheet.lienAmount')}
            {...register(`lienAmountFee`)}
            error={errors?.lienAmountFee}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <InputTextField
            label={t('worksheet.brokerAdminFee')}
            {...register(`brokerAdminFee`)}
            error={errors?.brokerAdminFee}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <InputTextField
            label={t('worksheet.roadside')}
            {...register(`roadsideAssistanceAmount`)}
            error={errors?.roadsideAssistanceAmount}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <InputTextField
            label={t('worksheet.merchantReferralFee')}
            {...register(`merchantReferralFee`)}
            error={errors?.merchantReferralFee}
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{ flexDirection: 'row' }}>
          <DatePicker
            name="deliveryOn"
            control={control}
            label={t('worksheet.deliveryOn')}
            error={errors?.deliveryOn}
            disablePast
          />
        </Grid>
      </Grid>
      <Typography variant="h6" component="div" sx={{ paddingLeft: 2 }} gutterBottom>
        {t('worksheet.funding')}
      </Typography>
      <Grid container item xs={12} spacing={2} sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Grid item xs={12} md={3}>
          <InputTextField
            label={t('worksheet.paymentAmount')}
            error={errors?.paymentAmountForFrequency}
            {...register('paymentAmountForFrequency')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            items={term.map((item) => ({
              label: `${item} ${t('worksheet.month')}`,
              value: item,
            }))}
            label={t('worksheet.term') as string}
            {...register('term')}
            error={errors?.term}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            items={EPaymentFrequencyList.map((item) => {
              const data: SelectValueListItem = { label: `worksheet.${item}`, value: item }
              return data
            })}
            label={t('worksheet.paymentFrequency') as string}
            {...register('paymentFrequency')}
            error={errors?.paymentFrequency}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField value={creditApplicationInterestRate} label={t('worksheet.interestRate')} disabled />
        </Grid>

        <Grid item xs={12} md={3} sx={{ flexDirection: 'row' }}>
          <DatePicker
            name="firstPaymentOn"
            control={control}
            label={t('worksheet.firstPaymentOn')}
            error={errors?.firstPaymentOn}
            disablePast
          />
        </Grid>
      </Grid>
    </>
  )
}

export default TransactionForm
