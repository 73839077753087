import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { AxiosInstance } from 'axios'
import configApi from '@src/data/api/config/config-api'
import type { TRootState } from '..'
import { appActions } from './app-store'

const appStoreEffects = {
  initialize: () => {
    return async (
      apiClient: AxiosInstance,
      dispatch: ThunkDispatch<TRootState, undefined, AnyAction> /* select: TypedUseSelectorHook<TRootState>, */,
    ): Promise<void> => {
      // log api is loading
      dispatch(appActions.setInitializing(true))

      // perform actual request
      await Promise.all([
        configApi
          .getValueList(apiClient, { listId: 'CreditRefusalReasons' })
          .then((msg) => dispatch(appActions.setCreditRefusalReasons(msg))),
        configApi.getValueList(apiClient, { listId: 'Tags' }).then((msg) => dispatch(appActions.setMerchantTags(msg))),
        configApi.getTerritories(apiClient).then((msg) => dispatch(appActions.setTerritories(msg))),
        configApi.getEnum(apiClient, {}).then((msg) => dispatch(appActions.setEnums(msg))),
        configApi.getDocumentTypes(apiClient, {}).then((msg) => dispatch(appActions.setDocumentTypes(msg))),
        configApi.getFinancingConfig(apiClient, {}).then((msg) => dispatch(appActions.setFinancingConfig(msg))),
        configApi.getTaskTypes(apiClient, {}).then((msg) => dispatch(appActions.setTaskTypes(msg))),
        configApi
          .getIFinanceHolidays(apiClient, { year: new Date().getFullYear().toString() })
          .then((msg) => dispatch(appActions.setHolidays(msg))),
      ])
      dispatch(appActions.setInitializing(false))
      return Promise.resolve()
    }
  },
}

export default appStoreEffects
