import React from 'react'
import { Divider, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  Merge,
  UseFormGetValues,
  UseFormRegister,
  UseFormTrigger,
} from 'react-hook-form'
import subYears from 'date-fns/subYears'
import {
  Applicant,
  CreditApplication,
  EGenderList,
  ELanguageList,
  EMaritalStatusList,
  EMask,
  ERelationToApplicantList,
} from '@src/data/types'
import { DatePicker, InputTextField, InputTextFieldWithMask, SelectComponent } from '@src/components'

type Props = {
  register: UseFormRegister<CreditApplication>
  getValues: UseFormGetValues<CreditApplication>
  trigger: UseFormTrigger<CreditApplication>
  errors: Merge<FieldError, FieldErrors<Applicant>> | undefined
  name: 'coApplicant' | 'applicant'
  formControl: Control<CreditApplication>
  editDisabled: boolean
}

const ApplicantInformationParameters = ({
  register,
  getValues,
  trigger,
  errors,
  name,
  formControl,
  editDisabled,
}: Props) => {
  const { t } = useTranslation()

  const handlePhoneOnchange = async () => {
    if (getValues('coApplicant')) {
      await Promise.all([trigger('applicant.cellPhone'), trigger('coApplicant.cellPhone')])
    }
  }

  return (
    <>
      <input type="hidden" {...register(`${name}.id`)} />
      <Divider style={{ fontSize: 25 }}>
        <Typography variant="h4" component="span" gutterBottom>
          {t('editCreditApplication.information.label')}
        </Typography>
      </Divider>

      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.firstName}
            disabled={editDisabled}
            label={t('editCreditApplication.information.firstName')}
            {...register(`${name}.firstName`)}
            maxLength={50}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.middleName}
            disabled={editDisabled}
            label={t('editCreditApplication.information.middleName')}
            {...register(`${name}.middleName`)}
            maxLength={50}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.lastName}
            disabled={editDisabled}
            label={t('editCreditApplication.information.lastName')}
            {...register(`${name}.lastName`)}
            maxLength={50}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePicker
            name={`${name}.birthDate`}
            control={formControl}
            label={t('editCreditApplication.information.birthDate')}
            error={errors?.birthDate}
            maxDate={subYears(new Date(), 20)}
            minDate={subYears(new Date(), 100)}
            disabled={editDisabled}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name={`${name}.sin`}
            control={formControl}
            render={({ field }) => (
              <InputTextFieldWithMask
                mask={EMask.sinMask}
                error={errors?.sin}
                disabled={editDisabled}
                label={t('editCreditApplication.information.sin')}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            items={EGenderList.map((item) => ({ label: `enum.eGender.${item}`, value: item }))}
            label={t('editCreditApplication.information.genderId') as string}
            {...register(`${name}.genderId`)}
            error={errors?.genderId}
            disabled={editDisabled}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            items={EMaritalStatusList.map((item) => ({
              label: `enum.eMaritalStatus.${item}`,
              value: item,
            }))}
            label={t('editCreditApplication.information.maritalStatusId') as string}
            {...register(`${name}.maritalStatusId`)}
            error={errors?.maritalStatusId}
            disabled={editDisabled}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            items={ELanguageList.map((item) => ({ label: `enum.eLanguage.${item}`, value: item }))}
            label={t('editCreditApplication.information.languageId') as string}
            {...register(`${name}.languageId`)}
            error={errors?.languageId}
            disabled={editDisabled}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name={`${name}.homePhone`}
            control={formControl}
            render={({ field }) => (
              <InputTextFieldWithMask
                mask={EMask.phoneNumberMask}
                error={errors?.homePhone}
                label={t('editCreditApplication.information.homePhone')}
                {...field}
                disabled={editDisabled}
                onChange={(e) => field.onChange(e.target.value.substring(1).replace(/-/g, ''))}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name={`${name}.cellPhone`}
            control={formControl}
            render={({ field }) => (
              <InputTextFieldWithMask
                mask={EMask.phoneNumberMask}
                error={errors?.cellPhone}
                label={t('editCreditApplication.information.cellPhone')}
                onKeyDown={handlePhoneOnchange}
                {...field}
                disabled={editDisabled}
                onChange={(e) => field.onChange(e.target.value.substring(1).replace(/-/g, ''))}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.email}
            label={t('editCreditApplication.information.email')}
            {...register(`${name}.email`)}
            inputProps={{ maxLength: 60 }}
            disabled={editDisabled}
          />
        </Grid>
        {name !== 'applicant' && (
          <Grid item xs={12} md={3}>
            <SelectComponent
              items={ERelationToApplicantList.map((item) => ({
                label: `enum.eRelationToApplicant.${item}`,
                value: item,
              }))}
              label={t('editCreditApplication.information.relationWithApplicant') as string}
              {...register(`${name}.relationWithApplicant`)}
              error={errors?.relationWithApplicant}
              disabled={editDisabled}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}
export default ApplicantInformationParameters
