import React from 'react'
import Typography from '@mui/material/Typography'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import { Chip, Grid, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PreviousApplication } from '../../../data/types/PreviousApplicationSchema'
import { useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'
import { ESourceSystem } from '../../../data/types'

type Props = { previousApplication: PreviousApplication[]; label: string }

const PreviousApplicationList = ({ previousApplication, label }: Props) => {
  const { t } = useTranslation()
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const financingProgramEnum = useAppSelector(appSelectors.getFinancingProgramEnum)

  const renderLucasLink = (x: PreviousApplication) => {
    const isAlisOrMonolith = x.matchOn === ESourceSystem.Alis || x.matchOn === ESourceSystem.Monolith

    return (
      <div>
        {isAlisOrMonolith ? (
          <p style={{ marginRight: '10px' }}>
            {financingProgramEnum[x.financingProgramId][lang]} - #{x.referenceNumber} {x.merchantName}{' '}
          </p>
        ) : (
          <Link href={x.link} target="_blank" rel="noreferrer" style={{ marginRight: '10px' }}>
            {financingProgramEnum[x.financingProgramId][lang]} - #{x.referenceNumber} {x.merchantName}{' '}
          </Link>
        )}
      </div>
    )
  }

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography sx={{ width: '100%', textAlign: 'center' }}>{label}</Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        {previousApplication.length > 0 ? (
          previousApplication.map((x) => (
            <div key={x.creditApplicationId}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {renderLucasLink(x)}
                <Chip
                  label={t(`common.otherApplication.${x.matchOn}`)}
                  variant="outlined"
                  style={{ marginRight: '5px' }}
                />
                {x.matchAsApplicant ? (
                  <Chip
                    label={t('common.otherApplication.asApplicant')}
                    variant="outlined"
                    style={{ marginRight: '5px' }}
                  />
                ) : (
                  <Chip
                    label={t('common.otherApplication.asCoApplicant')}
                    variant="outlined"
                    style={{ marginRight: '5px' }}
                  />
                )}
                <Chip
                  label={t(`enum.eCreditApplicationStatus.${x.status}`)}
                  variant="outlined"
                  style={{ marginRight: '5px' }}
                />
                {x.hasAdditionalBankruptcies && <AccountBalanceIcon fontSize="medium" color="error" />}
              </div>
            </div>
          ))
        ) : (
          <p>{t('common.otherApplication.noApplication')}</p>
        )}
      </Grid>
    </Grid>
  )
}

export default PreviousApplicationList
