import { EFinancingCompany, EFinancingProgram } from './constants'

interface FinancingCompany {
  Id: EFinancingCompany
  FinancingPrograms: EFinancingProgram[]
}

const companies: FinancingCompany[] = [
  {
    Id: EFinancingCompany.IFinance,

    FinancingPrograms: [EFinancingProgram.Personal, EFinancingProgram.B2c],
  },
  {
    Id: EFinancingCompany.Iceberg,
    FinancingPrograms: [
      EFinancingProgram.Auto,
      EFinancingProgram.IfXpress,
      EFinancingProgram.Products,
      EFinancingProgram.AutoRsla,
    ],
  },
]

export const getProgramsByCompanyId = (companyIds: EFinancingCompany[]): EFinancingProgram[] => {
  const programs: EFinancingProgram[] = []
  companyIds.forEach((companyId) => {
    const company = companies.find((x) => x.Id === companyId)
    if (company) {
      programs.push(...company.FinancingPrograms)
    }
  })
  return programs
}

export default companies
