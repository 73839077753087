import React from 'react'
import { Card, CardContent, Grid, IconButton, Stack, Typography } from '@mui/material'
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material'
import { ApplicantJob } from '../../../../../../../data/types'
import { formatAddress, FormatCurrencyNoDecimals } from '../../../../../../../services/Formatter'
import { useAppSelector } from '../../../../../../../data/store'
import { appSelectors } from '../../../../../../../data/store/AppStore'
import { TranslatedEnum } from '../../../../../../../data/types/TranslatedEnum'

type Props = {
  onDelete?: () => void
  onEdit?: () => void
  data: ApplicantJob
  editDisabled: boolean
}

const DisplayJobInfosComponent = ({ onDelete, onEdit, data, editDisabled }: Props) => {
  const { address } = data
  const jobTypeEnum = useAppSelector(appSelectors.getJobTypeEnum)
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum

  return (
    <Card sx={{ width: '100%', mb: 1 }}>
      <CardContent>
        <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
          <Grid item xs={12} sm={11}>
            <Typography variant="h6">{`${data.employerName} (${jobTypeEnum[data.jobType.toString()][lang]}) : ${
              data.jobTitle
            } ${FormatCurrencyNoDecimals(data.annualSalary)}`}</Typography>
            <Typography variant="body2">{formatAddress(address)}</Typography>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Stack direction="row">
              {onDelete && (
                <IconButton aria-label="delete" onClick={onDelete} disabled={editDisabled}>
                  <DeleteIcon />
                </IconButton>
              )}
              {onEdit && (
                <IconButton aria-label="edit" onClick={onEdit} disabled={editDisabled}>
                  <EditIcon />
                </IconButton>
              )}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default DisplayJobInfosComponent
