import React, { ReactElement } from 'react'
import { Typography } from '@mui/material'
import Stack from '@mui/material/Stack'

type Props = {
  icon: ReactElement
  messageText: string | null
  border?: number | string
  borderColor?: string | undefined
}

const OutlinedTextWithIcon = ({ icon, messageText, border = 2, borderColor = 'error.main' }: Props) => {
  return (
    <Stack
      direction="row"
      justifyContent="left"
      border={border}
      borderRadius="0.5rem"
      borderColor={borderColor}
      p={1}
      mb={1}
      gap={1}
      display="inline-flex"
    >
      {icon}
      <Typography variant="body1">{messageText}</Typography>
    </Stack>
  )
}

export default OutlinedTextWithIcon
