import React, { useRef } from 'react'
import {
  Box,
  CircularProgress,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  Link,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
  Typography,
} from '@mui/material'
import {
  PendingActions as PendingActionsIcon,
  DocumentScanner as DocumentScannerIcon,
  RemoveCircle as RemoveCircleIcon,
  HighlightOff,
  CheckCircle as CheckCircleIcon,
  FindInPage as FindInPageIcon,
  DeleteForever as DeleteForeverIcon,
  FileUpload as FileUploadIcon,
  Download as DownloadIcon,
  NotificationsActive as NotificationActiveIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Edit as EditIcon,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { Document, DocumentRequired } from '../../../data/types/DocumentSchema'
import { formatDate, formatDateTime } from '../../../services/Formatter'
import { Constants, EDocumentStatus, EFinancingProgram, UserRights } from '../../../data/types'
import { IconButtonWithTooltip } from '../../../components'
import { useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'
import { documentEffects, documentSelectors } from '../../../data/store/Document'

export enum DocumentDialog {
  DocumentsRequest = 'documentsRequestDialog',
  EditDocument = 'editDocumentRequestDialog',
  SendDocument = 'sendDocumentDialog',
  DeleteDocumentRequest = 'deleteDocumentRequest',
  None = '',
}

type RowPops = {
  documentRequired: DocumentRequired
  documents: Document[]
  setOpenDialog: (data: DocumentDialog) => void
  setEditRequiredDocument: (data: DocumentRequired) => void
  handleDelete?: (data: DocumentRequired) => void
  sendDocs: (documentList: Document[], files: File[]) => void
  editDisabled: boolean
  financingProgramId: EFinancingProgram
  userCanEditDocument: boolean
  userRights: UserRights | undefined
  handleDocumentChecked: (documentId: string) => (e: React.ChangeEvent<HTMLInputElement>) => void
  selectedDocumentIds: string[]
}

const DocumentTableRow = ({
  documentRequired,
  documents,
  setOpenDialog,
  setEditRequiredDocument,
  handleDelete,
  sendDocs,
  editDisabled,
  financingProgramId,
  userCanEditDocument,
  userRights,
  handleDocumentChecked,
  selectedDocumentIds,
}: RowPops) => {
  const { t } = useTranslation()

  const isDocumentSubmitted = () => {
    return documents.length > 0
  }
  const isSingleDocument = () => {
    return documents.length === 1
  }

  const [open, setOpen] = React.useState<boolean>(!isSingleDocument() && isDocumentSubmitted())
  const [currentDownloads, setCurrentDownloads] = React.useState<string[]>([])

  const theme = useTheme()
  const documentTypes = useAppSelector(appSelectors.getDocumentTypes)
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const dispatchEffect = useSideEffect()
  const documentStatuses = useAppSelector(documentSelectors.getRequiredDocumentStatusFromMatchingDocuments)
  const firstDocumentStatus = React.useMemo(() => {
    if (documents.length === 1) return documents[0].status
    return documentStatuses[String(documentRequired.id)]
  }, [documents, documentRequired.id, documentStatuses])

  const firstDocument = {
    ...documents[documents.length - 1],
    status: firstDocumentStatus,
    receivedOn: documents[0]?.createdOn,
  }
  const history = [...documents]
  history.sort(
    (a, b) => new Date(`${b.receivedOn?.toString()}`).getTime() - new Date(`${a.receivedOn?.toString()}`).getTime(),
  )

  let formattedDate: string | null
  if (firstDocument?.updatedOn) {
    const date = new Date(firstDocument.updatedOn)
    formattedDate = date.toISOString().split('T')[0]
  } else {
    formattedDate = null
  }

  const updatedBy =
    firstDocument.updatedByUserFullname === 'System' ? t('common.system') : firstDocument.updatedByUserFullname

  function canSendDoc() {
    return (
      firstDocument?.status !== EDocumentStatus.AwaitingScan &&
      (!firstDocument?.status ||
        firstDocument?.status === EDocumentStatus.Deleted ||
        firstDocument?.status === EDocumentStatus.Refused)
    )
  }

  function canDownloadDocuments() {
    return (
      firstDocument?.status &&
      firstDocument?.status !== EDocumentStatus.AwaitingScan &&
      firstDocument?.status !== EDocumentStatus.Deleted &&
      firstDocument?.status !== EDocumentStatus.Refused
    )
  }

  const downloadDocument = (document: Document) => {
    setCurrentDownloads((prev) => [...prev, document.id!])

    return dispatchEffect(
      documentEffects.downloadDocument(document.id ?? '', document.creditApplicationId, financingProgramId),
    ).finally(() => {
      setCurrentDownloads((prev) => prev.filter((i) => i !== document.id))
    })
  }

  const awaitingScan = EDocumentStatus.AwaitingScan
  const deleted = EDocumentStatus.Deleted
  const showLink =
    firstDocument &&
    firstDocument.status !== awaitingScan &&
    firstDocument.status !== deleted &&
    isSingleDocument() &&
    isDocumentSubmitted()

  const getDocumentStatusIcon = (document: Document) => {
    return (
      <>
        {!document?.status && (
          <Tooltip title={t('document.awaitingDocument')} arrow placement="top">
            <PendingActionsIcon sx={{ color: theme.palette.warning.main }} />
          </Tooltip>
        )}
        {document?.status === EDocumentStatus.AwaitingScan && (
          <Tooltip title={t('document.awaitingScan')} arrow placement="top">
            <DocumentScannerIcon />
          </Tooltip>
        )}
        {document?.status === EDocumentStatus.AwaitingApproval && (
          <Tooltip title={t('document.awaitingApproval')} arrow placement="top">
            <FindInPageIcon color="primary" />
          </Tooltip>
        )}
        {document?.status === EDocumentStatus.Approved && (
          <Tooltip
            title={`${t('document.approvedOn')} ${formattedDate} ${t('common.by').toLowerCase()} ${updatedBy}`}
            arrow
            placement="top"
          >
            <CheckCircleIcon color="success" />
          </Tooltip>
        )}
        {document?.status === EDocumentStatus.Deleted && (
          <Tooltip
            title={`${t('document.deletedOn')} ${formattedDate} ${t('common.by').toLowerCase()} ${updatedBy}`}
            arrow
            placement="top"
          >
            <HighlightOff color="error" />
          </Tooltip>
        )}
        {document?.status === EDocumentStatus.Refused && (
          <Tooltip
            title={`${t('document.refusedOn')} ${formattedDate} ${t('common.by').toLowerCase()} ${updatedBy}`}
            arrow
            placement="top"
          >
            <RemoveCircleIcon color="error" />
          </Tooltip>
        )}
        {document?.status === EDocumentStatus.Incomplete && (
          <Tooltip
            title={`${t('document.incompleteOn')} ${formattedDate} ${t('common.by').toLowerCase()} ${updatedBy}`}
            arrow
            placement="top"
          >
            <NotificationActiveIcon sx={{ color: theme.palette.warning.main }} />
          </Tooltip>
        )}
      </>
    )
  }

  const getDocumentSubText = (document: Document) => {
    return (
      <>
        {document.note && (
          <p style={{ marginLeft: '4.4rem', whiteSpace: 'pre-wrap', fontStyle: 'italic' }}>
            <strong>{`${t('document.uploadNote')}:`}</strong> {document.note}
          </p>
        )}
        {document?.refusalReason &&
          (document?.status === EDocumentStatus.Refused ||
            document?.status === EDocumentStatus.Deleted ||
            document?.status === EDocumentStatus.Incomplete) && (
            <p style={{ marginLeft: '4.4rem', whiteSpace: 'pre-wrap', fontStyle: 'italic' }}>
              <strong>{t('document.decision')}:</strong> {document.refusalReason}
            </p>
          )}
      </>
    )
  }

  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <>
      <TableRow key={documentRequired.id} sx={{ verticalAlign: 'top' }}>
        <TableCell align="left" sx={{ minWidth: '27em', maxWidth: '27em', borderBottom: 'none' }}>
          {showLink && (
            <Stack direction="row">
              {getDocumentStatusIcon(firstDocument)}
              <FormControlLabel
                sx={{ marginTop: '-0.5rem', marginLeft: '0.3rem' }}
                control={
                  <Checkbox
                    color={firstDocument?.id === selectedDocumentIds[0] ? 'warning' : 'primary'}
                    checked={selectedDocumentIds.includes(firstDocument.id!)}
                    onChange={handleDocumentChecked(firstDocument.id!)}
                  />
                }
                label={
                  <span>
                    <Link
                      href={`/Applications/${financingProgramId}/${
                        documentRequired.creditApplicationId
                      }/split-documents?docIds=${encodeURI(`${firstDocument?.id}`)}`}
                      underline="none"
                    >
                      <>
                        {documentTypes[documentRequired.typeId][lang]}
                        {documentRequired.labelSuffix && ` - ${documentRequired.labelSuffix}`}
                        {documentRequired.applicantType && ` - ${t(`common.${documentRequired.applicantType}`)}`}
                      </>
                    </Link>
                  </span>
                }
              />
            </Stack>
          )}

          {!showLink && (
            <Stack direction="row">
              <span style={{ marginRight: '1rem', marginTop: '-0.2rem' }}>{getDocumentStatusIcon(firstDocument)}</span>
              <Typography>
                {documentTypes[documentRequired.typeId][lang]}
                {documentRequired.labelSuffix && ` - ${documentRequired.labelSuffix}`}
                {documentRequired.applicantType && ` - ${t(`common.${documentRequired.applicantType}`)}`}
              </Typography>
            </Stack>
          )}
          {documentRequired.note && (
            <p style={{ marginLeft: '4.4rem', whiteSpace: 'pre-wrap', fontStyle: 'italic' }}>
              <strong>{`${t('document.agentComment')}:`}</strong> {documentRequired.note}
            </p>
          )}
          {getDocumentSubText(firstDocument)}
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          {documentRequired.requiredBeforeStep
            ? t(`enum.eCreditApplicationSteps.${documentRequired.requiredBeforeStep}`)
            : ''}
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          {formatDate(firstDocument?.receivedOn)}
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          {formatDate(documentRequired.createdOn)}
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Stack direction="row" marginTop="-0.5rem">
            {documentRequired.id && documentRequired.createdByUserId !== Constants.SystemUserId && (
              <IconButtonWithTooltip
                disabled={editDisabled || !userCanEditDocument}
                tooltip={t('document.edit')}
                icon={<EditIcon />}
                onClick={() => {
                  setOpenDialog(DocumentDialog.EditDocument)
                  setEditRequiredDocument(documentRequired)
                }}
              />
            )}
            {handleDelete !== undefined && (
              <IconButtonWithTooltip
                disabled={editDisabled || !userCanEditDocument}
                tooltip={t('document.deleteDocumentRequest')}
                icon={<DeleteForeverIcon />}
                onClick={() => handleDelete(documentRequired)}
              />
            )}
            {canSendDoc() && !editDisabled && (
              <IconButtonWithTooltip
                tooltip={t('common.upload')}
                icon={
                  <>
                    <input
                      type="file"
                      accept={Constants.SupportedDocumentTypes}
                      hidden
                      ref={fileInputRef}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const { files } = event.target
                        if (files) {
                          const doc = {
                            applicantType: documentRequired.applicantType,
                            typeId: Number(documentRequired.typeId),
                            note: '',
                            creditApplicationId: documentRequired.creditApplicationId,
                            financingProgramId,
                            isPrivate: false,
                            subKey: documentRequired.subKey,
                            fileName: `0-${files[0].name}`,
                          } as Document

                          sendDocs([doc], [files[0]])
                        }
                      }}
                    />
                    <FileUploadIcon />
                  </>
                }
                onClick={handleButtonClick}
              />
            )}
            {canDownloadDocuments() && userRights?.canDownloadDocuments && isSingleDocument() && (
              <IconButtonWithTooltip
                tooltip={t('common.download')}
                icon={currentDownloads.includes(firstDocument.id!) ? <CircularProgress size={25} /> : <DownloadIcon />}
                onClick={() => downloadDocument(firstDocument)}
              />
            )}
            {documents.length > 1 && (
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </Stack>
        </TableCell>
      </TableRow>
      {open && documents.length > 1 && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 2 }}>
                {history.map((historyDoc: Document) => (
                  <div key={historyDoc.id}>
                    <Stack direction="row" alignItems="center">
                      {getDocumentStatusIcon(historyDoc)}
                      <FormControlLabel
                        sx={{ marginLeft: '0.5rem' }}
                        control={
                          <Checkbox
                            disabled={historyDoc.status === deleted}
                            color={historyDoc.id === selectedDocumentIds[0] ? 'warning' : 'primary'}
                            checked={selectedDocumentIds.includes(historyDoc.id!)}
                            onChange={handleDocumentChecked(historyDoc.id!)}
                          />
                        }
                        label={
                          <span>
                            {historyDoc.status === deleted ? (
                              `${t('document.uploadedOn')} ${formatDateTime(historyDoc.updatedOn)}`
                            ) : (
                              <Link
                                href={`/Applications/${financingProgramId}/${
                                  documentRequired.creditApplicationId
                                }/split-documents?docIds=${encodeURI(`${historyDoc.id}`)}`}
                                underline="none"
                              >
                                {`${t('document.uploadedOn')} ${formatDateTime(historyDoc.updatedOn)}`}
                              </Link>
                            )}
                          </span>
                        }
                      />
                      {canDownloadDocuments() &&
                        userRights?.canDownloadDocuments &&
                        !isSingleDocument() &&
                        historyDoc.status !== EDocumentStatus.Refused && (
                          <IconButtonWithTooltip
                            tooltip={t('common.download')}
                            icon={
                              currentDownloads.includes(firstDocument.id!) ? (
                                <CircularProgress size={25} />
                              ) : (
                                <DownloadIcon />
                              )
                            }
                            onClick={() => downloadDocument(historyDoc)}
                          />
                        )}
                    </Stack>
                    {getDocumentSubText(historyDoc)}
                  </div>
                ))}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default DocumentTableRow
