import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { reportErrorToConsole } from '@src/services/error-logger'
import { AxiosInstance } from 'axios'
import { omit } from 'lodash-es'
import type { TRootState } from '..'
import api from '../../api'
import {
  EFinancingCompany,
  SELECTED_COMPANY_LOCAL_STORAGE,
  type User,
  type UserRight,
  type UserRights,
} from '../../types'
import { LoginCredentials } from '../../types/LoginCredentials'
import { apiStateActions } from '../ApiStateStore'
import { appActions } from '../AppStore/app-store'
import { LOGIN, userActions } from './user-store'

type UserDto = {
  id: string
  username: string
  name: string
  roles: string
  rights: string
  companyIds: string
  langId: string
  isInternal: string
  exp: number /* expire date in ms. to get the date, do new Date(user.exp * 1000) */
}

function parseJwt<T extends object>(token: string): T {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join(''),
  )

  return JSON.parse(jsonPayload) as T
}

function buildRights(jwtRights: UserRight[]): UserRights {
  const ret = {} as UserRights
  jwtRights.forEach((r: UserRight) => {
    ret[r] = true
  })
  return ret
}

function loadUserFromJwtString(jwtToken: string): User {
  const dto = parseJwt<UserDto>(jwtToken)
  dto.companyIds ??= ''
  const user: User = {
    ...omit(dto, ['roles', 'rights']),
    isInternal: dto.isInternal === 'true',
    roles: dto.roles.split(','),
    rights: buildRights(dto.rights.split(',') as UserRight[]),
    companyIds: dto.companyIds.split(',') as EFinancingCompany[],
  }
  return user
}

function saveUserToStorage(token: string): void {
  localStorage.setItem('jwtToken', token)
}

const userEffects = {
  loadUser: () => async (_client: AxiosInstance, dispatch: ThunkDispatch<TRootState, undefined, AnyAction>) => {
    let user: User | null = null
    const jwtToken = localStorage.getItem('jwtToken')

    if (jwtToken) {
      user = loadUserFromJwtString(jwtToken)
      if (new Date(user.exp * 1000) <= new Date() || user.companyIds.length === 0) {
        user = null
        localStorage.removeItem('jwtToken')
      } else {
        const selectedCompanyfromlocal = localStorage.getItem(SELECTED_COMPANY_LOCAL_STORAGE)
        const selectedCompany =
          selectedCompanyfromlocal === null ? user.companyIds[0] : (selectedCompanyfromlocal as EFinancingCompany)
        dispatch(userActions.setSelectedCompany(selectedCompany))
        dispatch(userActions.setUser(user))
        dispatch(appActions.setJwtToken(jwtToken))
      }
    }

    if (!user) {
      dispatch(userActions.setMustAuthenticate(true))
    }
    return Promise.resolve(user)
  },
  mockLogin:
    (data: LoginCredentials) =>
    async (client: AxiosInstance, dispatch: ThunkDispatch<TRootState, undefined, AnyAction>) => {
      try {
        // log api is loading
        dispatch(apiStateActions.logApiCallInitiated(LOGIN))
        dispatch(appActions.setBusinessErrors([]))

        // perform actual request
        const token = await api.user.mockLogin(client, data)
        saveUserToStorage(token.jwt)
        const user = loadUserFromJwtString(token.jwt)
        const selectedCompanyfromlocal = localStorage.getItem(SELECTED_COMPANY_LOCAL_STORAGE)
        const selectedCompany =
          selectedCompanyfromlocal === null ? user.companyIds[0] : (selectedCompanyfromlocal as EFinancingCompany)
        dispatch(userActions.setSelectedCompany(selectedCompany))
        dispatch(appActions.setJwtToken(token.jwt))
        dispatch(userActions.setUser(user))
        return user
      } finally {
        dispatch(apiStateActions.logApiCallCompleted(LOGIN))
      }
    },
  logout: (msaClientApp: IPublicClientApplication, account: AccountInfo | null) => {
    localStorage.clear()
    if (account) msaClientApp.logoutRedirect({ account }).catch(reportErrorToConsole)
    else window.location.reload()
  },
}

export default userEffects
