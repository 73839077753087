import React from 'react'
import { uniqueId } from 'lodash-es'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'
import { CreditReportLegalItem } from '@src/data/types'
import { FormatCurrencyNoDecimals } from '@src/services/Formatter'
import Row from './tableRow'

type LegalItemsProps = {
  legalItems: CreditReportLegalItem[]
}

const LegalItems = ({ legalItems }: LegalItemsProps) => {
  const { t } = useTranslation()
  return (
    <Row id="legalItems" name={t('creditReport.legalItems')}>
      <TableContainer>
        <Table sx={{ display: 'contents' }} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{t('creditReport.registrationDate')}</TableCell>
              <TableCell align="left">{t('creditReport.amount')}</TableCell>
              <TableCell align="left">{t('creditReport.defendant')}</TableCell>
              <TableCell align="left">{t('creditReport.plaintif')}</TableCell>
              <TableCell align="left">{t('creditReport.code')}</TableCell>
              <TableCell align="left">{t('creditReport.description')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {legalItems.map((item) => (
              <TableRow key={uniqueId()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align="left">
                  {item.filedYearMonth}
                </TableCell>
                <TableCell align="left">{FormatCurrencyNoDecimals(item.amount)}</TableCell>
                <TableCell align="left">{item.defendant}</TableCell>
                <TableCell align="left">{item.plaintiff}</TableCell>
                <TableCell align="left">{item.legalItemCode}</TableCell>
                <TableCell align="left">{item.legalItemCodeDescription}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Row>
  )
}

export default LegalItems
