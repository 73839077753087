import React from 'react'
import { Box, Typography } from '@mui/material'
import { CreditApplicationMessage } from '../../../data/types'
import { formatDateTime } from '../../../services/Formatter'

type Props = {
  message: CreditApplicationMessage
}
const Message = ({ message }: Props) => {
  const boxColor = () => {
    if (message.isPrivate) return '#f44336' // Private message ligthred
    if (message.isFromIceberg) return '#039be5' // Message from iceberg user lightblue
    return '#aed581' // Message from merchant ligthgreen
  }
  return (
    <div>
      <Box textAlign={message.isFromIceberg ? 'right' : 'left'} sx={{ mb: 1, fontSize: 10 }}>
        {message.createdByUserFullname} {formatDateTime(message.createdOn)}
      </Box>
      <Box
        sx={{
          width: 'fit-content',
          backgroundColor: boxColor,
          borderRadius: 2,
          p: 1,
          mb: 2,
        }}
      >
        <Typography maxWidth="50vw" color="white" whiteSpace="pre-wrap" sx={{ fontSize: 18 }}>
          {message.content}
        </Typography>
      </Box>
    </div>
  )
}
export default Message
