import { Vehicle, VehicleSchema } from './AutoWorksheetSchema'
import { IFinanceBaseWorksheet } from './IFinanceBaseWorksheetSchema'
import * as yup from './common/yup-extended'
import yupExtInt from './common/SchemaTypes'
import { EWorksheetStatus } from './constants'

export type AutoRslaWorksheet = IFinanceBaseWorksheet & {
  id: string
  internalType: string
  vehicle: Vehicle
  lienAmountFee: number
  brokerAdminFee: number
  roadsideAssistanceAmount: number
  merchantReferralFee: number
  paymentAmountForFrequency: number
}

const editAutoRslaWorksheetDtoSchema = yup.default.object({
  id: yup.default.string().default(''),
  vehicle: VehicleSchema,
  status: yup.default.mixed<EWorksheetStatus>().default(EWorksheetStatus.Draft),
  amountRequested: yupExtInt.double.positive(),
  deliveryOn: yup.default.string().default('').nullable(false).required('common.errors.required'),
  firstPaymentOn: yup.default
    .date()
    .default(new Date())
    .nullable()
    .required('common.errors.required')
    .typeError('common.errors.required'),
  paymentFrequency: yup.default.string().default('').nullable(false).required(),
  paymentAmountForFrequency: yupExtInt.double.min(0),
  term: yupExtInt.integer.min(6).nullable(false).required(),
  lienAmountFee: yupExtInt.double.min(0),
  brokerAdminFee: yupExtInt.double.min(0),
  roadsideAssistanceAmount: yupExtInt.double.min(0),
  merchantReferralFee: yupExtInt.double.min(0),
  creditApplicationId: yup.default.string().nullable(),
  versionTag: yup.default.string(),
})
export const EditAutoRslaWorksheetDtoSchema = yup.default.object({ ...editAutoRslaWorksheetDtoSchema.fields })
export type EditAutoRslaWorksheetDto = yup.default.InferType<typeof EditAutoRslaWorksheetDtoSchema>
