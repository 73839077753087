import { useTranslation } from 'react-i18next'
import * as yup from './common/yup-extended'
import yupExtInt from './common/SchemaTypes'
import {
  EBankStatementsRequiredFor,
  EDecision,
  EDecisionList,
  EFinancingProgram,
  EFinancingProgramList,
  EPlanType,
} from './constants'

export type CreditDecision = {
  decision: EDecision
  interestRate: number
  maxPmtAmount: number
  maxTermDuration: number | null
  maxAmountFinanced: number | null
  maximumAvalaibleAmount: number
  madeOn: string
  decidorFullName: string
  decidorId: string
  refusalReasonCodes: string[]
  infoCodes: string[]
  warningCodes: string[]
}

export type TotalDebtService = {
  dsAmnt_A: number
  dsAmnt_B: number
  dsAmnt_C: number
  dsAmnt_D: number
  dsAmnt_E: number
  dsAmnt_F: number
  totalDebtRatioForIfinance: number
  totalDebtRatioBeforeNewLoan: number
  totalDebtRatio: number
  totalDebtRatioIncludingNewLoan: number
  sumDSAmnt: number
  SumDSAmntWihtoutF: number
}

export type ApplicantCreditDecision = CreditDecision & {
  score: number
  scoreMethod: string
  isNewToCredit: boolean
  canQualifyForGold: boolean
  decisionTraceId: string
  planName: EPlanType
  reason: string
  treeId: string
  hasError: boolean
  totalDebtService: TotalDebtService
  maxAdditionalAmount: number | null
}

export type NormCreditDecision = CreditDecision & {
  ratePlanCategory: string
  planName: EPlanType
}

export type CreditDecisionOverrides = {
  interestRate: number | null
  cashdownPercent: number | null
  bankStatementsRequiredFor: EBankStatementsRequiredFor | null
  maxTermDuration: number | null
  maxPmtAmount: number | null
  existingCarLoanStatus: string | null
}

const typeExistingCarLoanStatus = yup.default
  .string()
  .nullable()
  .when('decision', {
    is: (value: EDecision | null | undefined) => value && value !== EDecision.accept,
    then: yup.default.string().nullable().notRequired(),
    otherwise: yup.default.string().when('noCoAplicant', {
      is: true,
      then: yup.default.string().nullable().notRequired(),
      otherwise: yup.default.string().nullable().notRequired(),
    }),
  })

export const FinalCreditDecisionSchema = yup.default.object({
  cashdownPercent: yupExtInt.double.PercentType().max(100).positive(),
  maxTermDuration: yupExtInt.integer,
  maxAmountFinanced: yupExtInt.double.nullable().when('decision', {
    is: (value: EDecision) => value && value === EDecision.accept,
    then: yupExtInt.double.positive().required(),
  }),
  financingProgramId: yup.default.mixed<EFinancingProgram>().oneOf(EFinancingProgramList),
  decision: yup.default.mixed<EDecision>().oneOf(EDecisionList).required(),
  refusalReasonCodes: yup.default.array(yup.default.string().required()),
  bankStatementsRequiredFor: yup.default.string().nullable(),
  noCoAplicant: yup.default.bool().default(true),
  existingCarLoanStatus: typeExistingCarLoanStatus,
  versionTag: yup.default.string(),
  maxPmtAmount: yupExtInt.double
    .required()
    .PercentType()
    .when('decision', {
      is: (value: EDecision) => value && value !== EDecision.declined,
      then: yupExtInt.double.positive().required().PercentType(),
    }), // Pas un pourcentage, mais seulement deux décimales acceptés
  interestRate: yupExtInt.double
    .required()
    .PercentType()
    .max(100)
    .when('decision', {
      is: (value: EDecision) => value && value !== EDecision.declined,
      then: yupExtInt.double.required().PercentType().max(100).positive(),
    }),
})

export type FinalCreditDecision = yup.default.InferType<typeof FinalCreditDecisionSchema>

export const WrapperFinalCreditDecisionSchema = (maxAllowedPayment: number, minAllowedPayment: number) => {
  const { t } = useTranslation()

  return FinalCreditDecisionSchema.shape({
    maxPmtAmount: yupExtInt.double
      .required()
      .PercentType()
      .when('decision', {
        is: (value: EDecision) => value && value !== EDecision.declined,
        then: (schema) =>
          schema
            .required()
            .max(maxAllowedPayment, () => t('common.errors.overNorms_other', '', { count: maxAllowedPayment }))
            .min(minAllowedPayment, () => t('common.errors.underNorms_other', '', { count: minAllowedPayment })),
      }),
    interestRate: yupExtInt.double
      .required()
      .PercentType()
      .max(100)
      .when('decision', {
        is: (value: EDecision) => value && value !== EDecision.declined,
        then: (schema) => schema.required().positive().min(6.99),
      }),
  })
}
