import React from 'react'
import { Control, Controller, FieldError, FieldErrorsImpl, Merge, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { SelectComponent, InputTextField, InputTextFieldWithMask } from '../../../../../../../components'
import { Address, EMask, EProvinceList } from '../../../../../../../data/types'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  errors?: Merge<FieldError, FieldErrorsImpl<Address>>
  name: string
  isCommercial?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formControl: Control<any>
  includeDuration?: boolean
  editDisabled: boolean
}

const AddressComponent = ({
  register,
  errors,
  name,
  isCommercial,
  formControl,
  includeDuration = true,
  editDisabled,
}: Props) => {
  const { t } = useTranslation()
  return (
    <Grid container item xs={12} spacing={2} alignContent="space-between">
      <input type="hidden" {...register(`${name}.country`)} value="Canada" />
      <Grid item xs={12} md={3}>
        <InputTextField
          error={errors?.civicNumber}
          label={t('editCreditApplication.address.addressNumber')}
          {...register(`${name}.civicNumber`)}
          inputProps={{ maxLength: 10 }}
          disabled={editDisabled}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <InputTextField
          error={errors?.street}
          label={t('editCreditApplication.address.street')}
          {...register(`${name}.street`)}
          inputProps={{ maxLength: 100 }}
          disabled={editDisabled}
        />
      </Grid>
      {!isCommercial && (
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.apartment}
            label={t('editCreditApplication.address.apartment')}
            {...register(`${name}.apartment`)}
            inputProps={{ maxLength: 50 }}
            disabled={editDisabled}
          />
        </Grid>
      )}
      {isCommercial && (
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.suite}
            label={t('editCreditApplication.address.suite')}
            {...register(`${name}.suite`)}
            inputProps={{ maxLength: 10 }}
            disabled={editDisabled}
          />
        </Grid>
      )}
      <Grid item xs={12} md={3}>
        <InputTextField
          error={errors?.city}
          label={t('editCreditApplication.address.city')}
          {...register(`${name}.city`)}
          inputProps={{ maxLength: 50 }}
          disabled={editDisabled}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Controller
          name={`${name}.postalCode`}
          control={formControl}
          render={({ field }) => (
            <InputTextFieldWithMask
              mask={EMask.postalCode}
              error={errors?.postalCode}
              label={t('editCreditApplication.address.postalCode')}
              {...field}
              disabled={editDisabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectComponent
          items={EProvinceList.sort().map((item) => ({ label: `enum.eProvince.${item}`, value: item }))}
          label={String(t('editCreditApplication.address.province'))}
          {...register(`${name}.stateIso`)}
          error={errors?.stateIso}
          disabled={editDisabled}
        />
      </Grid>
      {includeDuration && (
        <>
          <Grid item xs={12} md={3}>
            <InputTextField
              error={errors?.years}
              label={t('editCreditApplication.address.since.years')}
              {...register(`${name}.years`)}
              disabled={editDisabled}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputTextField
              error={errors?.months}
              label={t('editCreditApplication.address.since.months')}
              {...register(`${name}.months`)}
              disabled={editDisabled}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default AddressComponent
