import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Divider, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Address, CreditReport, CreditReportOtherNames, CreditReportPhone } from '@src/data/types'
import { formatAddress, formatDate } from '@src/services/Formatter'
import Row from './tableRow'

type OtherNamesProps = {
  name: CreditReportOtherNames
}

const OtherNamesComponent = ({ name }: OtherNamesProps) => {
  const { t } = useTranslation()
  return (
    <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
      <Stack>
        <Typography variant="body1" component="div">
          {t('creditReport.otherNameRaison')}: {t(`creditReport.otherNameCode.${name.code}`)}
        </Typography>
        <Typography variant="body1" component="div">
          {t('creditReport.name')}: {name.firstName} {name.middleName} {name.lastName}
          {name.suffixCode && `, ${t(`creditReport.otherNameSuffixCode.${name.suffixCode}`)}`}
        </Typography>
      </Stack>
    </Stack>
  )
}

type AddressProps = {
  address: Address
  reportedDate: string
  label: string
}

const AddressComponent = ({ address, reportedDate, label }: AddressProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography variant="subtitle2" sx={{ mt: 2 }} gutterBottom component="div">
        {label}
      </Typography>
      <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
        <Typography variant="body1" component="div">
          {formatAddress(address)}
        </Typography>

        <Typography variant="body1" gutterBottom component="div">
          {`${t('creditReport.since')} : ${reportedDate}`}
        </Typography>
      </Stack>
    </>
  )
}

type PhonesProps = {
  phones: CreditReportPhone[]
}

const Phones = ({ phones }: PhonesProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography variant="subtitle2" sx={{ mt: 2 }} gutterBottom component="div">
        {t('creditReport.phone')}
      </Typography>
      <TableContainer>
        <Table sx={{ display: 'contents' }} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">{t('creditReport.phoneNumber')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {phones.map((item) => (
              <TableRow key={item.number} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align="left">
                  {item.description}
                </TableCell>
                <TableCell align="left">{item.number}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

type IdentificationProps = {
  creditReport: CreditReport
}

const Identification = ({ creditReport }: IdentificationProps) => {
  const { t } = useTranslation()

  return (
    <Row id="identification" name="Identification">
      <Typography variant="subtitle2" gutterBottom component="div">
        {t('creditReport.name')}: {`${creditReport.firstName} ${creditReport.lastName}`}
      </Typography>
      <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
        <Stack>
          <Typography variant="body1" gutterBottom component="div">
            {t('creditReport.birthDate')}: {creditReport.dateOfBirth}
          </Typography>
          <Typography variant="body1" gutterBottom component="div">
            {t('creditReport.sin')}: {creditReport.sin}
          </Typography>

          <Typography variant="body1" gutterBottom component="div">
            {t('creditReport.equifaxReportNumber')}: {creditReport.uniqueNumber}
          </Typography>
          {creditReport.otherNames && creditReport.otherNames.length > 0 && (
            <Typography variant="subtitle2" sx={{ mt: 2 }} gutterBottom component="div">
              {t('creditReport.otherNames')}
            </Typography>
          )}
          {creditReport.otherNames.map((name) => (
            <OtherNamesComponent key={name.firstName} name={name} />
          ))}
        </Stack>

        <Stack divider={<Divider orientation="vertical" flexItem />}>
          <Typography variant="body1" gutterBottom component="div">
            {t('creditReport.creationDate')}: {creditReport.fileSinceDate}
          </Typography>
          <Typography variant="body1" gutterBottom component="div">
            {t('creditReport.lastActivityDate')}: {creditReport.dateOfLastActivity}
          </Typography>
          <Typography variant="body1" gutterBottom component="div">
            {t('creditReport.requestDate')}: {creditReport.dateOfRequest}
          </Typography>
        </Stack>
      </Stack>
      <AddressComponent
        label={t('creditReport.currentAddress')}
        address={creditReport.currentAddress}
        reportedDate={formatDate(creditReport.currentAddress.dateReported)}
      />
      <AddressComponent
        label={t('creditReport.previousAddress')}
        address={creditReport.previousAddress}
        reportedDate={formatDate(creditReport.previousAddress.dateReported)}
      />
      <AddressComponent
        label={t('creditReport.addressPriorToOldAddress')}
        address={creditReport.priorAddressOfPreviousAddress}
        reportedDate={creditReport.priorAddressOfPreviousAddress.dateReported}
      />
      <Phones phones={creditReport.phones} />
    </Row>
  )
}

export default Identification
