import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { LoginCredentials } from '../../types/LoginCredentials'

export type JwtToken = {
  jwt: string
}

const userApi = {
  mockLogin: async (apiClient: AxiosInstance, data: LoginCredentials): Promise<JwtToken> => {
    const payload: AxiosRequestConfig = {
      method: 'POST',
      baseURL: import.meta.env.VITE_BACKOFFICE_API_URL,
      url: '/MockLogin',
      data,
      withCredentials: true,
    }
    const response = await apiClient.request(payload)
    return response.data as JwtToken
  },
  loginWithAzureAd: async (apiClient: AxiosInstance, data: JwtToken): Promise<JwtToken> => {
    const payload: AxiosRequestConfig = {
      method: 'POST',
      baseURL: import.meta.env.VITE_BACKOFFICE_API_URL,
      url: '/GenerateJwtToken',
      data,
      withCredentials: true,
    }

    const response = await apiClient.request(payload)
    return response.data as JwtToken
  },
}

export default userApi
