import { omit, toInteger } from 'lodash-es'
import {
  Applicant,
  ApplicantAddress,
  ApplicantJob,
  CreditApplication,
  ApplicantExpenses,
  EHomeStatus,
  EBankStatementsRequiredFor,
} from '../../types'
import { CreditDecisionOverrides } from '../../types/CreditDecisionSchema'
import {
  ApplicantAddressDto,
  ApplicantDto,
  ApplicantJobDto,
  CreditApplicationDto,
  ApplicantExpensesDto,
} from './CreditApplicationDto'
import { normalizeNumber } from '../../../services/Formatter'
import { EditIncomesDto } from './EditIncomesDto'

function convertToNullableInt(original: number | string | null): number | null {
  if (original === 0) return 0
  if (!original) return null
  return Number(original)
}

// To API
function transformApplicantAddressToApi(address: ApplicantAddress): ApplicantAddressDto {
  const ret = omit(address, 'years') as ApplicantAddressDto
  ret.months = toInteger((address.years ?? 0) * 12) + toInteger(address.months ?? 0)
  return ret
}

function transformApplicantExpensesToApi(expenses: ApplicantExpenses): ApplicantExpensesDto {
  const ret = omit(expenses, 'financingProgramId') as ApplicantExpensesDto

  if (ret.homeFeeTypeId === EHomeStatus.OwnWithMortgage) {
    ret.mortgageMonthly = expenses.housingMonthly
    ret.rentMonthly = null
  } else {
    ret.rentMonthly = expenses.housingMonthly
    ret.mortgageMonthly = null
  }

  ret.monthlyInstallmentLoanPayment = convertToNullableInt(expenses.monthlyInstallmentLoanPayment)
  ret.monthlyStudentLoanPayment = convertToNullableInt(expenses.monthlyStudentLoanPayment)
  ret.monthlyLineOfCreditPayment = convertToNullableInt(expenses.monthlyLineOfCreditPayment)
  ret.monthlyCreditCardsPayment = convertToNullableInt(expenses.monthlyCreditCardsPayment)
  ret.monthlyPaydayLoanPayment = convertToNullableInt(expenses.monthlyPaydayLoanPayment)
  ret.monthlyPersonalLoanPayment = convertToNullableInt(expenses.monthlyPersonalLoanPayment)
  ret.monthlyOtherLoanPayment = convertToNullableInt(expenses.monthlyOtherLoanPayment)
  ret.monthlyOtherExpensePayment = convertToNullableInt(expenses.monthlyOtherExpensePayment)
  ret.rentMonthly = convertToNullableInt(ret.rentMonthly)
  ret.mortgageMonthly = convertToNullableInt(ret.mortgageMonthly)
  ret.houseMarketValue = convertToNullableInt(expenses.houseMarketValue)
  ret.monthlyOwnerInsuranceFee = convertToNullableInt(expenses.monthlyOwnerInsuranceFee)
  ret.monthlyMortgageInsuranceFee = convertToNullableInt(expenses.monthlyMortgageInsuranceFee)
  ret.monthlyMunicipalAndSchoolTaxPayment = convertToNullableInt(expenses.monthlyMunicipalAndSchoolTaxPayment)
  ret.monthlyCondominiumFee = convertToNullableInt(expenses.monthlyCondominiumFee)
  ret.monthlyPublicServiceFee = convertToNullableInt(expenses.monthlyPublicServiceFee)
  ret.monthlyRrspRefund = convertToNullableInt(expenses.monthlyRrspRefund)
  ret.monthlyOtherHomeFee = convertToNullableInt(expenses.monthlyOtherHomeFee)

  return ret
}

function transformApplicantJobToApi(job: ApplicantJob): ApplicantJobDto {
  const ret = omit(job, 'years') as ApplicantJobDto
  ret.months = (toInteger(job.years) ?? 0) * 12 + (toInteger(job.months) ?? 0)
  return ret
}

function transformApplicantToApi(applicant: Applicant): ApplicantDto {
  const ret: ApplicantDto = { ...applicant } as ApplicantDto
  if (applicant.expenses) ret.expenses = transformApplicantExpensesToApi(applicant.expenses)
  if (applicant.currentAddress) ret.currentAddress = transformApplicantAddressToApi(applicant.currentAddress)

  ret.previousAddresses = []
  if (applicant.previousAddresses) {
    ret.previousAddresses = applicant.previousAddresses.map(transformApplicantAddressToApi)
  }

  ret.currentJobs = []
  if (applicant.currentJobs) {
    ret.currentJobs = applicant.currentJobs.map(transformApplicantJobToApi)
  }

  ret.previousJobs = []
  if (applicant.previousJobs) {
    ret.previousJobs = applicant.previousJobs.map(transformApplicantJobToApi)
  }

  return ret
}

export function transformCreditApplicationToApi(data: CreditApplication): CreditApplicationDto {
  data.requestedLoanAmount = normalizeNumber(data.requestedLoanAmount?.toString())
  const ret = { ...data } as CreditApplicationDto
  ret.applicant = transformApplicantToApi(data.applicant)
  if (data.coApplicant) ret.coApplicant = transformApplicantToApi(data.coApplicant)

  return ret
}

// From API
function transformApplicantAddressFromApi(address: ApplicantAddressDto): ApplicantAddress {
  const ret = { ...address } as ApplicantAddress
  ret.months = address.months % 12
  ret.years = (address.months - ret.months) / 12
  return ret
}

function transformExpenseFromApi(expense: ApplicantExpenses) {
  if (expense.homeFeeTypeId === EHomeStatus.OwnWithMortgage) {
    expense.housingMonthly = expense.mortgageMonthly
  } else {
    expense.housingMonthly = expense.rentMonthly
  }

  expense.monthlyInstallmentLoanPayment = convertToNullableInt(expense.monthlyInstallmentLoanPayment)
  expense.monthlyStudentLoanPayment = convertToNullableInt(expense.monthlyStudentLoanPayment)
  expense.monthlyLineOfCreditPayment = convertToNullableInt(expense.monthlyLineOfCreditPayment)
  expense.monthlyCreditCardsPayment = convertToNullableInt(expense.monthlyCreditCardsPayment)
  expense.monthlyPaydayLoanPayment = convertToNullableInt(expense.monthlyPaydayLoanPayment)
  expense.monthlyPersonalLoanPayment = convertToNullableInt(expense.monthlyPersonalLoanPayment)
  expense.monthlyOtherLoanPayment = convertToNullableInt(expense.monthlyOtherLoanPayment)
  expense.monthlyOtherExpensePayment = convertToNullableInt(expense.monthlyOtherExpensePayment)
  expense.housingMonthly = convertToNullableInt(expense.housingMonthly)
  expense.mortgageMonthly = convertToNullableInt(expense.mortgageMonthly)
  expense.houseMarketValue = convertToNullableInt(expense.houseMarketValue)
  expense.monthlyOwnerInsuranceFee = convertToNullableInt(expense.monthlyOwnerInsuranceFee)
  expense.monthlyMortgageInsuranceFee = convertToNullableInt(expense.monthlyMortgageInsuranceFee)
  expense.monthlyMunicipalAndSchoolTaxPayment = convertToNullableInt(expense.monthlyMunicipalAndSchoolTaxPayment)
  expense.monthlyCondominiumFee = convertToNullableInt(expense.monthlyCondominiumFee)
  expense.monthlyPublicServiceFee = convertToNullableInt(expense.monthlyPublicServiceFee)
  expense.monthlyRrspRefund = convertToNullableInt(expense.monthlyRrspRefund)
  expense.monthlyOtherHomeFee = convertToNullableInt(expense.monthlyOtherHomeFee)

  return expense
}

function transformApplicantJobFromApi(job: ApplicantJob): ApplicantJob {
  const ret = { ...job }
  if (job.months !== null) {
    ret.months = job.months % 12
    ret.years = (job.months - ret.months) / 12
  } else {
    ret.months = 0
    ret.years = 0
  }
  return ret
}

function transformApplicantFromApi(applicant: Applicant): Applicant {
  const ret = { ...applicant }
  if (applicant.currentAddress)
    ret.currentAddress = transformApplicantAddressFromApi(applicant.currentAddress as ApplicantAddressDto)

  if (applicant.previousAddresses) {
    ret.previousAddresses = applicant.previousAddresses.map((add) =>
      transformApplicantAddressFromApi(add as ApplicantAddressDto),
    )
  }

  if (applicant.currentJobs) {
    ret.currentJobs = applicant.currentJobs.map(transformApplicantJobFromApi)
  }

  if (applicant.previousJobs) {
    ret.previousJobs = applicant.previousJobs.map(transformApplicantJobFromApi)
  }

  applicant.expenses = transformExpenseFromApi(applicant.expenses)

  return ret
}

function transformDecisionOverrideFromApi(override: CreditDecisionOverrides): CreditDecisionOverrides {
  const ret: CreditDecisionOverrides = { ...override }
  if (override.bankStatementsRequiredFor === null) ret.bankStatementsRequiredFor = EBankStatementsRequiredFor.Blank

  return ret
}

export function transformCreditApplicationFromApi(application: CreditApplication): CreditApplication {
  const ret = { ...application }
  ret.applicant = transformApplicantFromApi(application.applicant)
  if (application.coApplicant) ret.coApplicant = transformApplicantFromApi(application.coApplicant)
  if (application.decisionOverrides)
    ret.decisionOverrides = transformDecisionOverrideFromApi(application.decisionOverrides)
  return ret
}

function transformIncomesToApi(job: ApplicantJob): ApplicantJob {
  const ret: ApplicantJob = { ...job }
  ret.months = (job.years ?? 0) * 12 + (job.months ?? 0)
  return ret
}

export function transformEditIncomesDtoToApi(source: EditIncomesDto): EditIncomesDto {
  const ret = { ...source }
  ret.incomes = []
  if (source.incomes) {
    ret.incomes = source.incomes.map(transformIncomesToApi)
  }

  return ret
}
