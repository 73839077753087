import { IncomesHistory, CreditDecisionHistory } from '../../types/IncomesHistory'
import { makeGetListWithIdsFromApi, makeGetSingleFromApi } from '../make-api'

const BASE_URL = '/Applications/{financingProgramId}/{creditApplicationId}/Revisions'
const creditApplicationHistoryApi = {
  getIncomesHistory: makeGetSingleFromApi<IncomesHistory>(BASE_URL.concat('/IncomesRevision')),
  getCreditDecisionHistory: makeGetListWithIdsFromApi<CreditDecisionHistory>(BASE_URL.concat('/DecisionsRevision')),
}

export default creditApplicationHistoryApi
