import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import { useAppSelector } from '../../data/store'
import reportEffect from '../../data/store/report/report-effects'
import { reportSelectors } from '../../data/store/report/report-store'
import { ReportPayoutThatSkippedIncomeValidationDatas } from '../../data/types/ReportPayoutWithoutFlinkData'

const PayoutThatSkippedIncomeValidation = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatchEffect = useSideEffect()
  const payoutThatSkippedIncomeValidationDatas = useAppSelector(
    reportSelectors.getPayoutThatSkippedIncomeValidationDatas,
  )
  const isLoading = useAppSelector(reportSelectors.isLoadingPayoutThatSkippedIncomeValidationDatas)

  React.useEffect(() => {
    dispatchEffect(reportEffect.getPayoutThatSkippedIncomeValidationDatas()).catch(reportErrorToConsole)
  }, [dispatchEffect])

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ borderBottom: 'none' }}>
              <Typography variant="h6" gutterBottom component="div">
                {t('report.payoutWithoutFlinks.title')}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">{t('report.payoutWithoutFlinks.merchantName')}</TableCell>
            <TableCell align="center">{t('report.payoutWithoutFlinks.deliveryOn')}</TableCell>
            <TableCell align="center">{t('report.payoutWithoutFlinks.loanId')}</TableCell>
            <TableCell align="center">{t('report.payoutWithoutFlinks.clientName')}</TableCell>
            <TableCell align="center">{t('report.payoutWithoutFlinks.worksheetAmount')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        {!isLoading && (
          <TableBody>
            {payoutThatSkippedIncomeValidationDatas.map((data: ReportPayoutThatSkippedIncomeValidationDatas) => (
              <TableRow key={data.loanId}>
                <TableCell align="center">{data.merchantName}</TableCell>
                <TableCell align="center">{data.deliveryOn}</TableCell>
                <TableCell align="center">{data.loanId}</TableCell>
                <TableCell align="center">{data.clientName}</TableCell>
                <TableCell align="center">{data.worksheetAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}

export default PayoutThatSkippedIncomeValidation
