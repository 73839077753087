import { AxiosInstance } from 'axios'
import allApi from '../../api'

const reportActEffect = {
  getReportAct: () => {
    return async (apiClient: AxiosInstance): Promise<void> => {
      const ret = await allApi.reportAct.getReportAct(apiClient)
      return ret
    }
  },
}

export default reportActEffect
