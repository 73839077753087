import { StandardTextFieldProps } from '@mui/material'
import React from 'react'
import { FieldError } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import InputTextField from './InputTextField'

interface CustomProps {
  name: string
  type: string
  onChange: (event: { target: { name: string; value: string } }) => void
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref) {
  const { type, name, onChange, ...other } = props

  return (
    <IMaskInput
      {...other}
      mask={type}
      inputRef={ref as never}
      onAccept={(value) => onChange({ target: { name, value } })}
      overwrite
    />
  )
})

interface Props extends Omit<StandardTextFieldProps, 'error'> {
  error?: FieldError
  mask: string
}

const InputTextFieldWithMask = React.forwardRef<Props, Props>(function InputTextFieldWithMask(
  { error, mask, ...othersProps },
  ref,
) {
  return (
    <InputTextField
      error={error}
      {...othersProps}
      ref={ref as never}
      InputProps={{
        type: mask,
        inputComponent: TextMaskCustom as never,
      }}
    />
  )
})

export default InputTextFieldWithMask
