import { CreditApplicationTask } from '../../types'
import { createEntityApi, makeGetListWithIdsFromApi } from '../make-api'
import { transformCreditTaskFromApi, transformCreditTaskToApi } from './credit-task-transform'

const BASE_URL = '/Applications/{financingProgramId}/{creditApplicationId}/tasks'
const creditTaskApi = {
  ...createEntityApi<CreditApplicationTask>(BASE_URL, transformCreditTaskFromApi, transformCreditTaskToApi),
  getTaskByCreditApplicationId: makeGetListWithIdsFromApi<CreditApplicationTask>(BASE_URL, transformCreditTaskFromApi),
}

export default creditTaskApi
