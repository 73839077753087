import * as yup from './common/yup-extended'
import yupExtInt from './common/SchemaTypes'
import {
  IFinanceBaseWorksheet,
  IFinanceBaseWorksheetDecisionDTOSchema,
  IFinanceBaseWorksheetRevision,
  IFinanceBaseWorksheetSchema,
} from './IFinanceBaseWorksheetSchema'
import { EWorksheetStatus, EWorksheetStatusList } from './constants'

const b2cWorksheetSchema = IFinanceBaseWorksheetSchema

export const B2cWorksheetSchema = yup.default.object({ ...b2cWorksheetSchema.fields })

export type B2cWorksheet = IFinanceBaseWorksheet

export type B2cWorksheetRevision = IFinanceBaseWorksheetRevision

const b2cWorksheetDecisionDTOSchema = IFinanceBaseWorksheetDecisionDTOSchema
export type B2cWorksheetDecisionDTO = yup.default.InferType<typeof b2cWorksheetDecisionDTOSchema>

const editB2cWorksheetDtoSchema = yup.default.object({
  status: yup.default.mixed<EWorksheetStatus>().required().default(EWorksheetStatus.Draft).oneOf(EWorksheetStatusList),
  amountRequested: yupExtInt.double.positive(),
  deliveryOn: yup.default.string().default('').nullable(false).required('common.errors.required'),
  firstPaymentOn: yup.default
    .date()
    .default(new Date())
    .nullable()
    .required('common.errors.required')
    .typeError('common.errors.required'),
  includeInsurance: yup.default.boolean().default(false),
  paymentFrequency: yup.default.string().default('').nullable(false).required(),
  term: yupExtInt.integer.min(6).nullable(false).required(),
  fundConfirmationNote: yup.default.string().default('').nullable(),
  paymentPlanId: yup.default.string().required(),
  creditApplicationId: yup.default.string().nullable(),
  versionTag: yup.default.string(),
})
export const EditB2cWorksheetDtoSchema = yup.default.object({ ...editB2cWorksheetDtoSchema.fields })
export type EditB2cWorksheetDto = yup.default.InferType<typeof EditB2cWorksheetDtoSchema>
