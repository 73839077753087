import { AvailableCredit, EFinancingProgram } from '@src/data/types'
import { availableCreditSlice, GET_AVAILABLE_CREDIT } from './available-credit-store'
import allApi from '../../api'
import { makeGetById } from '../effects/standard-side-effects'

const availableCreditEffects = {
  getAvailableCredit: (id: string, financingProgramId: EFinancingProgram) =>
    makeGetById<AvailableCredit>(
      GET_AVAILABLE_CREDIT,
      allApi.availableCredit.getAvailableCredit,
      availableCreditSlice.actions.setAvailableCredit,
      { id, financingProgramId },
    ),
}

export default availableCreditEffects
