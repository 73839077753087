import { AnyAction, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit'
import { AxiosInstance } from 'axios'
import type { TRootState } from '../index'

import {
  DeleteToApi,
  DeleteWithReturnToApi,
  DownloadFromApi,
  DownloadWithObjectFromApi,
  GetListFromApi,
  GetListWithIdsFromApi,
  GetSingleFromApi,
  GetStringListFromApi,
  GetWithFiltersFromApi,
  SaveToApi,
} from '../../api/make-api'
import { apiStateActions } from '../ApiStateStore'
import { appActions } from '../AppStore'
import { AsyncEffectHandler } from './side-effects-types'

export interface TSetOne<T> {
  (entity: T): PayloadAction<T>
}

interface TSetList<T> {
  (entities: T[]): PayloadAction<T[]>
}

export const makeGetById = <T extends object>(
  actionName: string,
  getFromApi: GetSingleFromApi<T>,
  setOne: TSetOne<T | null>,
  ids: Record<string, string | number>,
): AsyncEffectHandler<T> => {
  return async (
    apiClient: AxiosInstance,
    dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
    /* select: TypedUseSelectorHook<TRootState>, */
  ): Promise<T> => {
    try {
      // log api is loading
      dispatch(apiStateActions.logApiCallInitiated(actionName))
      // perform actual request
      const ret = await getFromApi(apiClient, ids)
      dispatch(setOne(ret))
      return ret
    } finally {
      dispatch(apiStateActions.logApiCallCompleted(actionName))
    }
  }
}

export const makeGetWithFilters = <T extends object>(
  actionName: string,
  getWithFiltersFromApi: GetWithFiltersFromApi<T>,
  setOne: TSetOne<T>,
  filters?: Record<string, string | number | string[] | number[]>,
): AsyncEffectHandler<T> => {
  return async (apiClient: AxiosInstance, dispatch: ThunkDispatch<TRootState, undefined, AnyAction>): Promise<T> => {
    try {
      dispatch(apiStateActions.logApiCallInitiated(actionName))

      const ret = await getWithFiltersFromApi(apiClient, filters)
      dispatch(setOne(ret))
      return ret
    } finally {
      dispatch(apiStateActions.logApiCallCompleted(actionName))
    }
  }
}

export const makeGetList = <T extends object>(
  actionName: string,
  getListFromApi: GetListFromApi<T>,
  setMany: TSetList<T>,
  filters?: Record<string, string | number | string[] | number[]>,
): AsyncEffectHandler<T[]> => {
  return async (
    apiClient: AxiosInstance,
    dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
    /* select: TypedUseSelectorHook<TRootState>, */
  ): Promise<T[]> => {
    try {
      // log api is loading
      dispatch(apiStateActions.logApiCallInitiated(actionName))
      // perform actual request
      const ret = await getListFromApi(apiClient, filters)
      dispatch(setMany(ret))
      return ret
    } finally {
      dispatch(apiStateActions.logApiCallCompleted(actionName))
    }
  }
}

export const makeGetStringList = (
  actionName: string,
  getListFromApi: GetStringListFromApi,
  setMany: TSetList<string>,
): AsyncEffectHandler<string[]> => {
  return async (
    apiClient: AxiosInstance,
    dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
    /* select: TypedUseSelectorHook<TRootState>, */
  ): Promise<string[]> => {
    try {
      // log api is loading
      dispatch(apiStateActions.logApiCallInitiated(actionName))
      // perform actual request
      const ret = await getListFromApi(apiClient)
      dispatch(setMany(ret))
      return ret
    } finally {
      dispatch(apiStateActions.logApiCallCompleted(actionName))
    }
  }
}

export const makeGetListWithIds = <T extends object>(
  actionName: string,
  getListWithIdsFromApi: GetListWithIdsFromApi<T>,
  setMany: TSetList<T>,
  ids: Record<string, string>,
  filters?: Record<string, string | number | string[] | number[]>,
): AsyncEffectHandler<T[]> => {
  return async (
    apiClient: AxiosInstance,
    dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
    /* select: TypedUseSelectorHook<TRootState>, */
  ): Promise<T[]> => {
    try {
      // log api is loading
      dispatch(apiStateActions.logApiCallInitiated(actionName))

      // perform actual request
      const ret = await getListWithIdsFromApi(apiClient, ids, filters)
      dispatch(setMany(ret))
      return ret
    } finally {
      dispatch(apiStateActions.logApiCallCompleted(actionName))
    }
  }
}

export const makeSave = <TInput extends object, TOutput extends object = TInput>(
  actionName: string,
  saveToApi: SaveToApi<TInput, TOutput>,
  setOne: TSetOne<TOutput | null> | null,
  data: Partial<TInput>,
): AsyncEffectHandler<TOutput> => {
  return async (
    apiClient: AxiosInstance,
    dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
    /* select: TypedUseSelectorHook<TRootState>, */
  ): Promise<TOutput> => {
    try {
      // log api is loading
      dispatch(apiStateActions.logApiCallInitiated(actionName))
      dispatch(appActions.setBusinessErrors([]))

      // perform actual request
      const ret = await saveToApi(apiClient, data)
      if (setOne) dispatch(setOne(ret))
      return ret
    } finally {
      dispatch(apiStateActions.logApiCallCompleted(actionName))
    }
  }
}

export const makeDelete = <TInput extends object>(
  actionName: string,
  deleteToApi: DeleteToApi<TInput>,
  removeOne: TSetOne<TInput>,
  data: TInput,
): AsyncEffectHandler<void> => {
  return async (
    apiClient: AxiosInstance,
    dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
    /* select: TypedUseSelectorHook<TRootState>, */
  ): Promise<void> => {
    try {
      // log api is loading
      dispatch(apiStateActions.logApiCallInitiated(actionName))
      dispatch(appActions.setBusinessErrors([]))

      // perform actual request
      await deleteToApi(apiClient, data)
      dispatch(removeOne(data))
    } finally {
      dispatch(apiStateActions.logApiCallCompleted(actionName))
    }
  }
}

export const makeDeleteWithReturn = <TInput extends object, TOutput extends object = TInput>(
  actionName: string,
  deleteToApi: DeleteWithReturnToApi<TInput, TOutput>,
  removeOne: TSetOne<TOutput | null> | null,
  data: TInput,
): AsyncEffectHandler<void> => {
  return async (
    apiClient: AxiosInstance,
    dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
    /* select: TypedUseSelectorHook<TRootState>, */
  ): Promise<void> => {
    try {
      // log api is loading
      dispatch(apiStateActions.logApiCallInitiated(actionName))
      dispatch(appActions.setBusinessErrors([]))

      // perform actual request
      const ret = await deleteToApi(apiClient, data)
      if (removeOne) dispatch(removeOne(ret))
    } finally {
      dispatch(apiStateActions.logApiCallCompleted(actionName))
    }
  }
}

export const makeDownload = (
  actionName: string,
  download: DownloadFromApi,
  fileName: string,
): AsyncEffectHandler<void> => {
  return async (
    _apiClient: AxiosInstance,
    dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
    /* select: TypedUseSelectorHook<TRootState>, */
  ): Promise<void> => {
    try {
      // log api is loading
      dispatch(apiStateActions.logApiCallInitiated(actionName))
      dispatch(appActions.setBusinessErrors([]))

      // perform actual request
      await download(fileName)
    } finally {
      dispatch(apiStateActions.logApiCallCompleted(actionName))
    }
  }
}

export const makeDownloadWithObject = <TInput extends object>(
  actionName: string,
  downloadWithObject: DownloadWithObjectFromApi<TInput>,
  data: TInput,
): AsyncEffectHandler<void> => {
  return async (
    apiClient: AxiosInstance,
    dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
    /* select: TypedUseSelectorHook<TRootState>, */
  ): Promise<void> => {
    try {
      // log api is loading
      dispatch(apiStateActions.logApiCallInitiated(actionName))
      dispatch(appActions.setBusinessErrors([]))

      // perform actual request
      await downloadWithObject(apiClient, data)
    } finally {
      dispatch(apiStateActions.logApiCallCompleted(actionName))
    }
  }
}
