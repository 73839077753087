export function getStringArrayFromQueryString(
  urlParams: URLSearchParams,
  paramName: string,
  defaultValue: string[] = [],
): string[] {
  const value = decodeURIComponent(urlParams.get(paramName) ?? '')
  return !value ? defaultValue : value.split(',')
}

export function getNumberArrayFromQueryString(
  urlParams: URLSearchParams,
  paramName: string,
  defaultValue: number[] = [],
): number[] {
  const value = decodeURIComponent(urlParams.get(paramName) ?? '')
  return !value ? defaultValue : value.split(',').map((x) => +x)
}

export function getNumberFromQueryString(urlParams: URLSearchParams, paramName: string, defaultValue: number): number {
  const value = decodeURIComponent(urlParams.get(paramName) ?? '')
  return !value ? defaultValue : +value
}

export function getStringFromQueryString<T extends string = string>(
  urlParams: URLSearchParams,
  paramName: string,
  defaultValue = '',
): T {
  return decodeURIComponent(urlParams.get(paramName) ?? defaultValue) as T
}
