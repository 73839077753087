import {
  CreditApplication,
  EFinancingRefusalApproveMessage,
  ETasksStatus,
  EWorksheetDecision,
  WorksheetDecision,
} from '@src/data/types'
import { IFinanceBaseWorksheet } from '@src/data/types/IFinanceBaseWorksheetSchema'
import { isBefore, parseISO, startOfDay } from 'date-fns'
import { useCallback, useMemo } from 'react'

export const useCantApproveReasonCodes = (
  worksheetNormRefusalMessages: string[],
  areAllTasksRequiredForCVTCompleted: boolean,
  areAllDocumentsRequiredForCVTApproved: boolean,
  confirmAllIncomesStatus: ETasksStatus,
  creditApplication: CreditApplication | null,
  worksheet: IFinanceBaseWorksheet | null,
) => {
  const computeCantApproveReasonCodes = useCallback(() => {
    const message: EFinancingRefusalApproveMessage[] = []
    if (worksheetNormRefusalMessages.length > 0) message.push(EFinancingRefusalApproveMessage.NormRefusalMessage)
    if (!areAllTasksRequiredForCVTCompleted) message.push(EFinancingRefusalApproveMessage.TaskIncomplete)
    if (!areAllDocumentsRequiredForCVTApproved) message.push(EFinancingRefusalApproveMessage.MissingRequiredDocument)
    if (confirmAllIncomesStatus !== ETasksStatus.Completed)
      message.push(EFinancingRefusalApproveMessage.NotAllIcomeConfirmed)
    if (
      !creditApplication?.applicant.existingCustomerReference ||
      (creditApplication?.coApplicant && !creditApplication?.coApplicant.existingCustomerReference)
    )
      message.push(EFinancingRefusalApproveMessage.MissingExistingCustomerReference)
    if (worksheet?.deliveryOn) {
      const localDeliveryDate = parseISO(worksheet.deliveryOn)
      if (isBefore(startOfDay(localDeliveryDate), startOfDay(new Date()))) {
        message.push(EFinancingRefusalApproveMessage.DeliveryDateInPast)
      }
    }

    return message
  }, [
    worksheetNormRefusalMessages.length,
    areAllTasksRequiredForCVTCompleted,
    areAllDocumentsRequiredForCVTApproved,
    confirmAllIncomesStatus,
    creditApplication,
    worksheet,
  ])

  return computeCantApproveReasonCodes
}

export const useDialogDecisionDefaultValue = (
  creditApplication: CreditApplication,
  worksheet: IFinanceBaseWorksheet,
  areAllTasksRequiredForCVTCompleted: boolean,
  areAllDocumentsRequiredForCVTApproved: boolean,
  confirmAllIncomesStatus: ETasksStatus,
) => {
  const computeCantApproveReasonCodes = useCantApproveReasonCodes(
    worksheet?.finalDecision?.refusalMessages?.map((x) => x.id) || [],
    areAllTasksRequiredForCVTCompleted,
    areAllDocumentsRequiredForCVTApproved,
    confirmAllIncomesStatus,
    creditApplication,
    worksheet,
  )

  const dialogDecisionDefaultValue = useMemo(() => {
    const ret: WorksheetDecision = {
      decision: worksheet?.finalDecision?.decision ?? '',
      otherReason: worksheet?.finalDecision?.otherReason ?? '',
      refusalReasonCodes: worksheet?.finalDecision?.refusalMessages?.map((x) => x.id) ?? [],
      cantApproveReasonCodes: computeCantApproveReasonCodes(),
      maxPmtAmount: worksheet?.fundingThresholdExtend?.maxPmtAmount ?? 0,
      maxTermDuration: worksheet?.fundingThresholdExtend?.maxTermDuration ?? 0,
      maxTotalAmountFinanced: worksheet?.fundingThresholdExtend?.maxTotalAmountFinanced ?? 0,
      id: undefined,
      decidorFullName: null,
      decidorId: null,
      refusalMessages: [],
      warningMessages: [],
    }

    if (worksheet?.fundingThresholdExtend) {
      ret.decision = EWorksheetDecision.ApprovedWithCondition
    }

    return ret
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    creditApplication,
    worksheet,
    computeCantApproveReasonCodes,
    areAllTasksRequiredForCVTCompleted,
    areAllDocumentsRequiredForCVTApproved,
    confirmAllIncomesStatus,
  ])

  return dialogDecisionDefaultValue
}
