import React from 'react'
import { useTranslation } from 'react-i18next'
import { Paper } from '@mui/material'
import { FieldError, FieldErrorsImpl, Merge, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { Breadcrumb, PageError } from '../../components'
import { creditSelectors } from '../../data/store/CreditApplication'
import {
  AutoWorksheet,
  AutoWorksheetSchema,
  EExtendedServiceDuration,
  EFinancingProgram,
  EGapInsuranceDuration,
  EPaymentFrequency,
  EWorksheetStatus,
  Vehicle,
} from '../../data/types'
import TransactionForm from './components/TransactionForm'
import { formatDate } from '../../services/Formatter'
import VehicleForm from './components/VehicleForm'
import ProgramSectionEdit from './components/programSection'
import { reportErrorToConsole } from '../../services/error-logger'
import { worksheetEffects } from '../../data/store/AutoWorksheet'
import { useAppSelector } from '../../data/store'
import { autoWorksheetSelectors } from '../../data/store/AutoWorksheet/auto-worksheet-store'
import { appSelectors } from '../../data/store/AppStore'
import { vehicleSelectors } from '../../data/store/Vehicle'

const EditWorksheetPage = () => {
  const { t } = useTranslation()

  const creditApplication = useAppSelector(creditSelectors.getCurrent)
  const merchant = useAppSelector(creditSelectors.getMerchant)

  const worksheet = useAppSelector(autoWorksheetSelectors.getCurrent)
  const error = useAppSelector(appSelectors.getBusinessError)
  const dispatchEffect = useSideEffect()
  const navigate = useNavigate()
  const vehicleMaxTerm = useAppSelector(vehicleSelectors.getMaxTerm)

  const breadCrumbs = [
    { path: '/', label: t('breadcrumbs.home') },
    { path: '/Applications/browse', label: t('breadcrumbs.creditApplication') },
    {
      path: `/Applications/${EFinancingProgram.Auto}/${creditApplication?.id}/view`,
      label: (t('breadcrumbs.application') as string).concat(` #${creditApplication?.referenceNumber}`),
    },
    { path: '#', label: t('worksheet.financing') },
  ]

  const editDisabled = creditApplication?.editLocked === true
  const today = formatDate(new Date())

  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    setError,
    formState: { errors },
    reset,
  } = useForm<AutoWorksheet>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: {
      ...worksheet,
      deliveryOn: formatDate(worksheet?.deliveryOn) < today ? today.toString() : worksheet?.deliveryOn,
    },
    resolver: yupResolver(AutoWorksheetSchema),
  })

  const addFrequencyToDate = (date: string, frequency: number) => {
    return new Date(new Date(date).setDate(new Date(date).getUTCDate() + frequency))
  }

  const verifyFirstPaymentDate = (data: AutoWorksheet) => {
    const frequencyMonthlyMaxDate = addFrequencyToDate(data.deliveryOn, 30)
    const frequencyBiWeeklyMaxDate = addFrequencyToDate(data.deliveryOn, 14)
    const firstPaymentOnDate = data.firstPaymentOn
    const deliveryDate = addFrequencyToDate(data.deliveryOn, 0)

    if (firstPaymentOnDate <= deliveryDate) {
      setError('firstPaymentOn', { message: t('worksheet.norms.firstPaymentDateMustBeHigherThanDeliveryDate') })
      return false
    }

    if (data.paymentFrequency === EPaymentFrequency.BiWeekly) {
      if (firstPaymentOnDate > frequencyBiWeeklyMaxDate) {
        setError('firstPaymentOn', {
          message: t('worksheet.norms.firstPaymentDateIsHigherThanMaxFirstPaymentOn', '', {
            val: frequencyBiWeeklyMaxDate.toLocaleDateString(),
          }),
        })
        return false
      }
    } else if (firstPaymentOnDate > frequencyMonthlyMaxDate) {
      setError('firstPaymentOn', {
        message: t('worksheet.norms.firstPaymentDateIsHigherThanMaxFirstPaymentOn', '', {
          val: frequencyMonthlyMaxDate.toLocaleDateString(),
        }),
      })
      return false
    }

    return true
  }
  const verifyTerm = (data: AutoWorksheet) => {
    const maxTerm = creditApplication?.finalCreditDecision.maxTermDuration
    if (maxTerm && data.term > maxTerm) {
      setError('term', { message: t('worksheet.paramNames.maxTermDuration', '', { val: maxTerm.toString() }) })
      return false
    }

    return true
  }

  const verifyGapDuration = (data: AutoWorksheet) => {
    if (data.gapInsurance && data.gapInsuranceDuration === EGapInsuranceDuration.unknown) {
      setError('gapInsuranceDuration', {
        message: t('worksheet.gapInsuranceDurationIsRequired'),
      })
      return false
    }
    if (
      !data.gapInsurance &&
      data.gapInsuranceDuration !== EGapInsuranceDuration.unknown &&
      data.gapInsuranceDuration !== null
    ) {
      setError('gapInsurance', {
        message: t('worksheet.gapInsuranceIsRequired'),
      })
      return false
    }
    if (
      data.gapInsuranceDuration !== EGapInsuranceDuration.unknown &&
      data.gapInsuranceDuration !== null &&
      (!data.gapInsurance || data.gapInsurance <= 0)
    ) {
      setError('gapInsurance', {
        message: t('common.errors.number'),
      })
      return false
    }

    return true
  }

  const verifyExtendedDuration = (data: AutoWorksheet) => {
    if (
      data.extendedService &&
      data.extendedService > 0 &&
      (data.extendedServiceDuration === EExtendedServiceDuration.unknown || data.extendedServiceDuration === null)
    ) {
      setError('extendedServiceDuration', {
        message: t('worksheet.extendedServiceDurationIsRequired'),
      })
      return false
    }
    if (
      data.extendedService === null &&
      data.extendedServiceDuration !== EExtendedServiceDuration.unknown &&
      data.extendedServiceDuration !== null
    ) {
      setError('extendedService', {
        message: t('worksheet.extendedServiceIsRequired'),
      })
      return false
    }

    if (
      data.extendedServiceDuration !== EExtendedServiceDuration.unknown &&
      data.extendedServiceDuration !== null &&
      (!data.extendedService || data.extendedService <= 0)
    ) {
      setError('extendedService', {
        message: t('common.errors.number'),
      })
      return false
    }
    return true
  }
  const verifyGapInsurance = (data: AutoWorksheet) => {
    if (data.gapInsurance === null && data.gapInsuranceDuration === EGapInsuranceDuration.unknown) {
      return true
    }
    if (data.gapInsuranceDuration !== EGapInsuranceDuration.unknown && data.gapInsurance !== null) {
      if (data.gapInsuranceDuration === EGapInsuranceDuration.lessThanSixtyMonths && data.gapInsurance > 2000) {
        setError('gapInsurance', {
          message: t('worksheet.paramNames.maxGapInsuranceAmountForTermDurationLessThanSixtyMonths', '', {
            val: '2000',
          }),
        })
        return false
      }
      if (data.gapInsuranceDuration === EGapInsuranceDuration.sixtyMonthsOrMore && data.gapInsurance > 2500) {
        setError('gapInsurance', {
          message: t('worksheet.paramNames.maxGapInsuranceAmountForTermDurationSixtyMonthsOrMore', '', { val: '2500' }),
        })
        return false
      }
    }
    return true
  }

  const verifiyExtendedService = (data: AutoWorksheet) => {
    if (data.extendedService !== null && data.extendedServiceDuration !== null) {
      if (data.extendedServiceDuration === EExtendedServiceDuration.twelveMonths && data.extendedService > 1200) {
        setError('extendedService', {
          message: t('worksheet.paramNames.maxExtendedServiceAmountForTwelveMonths', '', { val: '1200' }),
        })
        return false
      }
      if (data.extendedServiceDuration === EExtendedServiceDuration.twentyFourMonths && data.extendedService > 2500) {
        setError('extendedService', {
          message: t('worksheet.paramNames.maxExtendedServiceAmountForTwentyFourMonths', '', { val: '2500' }),
        })
        return false
      }
      if (data.extendedServiceDuration === EExtendedServiceDuration.thirtySixMonth && data.extendedService > 3000) {
        setError('extendedService', {
          message: t('worksheet.paramNames.maxExtendedServiceAmountForThirtySixMonths', '', { val: '3000' }),
        })
        return false
      }
    }

    return true
  }

  const ensureDataAreWithinLimit = (data: AutoWorksheet) => {
    return (
      verifyGapDuration(data) &&
      verifyExtendedDuration(data) &&
      verifyFirstPaymentDate(data) &&
      verifyGapInsurance(data) &&
      verifiyExtendedService(data) &&
      verifyTerm(data)
    )
  }

  const saveWorksheet = (data: AutoWorksheet) => {
    return dispatchEffect(worksheetEffects.update(data)).then(() =>
      navigate(`/Applications/${EFinancingProgram.Auto}/${creditApplication?.id}/view`, { replace: true }),
    )
  }

  const submitWorkSheet = (data: AutoWorksheet) => {
    if (ensureDataAreWithinLimit(data)) {
      data.status = EWorksheetStatus.Active
      saveWorksheet(data).catch(reportErrorToConsole)
    }
  }

  const saveDraft = () => {
    const data = getValues()
    data.status = EWorksheetStatus.Draft
    saveWorksheet(data).catch(reportErrorToConsole)
  }

  return (
    <div>
      <Breadcrumb trees={breadCrumbs} />
      <div style={{ height: '100%' }}>
        <Paper>
          <PageError errors={error} />
          <input type="hidden" value=" " {...register('id')} />
          <ProgramSectionEdit
            worksheet={worksheet!}
            onSubmit={handleSubmit(submitWorkSheet, reportErrorToConsole)}
            onSaveDraft={saveDraft}
            creditApplication={creditApplication!}
            merchant={merchant}
          />

          <VehicleForm
            defaultVehicle={worksheet!.vehicle}
            merchant={merchant}
            control={control}
            getValues={getValues}
            setValue={setValue}
            register={register}
            watch={watch}
            errors={errors.vehicle as Merge<FieldError, FieldErrorsImpl<Vehicle>>}
            name="vehicle"
            title={t('worksheet.vehicle')}
            editDisabled={editDisabled}
          />

          <VehicleForm
            defaultVehicle={worksheet!.tradeInVehicle}
            merchant={merchant}
            control={control}
            getValues={getValues}
            setValue={setValue}
            register={register}
            watch={watch}
            errors={errors.tradeInVehicle as Merge<FieldError, FieldErrorsImpl<Vehicle>>}
            isTradeIn
            name="tradeInVehicle"
            title={t('worksheet.tradeInVehicle')}
            editDisabled={editDisabled}
          />

          <TransactionForm
            control={control}
            currentCreditApplication={creditApplication!}
            applicableTaxOnInsurances={worksheet!.applicableTaxOnInsurances}
            setValue={setValue}
            reset={reset}
            register={register}
            worksheet={worksheet!}
            errors={errors as Merge<FieldError, FieldErrorsImpl<AutoWorksheet>>}
            maxAdmissibleTerm={vehicleMaxTerm}
            creditApplicationInterestRate={creditApplication!.finalCreditDecision.interestRate}
            watch={watch}
          />
        </Paper>
      </div>
    </div>
  )
}

export default EditWorksheetPage
