import { CreditApplication } from '../../types'
import { SetCustomerReferenceDto } from '../../types/CustomerReferenceSchema'
import { makeUpdate } from '../make-api'

const applicantUrl = '/Applications/{financingProgramId}/{creditApplicationId}/Applicant/ExistingCustomer'
const coApllicantUrl = '/Applications/{financingProgramId}/{creditApplicationId}/Coapplicant/ExistingCustomer'
const customerReferenceApi = {
  setApplicantCustomerReference: makeUpdate<SetCustomerReferenceDto, CreditApplication>(applicantUrl),
  setCoapplicantCustomerReference: makeUpdate<SetCustomerReferenceDto, CreditApplication>(coApllicantUrl),
}

export default customerReferenceApi
