import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  TextField,
} from '@mui/material'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { every } from 'lodash-es'
import { reportErrorToConsole } from '@src/services/error-logger'
import { useAppSelector } from '../../data/store'
import { appSelectors } from '../../data/store/AppStore'
import { Applicant } from '../../data/types'

type TestCase = {
  id: string
  applicantDecisionAuto: string
  applicant: Applicant
  description: string
}

type TestCaseItemProps = {
  onTestCaseSelected: (applicant: Applicant) => void
  testcase: TestCase
  selected: boolean
}

const TestCaseItem = ({ onTestCaseSelected, testcase, selected }: TestCaseItemProps) => {
  const { applicant } = testcase

  const primaryText = `${applicant.firstName} ${applicant.lastName} (${applicant.birthDate})`
  return (
    <>
      <ListItemButton onClick={() => onTestCaseSelected(testcase.applicant)} selected={selected}>
        <ListItemText primary={primaryText} secondary={testcase.description} />
      </ListItemButton>
      <Divider />
    </>
  )
}

function loadTestCases(): Promise<TestCase[]> {
  const payload: AxiosRequestConfig = {
    method: 'GET',
    url: 'https://func-iceberg-proxies-dev.azurewebsites.net/CreditTestCases',
  }
  return axios.request(payload).then((response) => {
    const res = response.data as TestCase[]
    res.forEach((x: TestCase, index) => {
      x.id = index.toString()
    })
    return response.data as TestCase[]
  })
}

async function anonymizeTestCases(client: AxiosInstance, applicant: Applicant): Promise<void> {
  const payload: AxiosRequestConfig = {
    method: 'POST',
    url: 'ResetTestCases',
    data: applicant,
  }
  return client.request(payload)
}

const ManageTestCasePage = () => {
  const [selectedApplicant, setSelectedApplicant] = useState<Applicant | null>(null)
  const [testcases, setTestCases] = useState<TestCase[]>([])
  const [filteredTestCases, setFilteredTestCases] = useState<TestCase[]>([])
  const [textFilter, setTextFilter] = useState('')
  const [isLoading, setLoading] = useState(false)
  const apiClient = useAppSelector(appSelectors.getApiClient)

  useEffect(() => {
    setLoading(true)
    loadTestCases()
      .then((values: TestCase[]) => {
        setLoading(false)
        setTestCases(values)
      })
      .catch(reportErrorToConsole)
  }, [setLoading, setTestCases])

  useEffect(() => {
    const filters = textFilter.toLowerCase().split(' ')
    const match = testcases.filter((testcase) => {
      return every(
        filters,
        (f) =>
          testcase.applicant.firstName.toLowerCase().indexOf(f) > -1 ||
          testcase.applicant.lastName.toLowerCase().indexOf(f) > -1 ||
          testcase.description.toLowerCase().indexOf(f) > -1,
      )
    })
    setFilteredTestCases(match)
  }, [textFilter, testcases])

  return (
    <Paper>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12}>
          <h1>Gestion des cas de test</h1>
          <p>
            Séléctionner un cas de test parmis les éléments suivantes
            <br />
            Toute demande de crédit pour le cas de teste séléctionner sera dépersonalisé (nom, prénom de
            l&apos;applicant modifié ainsi que <i>existing customer</i> disassocié).
            <br />
            Cette action est irréversible.
          </p>
        </Grid>
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={3}>
            <TextField
              fullWidth
              name="filter"
              onChange={(e) => setTextFilter(e.target.value)}
              value={textFilter}
              label="Filtre"
            />
          </Grid>
          <Grid item xs={9} container justifyContent="flex-end">
            <Button
              type="button"
              variant="contained"
              color="error"
              onClick={() => window.history.back()}
              style={{ marginRight: '10px' }}
            >
              Annuler
            </Button>
            <Button
              type="button"
              variant="contained"
              color="success"
              onClick={async () => {
                if (selectedApplicant && apiClient)
                  await anonymizeTestCases(apiClient, selectedApplicant).then(() => window.history.back())
              }}
              disabled={!selectedApplicant}
            >
              Dépersonnaliser
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box height={700} paddingTop={2} sx={{ overflowY: 'auto' }}>
            {isLoading && <CircularProgress />}
            {!isLoading && filteredTestCases.length > 0 && (
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {filteredTestCases.map((testcase) => (
                  <TestCaseItem
                    key={testcase.id}
                    testcase={testcase}
                    onTestCaseSelected={setSelectedApplicant}
                    selected={testcase.applicant === selectedApplicant}
                  />
                ))}
              </List>
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ManageTestCasePage
