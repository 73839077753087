import { FullCreditApplication } from '../../types'
import { transformCreditApplicationFromApi } from '../credit-application/credit-transform'
import { transformListCreditTasksWithCommentsFromApi } from '../credit-task/credit-task-transform'
import { FullCreditApplicationDto } from './FullCreditApplicationDto'

export default function transformFullCreditApplicationFromApi(
  fullCreditApplication: FullCreditApplicationDto,
): FullCreditApplication {
  const ret = { ...fullCreditApplication } as FullCreditApplication

  ret.credit = transformCreditApplicationFromApi(fullCreditApplication.credit)
  ret.tasks = transformListCreditTasksWithCommentsFromApi(
    fullCreditApplication.tasks,
    fullCreditApplication.taskComments,
  )

  return ret
}
