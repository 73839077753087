import type { ValueListDto, ValueListItemDto } from '../../api/config/config-api'
import { ValueListItem } from '../../types'

export default function buildValueList(list: ValueListDto | null, lang: string) {
  if (!list) return []

  const inputList: ValueListItemDto[] = [...list.items]
  if (list.sortMethod === 'priority') inputList.sort((x) => x.priority ?? 0)

  const items: ValueListItem[] = inputList.map((x) => {
    return {
      id: x.value,
      text: lang === 'fr' ? x.textFr : x.textEn,
    }
  })
  if (list.sortMethod === 'alpha') items.sort((one, two) => (one.text > two.text ? -1 : 1))
  return items
}
