import { CreditApplication, EFinancingProgram } from '../../types'
import { buildUrlWithIds, makeCreate } from '../make-api'

const baseUrl = '/Applications/{financingProgramId}/{creditApplicationId}/loan'

const loanApi = {
  create: (financingProgramId: EFinancingProgram, creditApplicationId: string) =>
    makeCreate<CreditApplication>(buildUrlWithIds(baseUrl, { financingProgramId, creditApplicationId })),
}

export default loanApi
