import { CreditApplication } from '../../types'
import { FinalCreditDecision } from '../../types/CreditDecisionSchema'
import { makeUpdate } from '../make-api'

function transformToApi(decision: FinalCreditDecision): FinalCreditDecision {
  const update = { ...decision }
  if (update.existingCarLoanStatus === '') update.existingCarLoanStatus = null
  if (update.maxAmountFinanced === null) update.maxAmountFinanced = 0
  return update
}

const BASE_URL = '/Applications/{financingProgramId}/{creditApplicationId}/FinalDecision'
const creditDecisionApi = {
  update: makeUpdate<FinalCreditDecision, CreditApplication>(BASE_URL, transformToApi),
}

export default creditDecisionApi
