import { Button } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EFinancingProgram } from '@src/data/types'
import CopyLoanAsDraftDialog from './CopyLoanAsDraftDialog'

interface CopyLoanAsDraftBtnProps {
  financingProgramId: EFinancingProgram
  creditApplicationId: string
  disabled?: boolean
}

const CopyLoanAsDraftBtn = ({ creditApplicationId, financingProgramId, disabled = false }: CopyLoanAsDraftBtnProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const handleClose = useCallback(() => setOpen(false), [setOpen])

  return (
    <>
      <Button variant="outlined" size="large" disabled={disabled} onClick={() => setOpen(true)}>
        {t('editCreditApplication.copyAsDraft.btn')}
      </Button>
      <CopyLoanAsDraftDialog
        open={open}
        onClose={handleClose}
        creditApplicationId={creditApplicationId}
        financingProgramId={financingProgramId}
      />
    </>
  )
}

export default React.memo(CopyLoanAsDraftBtn)
