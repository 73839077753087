import { AxiosInstance } from 'axios'
import allApi from '../../api'

const reportAllApplicationEffect = {
  getReportAllApplication: () => {
    return async (apiClient: AxiosInstance): Promise<void> => {
      const ret = await allApi.reportAllApplication.getReportAllApplication(apiClient)
      return ret
    }
  },
}

export default reportAllApplicationEffect
