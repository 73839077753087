import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { AxiosInstance } from 'axios'
import type { TRootState } from '..'
import allApi from '../../api'
import { ReportPayoutThatSkippedIncomeValidationDatas } from '../../types/ReportPayoutWithoutFlinkData'
import { apiStateActions } from '../ApiStateStore'
import { appActions } from '../AppStore'
import { GET_PAYOUT_THAT_SKIPPED_INCOME_VALIDATION_DATAS, reportSlice } from './report-store'

const reportEffect = {
  getPayoutThatSkippedIncomeValidationDatas: () => {
    return async (
      apiClient: AxiosInstance,
      dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
      /* select: TypedUseSelectorHook<TRootState>, */
    ): Promise<ReportPayoutThatSkippedIncomeValidationDatas[]> => {
      try {
        // log api is loading
        dispatch(apiStateActions.logApiCallInitiated(GET_PAYOUT_THAT_SKIPPED_INCOME_VALIDATION_DATAS))
        dispatch(appActions.setBusinessErrors([]))

        // perform actual request
        const ret = await allApi.report.getPayoutThatSkippedIncomeValidation(apiClient)
        dispatch(reportSlice.actions.setPayoutThatSkippedIncomeValidationDatas(ret))
        return ret
      } finally {
        dispatch(apiStateActions.logApiCallCompleted(GET_PAYOUT_THAT_SKIPPED_INCOME_VALIDATION_DATAS))
      }
    }
  },
}

export default reportEffect
