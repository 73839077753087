import * as yup from 'yup'
import { EFinancingCompany } from './constants'

export interface LoginCredentials {
  username: string
  password: string
  companyIds: EFinancingCompany[]
}

export const LoginCredentialsSchema = yup.object({
  username: yup.string().required(),
  password: yup.string().required(),
})
