import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ELanguage, EProvince } from '@src/data/types'

type Props = {
  correspondanceLanguageId: ELanguage
  applicantStateIso: EProvince | null
}

const LanguageProvinceIndicator = ({ correspondanceLanguageId, applicantStateIso }: Props) => {
  const { t } = useTranslation()

  return (
    <Typography
      variant="body1"
      component="div"
      sx={{ border: '2px solid', borderRadius: 2, p: 1, mb: 1, borderColor: 'error.main', float: 'right' }}
    >
      {t(`enum.eLanguageAbbreviation.${correspondanceLanguageId}`)} | {applicantStateIso}
    </Typography>
  )
}

export default LanguageProvinceIndicator
