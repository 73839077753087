import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'
import { Bankruptcy } from '@src/data/types'
import { formatDate } from '@src/services/Formatter'
import Row from './tableRow'

type BankruptcyHistoryProps = {
  bankruptcys: Bankruptcy[]
}

const BankruptcyHistory = ({ bankruptcys }: BankruptcyHistoryProps) => {
  const { t } = useTranslation()
  return (
    <Row id="bankruptcy" name={t('creditReport.bankruptcyHistory')}>
      <TableContainer>
        <Table sx={{ display: 'contents' }} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>{t('creditReport.bankruptcies.registrationDate')}</TableCell>
              <TableCell align="left">{t('creditReport.bankruptcies.courtNumber')}</TableCell>
              <TableCell align="left">{t('creditReport.bankruptcies.courtName')}</TableCell>
              <TableCell align="left">{t('creditReport.bankruptcies.superintendentNumber')}</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">{t('creditReport.bankruptcies.registeredBy')}</TableCell>
              <TableCell align="left">{t('creditReport.bankruptcies.intentionOrDisposition')}</TableCell>
              <TableCell align="left">{t('creditReport.bankruptcies.responsibilityAmount')}</TableCell>
              <TableCell align="left">{t('creditReport.bankruptcies.assetsAmount')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bankruptcys.map((item) => (
              <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {formatDate(item.filedD)}
                </TableCell>
                <TableCell align="left">{item.courtCustomerNumber}</TableCell>
                <TableCell align="left">{item.courtName}</TableCell>
                <TableCell align="left">{item.caseNumber}</TableCell>
                <TableCell align="left">{item.typeDesc}</TableCell>
                <TableCell align="left">{item.trusteeName}</TableCell>
                <TableCell align="left">{t(`enum.eBankruptcyTypes.${item.intentCode}`)}</TableCell>
                <TableCell align="left">{item.liabilityAmount}</TableCell>
                <TableCell align="left">{item.assetAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Row>
  )
}

export default BankruptcyHistory
