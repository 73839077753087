import { addDays } from 'date-fns'

const getIFinanceMaxPaymentDateGivenActivationDate = (activationDate: Date) => {
  const baseDateForMaxDelay = addDays(activationDate, 30)
  const dayOfMonth = baseDateForMaxDelay.getUTCDate()

  if (dayOfMonth > 1 && dayOfMonth < 15) {
    baseDateForMaxDelay.setDate(1)
  } else if (dayOfMonth > 15 && dayOfMonth < 23) {
    baseDateForMaxDelay.setDate(15)
  } else if (dayOfMonth > 23) {
    baseDateForMaxDelay.setDate(23)
  }
  return baseDateForMaxDelay
}

export default getIFinanceMaxPaymentDateGivenActivationDate
