import { IApplicationInsights } from '@microsoft/applicationinsights-web'
import { nanoid } from 'nanoid'

let appInsights: IApplicationInsights | null = null
const loggedErrors: Error[] = []

export function getSessionId(): string {
  // Check if the sessionId exists in sessionStorage (per tab)
  let sessionId = sessionStorage.getItem('AppSessionId')

  if (!sessionId) {
    // Generate a new sessionId and store it in sessionStorage
    sessionId = nanoid()
    sessionStorage.setItem('AppSessionId', sessionId)
  }

  return sessionId
}

export function setupTracking(ai: IApplicationInsights): void {
  appInsights = ai
  appInsights.addTelemetryInitializer((envelope) => {
    const telemetryItem = envelope.data as {
      appSessionId?: string | undefined
      subscriptionsIFinanceAppVersion?: string | undefined
    }
    telemetryItem.appSessionId = getSessionId()
    telemetryItem.subscriptionsIFinanceAppVersion = import.meta.env.VITE_VERSION
  })
}

export function reportErrorToConsole(error: unknown) {
  console.error(error)
}
export function reportErrorToServer(error: Error) {
  if (loggedErrors.includes(error) === false) {
    reportErrorToConsole(error)
    appInsights?.trackException({ exception: error, severityLevel: 3 })
    loggedErrors.unshift(error)
  }
}
