import { Container } from '@mui/material'

const NotAuthorizedPage = (): JSX.Element => {
  return (
    <Container maxWidth="xl" disableGutters>
      You are not authorized to access this page
    </Container>
  )
}
export default NotAuthorizedPage
