import React from 'react'
import { Alert } from '@mui/material'
import translate from '@src/services/translate'
import { BusinessError } from '../data/types'

type Props = {
  errors: BusinessError[]
}

const PageError = ({ errors }: Props): JSX.Element | null => {
  if (errors.length === 0) return null

  return (
    <div>
      {errors.map((error) => (
        <Alert severity="error" key={error.message}>
          {translate(error.message, error.params)}
        </Alert>
      ))}
    </div>
  )
}

export default PageError
