import { useAccount, useMsal } from '@azure/msal-react'
import { Button, Menu, MenuItem } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../data/store'
import { userEffects } from '../../../data/store/UserStore'
import { userSelectors } from '../../../data/store/UserStore/user-store'
import { useDevTools } from '../../../data/types'

const UserMenu = (): JSX.Element => {
  const { t } = useTranslation()
  const menuStyle = { textDecoration: 'none' }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { instance } = useMsal()
  const account = useAccount()
  const navigate = useNavigate()

  const open = Boolean(anchorEl)

  const user = useAppSelector(userSelectors.getUser)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <>
        <Button
          id="btnAdmin"
          aria-controls={open ? 'admin-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          Admin
        </Button>
        <Menu id="admin-menu" anchorEl={anchorEl} open={open && anchorEl?.id === 'btnAdmin'} onClose={handleClose}>
          <MenuItem
            style={menuStyle}
            onClick={() => {
              window.open(import.meta.env.VITE_MERCHANT_APP_URL, '_blank')
            }}
          >
            {t('common.merchantManagement')}
          </MenuItem>
          {useDevTools && (
            <MenuItem style={menuStyle} onClick={() => navigate('/manageTestCases')}>
              {t('common.testCaseManagement')}
            </MenuItem>
          )}
        </Menu>
      </>

      <>
        <Button
          id="btnUser"
          aria-controls={open ? 'user-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          {user?.name}
        </Button>
        <Menu id="user-menu" anchorEl={anchorEl} open={open && anchorEl?.id === 'btnUser'} onClose={handleClose}>
          <MenuItem
            style={menuStyle}
            onClick={() => {
              userEffects.logout(instance, account)
            }}
          >
            {t('menus.user.logout')}
          </MenuItem>
        </Menu>
      </>
    </>
  )
}

export default UserMenu
