import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { TRootState } from '..'
import { ElectronicSignature } from '../../types/ElectronicSignature'

export const electronicSignatureSlice = createSlice({
  name: 'electronicSignature',
  initialState: {
    electronicSignature: {} as ElectronicSignature,
  },
  reducers: {
    setElectronicSignature: (state, action: PayloadAction<ElectronicSignature>) => {
      state.electronicSignature = action.payload
    },
  },
})

export default electronicSignatureSlice.reducer

export const electronicSignatureActions = electronicSignatureSlice.actions

export const RESEND_LINK_ELECTRONIC_SIGN = 'electronicSignature/resendLink'

const electronicSignatureSelectorsPartial = {
  getElectronicSignature: (state: TRootState) => state.electronicSignature.electronicSignature,
  getElectronicSignatureStatus: (state: TRootState) => state.electronicSignature.electronicSignature?.currentStep,
}

export const electronicSignatureSelectors = {
  ...electronicSignatureSelectorsPartial,
}
