import { PreviousApplicationInputDto } from '../../types/PreviousApplicationInputDto'
import { PreviousApplication } from '../../types/PreviousApplicationSchema'
import { makeCreate } from '../make-api'

const BASE_URL = '/PreviousApplication'
const previousApplicationApi = {
  getByFilters: makeCreate<PreviousApplicationInputDto, PreviousApplication[]>(BASE_URL),
}

export default previousApplicationApi
