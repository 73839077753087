import React from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import Clear from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { CreditApplicationTask } from '../../../data/types'
import { ETasksStatus } from '../../../data/types/constants'

type Props = { task: CreditApplicationTask | undefined }

const TaskStatus = ({ task }: Props) => {
  const renderComponentForSpecificStatus = () => {
    if (task) {
      switch (task.status) {
        case ETasksStatus.Completed:
          return <CheckCircleOutlineIcon color="success" />
        case ETasksStatus.Cancelled:
          return <Clear color="error" />
        case ETasksStatus.ToDo:
        default:
          return <SearchIcon />
      }
    }
    return <SearchIcon />
  }

  return <div>{renderComponentForSpecificStatus()}</div>
}

export default TaskStatus
