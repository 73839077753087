import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  IconButton,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { ActionsDialog, InputTextField } from '../../../components'
import { vehicleEvaluationDTO, VehicleEvaluationDTO } from '../../../data/types'
import { Document } from '../../../data/types/DocumentSchema'
import VehicleEvaluationFileRow from './VehicleEvaluationFileRow'

export type VehicleEvaluationFormData = {
  vehicleEvaluationDTO: VehicleEvaluationDTO | null
  vehicleEvaluationFile: File | null
}

type Props = {
  title: string
  onConfirm: (data: VehicleEvaluationFormData) => void
  onCancel: () => void
  open: boolean
  initialInternalEvaluationValue: VehicleEvaluationDTO
  vautoDocument: Document | null
  onDeleteDoc: (doc: Document) => void
}

const EditVehicleEvaluationDialog = ({
  onConfirm,
  onCancel,
  title,
  open,
  initialInternalEvaluationValue,
  vautoDocument,
  onDeleteDoc,
}: Props) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<VehicleEvaluationDTO>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: { ...initialInternalEvaluationValue },
    resolver: yupResolver(vehicleEvaluationDTO),
  })
  const { t } = useTranslation()
  const [file, setFile] = React.useState<File | null>(null)
  const [isDelete, setIsDelete] = React.useState<boolean>(false)

  const onDeleteCliced = () => {
    setIsDelete(true)
  }

  const onConfirmPartial = (data: VehicleEvaluationDTO) => {
    if (
      (file && data.vehicleValue) ||
      (!file && !data.vehicleValue && !vautoDocument) ||
      (!file && !data.vehicleValue && isDelete) ||
      (vautoDocument && data.vehicleValue && !isDelete)
    ) {
      if (isDelete) {
        if (vautoDocument) {
          onDeleteDoc(vautoDocument)
        }
      }
      if (vautoDocument && !isDelete && !file) {
        onConfirm({ vehicleEvaluationDTO: data, vehicleEvaluationFile: null } as VehicleEvaluationFormData)
      } else {
        onConfirm({ vehicleEvaluationDTO: data, vehicleEvaluationFile: file } as VehicleEvaluationFormData)
      }
      setFile(null)
    } else {
      if (!file) {
        setError('vehicleValue', { type: 'custom', message: t('worksheet.vehicleEvaluationFileMissing') })
      }
      if (!data.vehicleValue) {
        setError('vehicleValue', { type: 'custom', message: t('worksheet.vehicleEvaluationIsMissing') })
      }
    }
  }

  return (
    <ActionsDialog
      title={title}
      open={open}
      onCancel={() => {
        setFile(null)
        setIsDelete(false)
        onCancel()
      }}
      onConfirm={handleSubmit(onConfirmPartial)}
    >
      <Grid container marginTop={2} spacing={2}>
        <Grid item>
          <InputTextField
            id="vehicleValue"
            label={t('worksheet.sideSection.marketValue')}
            {...register('vehicleValue')}
            error={errors?.vehicleValue}
          />
          <InputTextField
            label={t('worksheet.sideSection.discretionaryAllocation')}
            {...register('discretionaryAllocation')}
            error={errors?.discretionaryAllocation}
          />
          <InputTextField
            label={t('worksheet.sideSection.carfax')}
            {...register('carfaxValue')}
            error={errors?.carfaxValue}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{ minWidth: '30vh', mt: 3 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">{t('common.file')}</TableCell>
              <TableCell align="right">
                <IconButton aria-label="delete" component="label">
                  <EditIcon color="primary" />
                  <input
                    type="file"
                    accept=".pdf"
                    hidden
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const { files } = event.target
                      if (files) {
                        setFile(files[0])
                        setIsDelete(false)
                      }
                    }}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {file && !isDelete && <VehicleEvaluationFileRow fileName={file.name} onDeleteDoc={onDeleteCliced} />}
            {vautoDocument && !isDelete && vautoDocument.fileName && !file && (
              <VehicleEvaluationFileRow fileName={vautoDocument.fileName} onDeleteDoc={onDeleteCliced} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </ActionsDialog>
  )
}

export default EditVehicleEvaluationDialog
