import { CreditTaskDashboardEntry } from '../../types/CreditTaskDashboardEntry'
import { makeGetListFromApi, makeGetWithFiltersFromApi, makeGetStringListFromApi } from '../make-api'
import { StatusCount } from '../../types'

const BASE_URL = '/CreditTaskDashboardEntries'
const STATUS_COUNT_BASE_URL = 'CreditTaskDashboardEntries/StatusCount'
const TRUSTEE_NAMES_LIST = '/CreditTaskDashboardEntries/BankruptcyTrusteeNames'

const creditTaskDashboardEntriesApi = {
  getByFilters: makeGetListFromApi<CreditTaskDashboardEntry>(BASE_URL),
  getStatusCount: makeGetWithFiltersFromApi<StatusCount>(STATUS_COUNT_BASE_URL),
  getTrusteeNames: makeGetStringListFromApi(TRUSTEE_NAMES_LIST),
}

export default creditTaskDashboardEntriesApi
