import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import translate from '@src/services/translate'
import type { TRootState } from '..'
import { AutoWorksheet, AutoWorksheetRevision, NormsMessage, VehicleInternalEvaluation } from '../../types'
import { ParameterizedMessage } from '../../types/ParameterizedMessage'
import { apiStateSelectors } from '../ApiStateStore'
import { appSelectors } from '../AppStore'

export const AutoworksheetSlice = createSlice({
  name: 'autoworksheet',
  initialState: {
    current: null as AutoWorksheet | null,
    vehicleInternalEvaluation: null as VehicleInternalEvaluation | null,
    currentRevisions: [] as AutoWorksheetRevision[],
  },
  reducers: {
    setCurrent: (state, action: PayloadAction<AutoWorksheet | null>) => {
      state.current = action.payload
      state.vehicleInternalEvaluation = action.payload?.vehicleEvaluation as VehicleInternalEvaluation
    },
    setVehicleInternalEvaluation: (state, action: PayloadAction<VehicleInternalEvaluation>) => {
      state.vehicleInternalEvaluation = action.payload
    },
    setCurrentRevisions: (state, action: PayloadAction<AutoWorksheetRevision[]>) => {
      state.currentRevisions = action.payload.sort((a, b) => {
        if (!a.updatedOn) return -1
        if (!b.updatedOn) return 1
        return a.updatedOn < b.updatedOn ? 1 : -1
      })
    },
  },
})

export default AutoworksheetSlice.reducer

export const autoWorksheetActions = AutoworksheetSlice.actions

export const GET_AUTO_WORKSHEET_BY_ID = 'autoWorksheet/getById'
export const SAVE_AUTO_WORKSHEET = 'autoWorksheet/save'
export const SAVE_VEHICLE_INTERNAL_EVALUATION = 'autoWorksheet/saveVehicleInternalEvaluation'
export const SAVE_DECISION = 'autoWorksheet/saveDecision'
export const GET_AUTO_WORKSHEET_REVISIONS = 'autoWorksheet/getWorksheetRevisions'

const autoWorksheetSelectorsPartial = {
  isLoadingWortsheet: (state: TRootState) => apiStateSelectors.isLoading(state, GET_AUTO_WORKSHEET_BY_ID),
  isSavingWorksheet: (state: TRootState) => apiStateSelectors.isLoading(state, SAVE_AUTO_WORKSHEET),

  getCurrent: (state: TRootState) => state.worksheet.current,
  getCurrentRevisions: (state: TRootState) => state.worksheet.currentRevisions,
}

function getParameterizedNormMessage(message: ParameterizedMessage): string {
  const translatedMessage = translate(`worksheet.norms.${message.id}`)

  let messageSuffix = ' | '
  message.parameters.forEach((p, i) => {
    if (i >= 1) messageSuffix = messageSuffix.concat(', ')

    const suffix = translate(`worksheet.paramNames.${p.name}`, { val: p.value })
    messageSuffix = messageSuffix.concat(suffix)
  })

  return translatedMessage.concat(messageSuffix)
}

const getCurrentWorksheetNormRefusalAndWarningMessages = createSelector(
  [autoWorksheetSelectorsPartial.getCurrent, appSelectors.getCurrentLang],
  (currentWorksheet) => {
    const warningsAndRefusals = currentWorksheet?.normsDecision?.warningMessages?.concat(
      currentWorksheet?.normsDecision?.refusalMessages,
    )

    const worksheetNormMessages: NormsMessage[] = []
    warningsAndRefusals?.forEach((wr) => {
      const builtMessage = getParameterizedNormMessage(wr)
      const worksheetNormMessage: NormsMessage = {
        id: wr.id,
        message: builtMessage,
        applicantType: null,
      }
      worksheetNormMessages.push(worksheetNormMessage)
    })
    return worksheetNormMessages
  },
)

const getCurrentWorksheetNormWarningMessages = createSelector(
  [autoWorksheetSelectorsPartial.getCurrent, appSelectors.getCurrentLang],
  (currentWorksheet) => {
    const worksheetNormMessages: NormsMessage[] = []
    currentWorksheet?.normsDecision?.warningMessages.forEach((wr) => {
      const builtMessage = getParameterizedNormMessage(wr)
      const worksheetNormMessage: NormsMessage = {
        id: wr.id,
        message: builtMessage,
        applicantType: null,
      }
      worksheetNormMessages.push(worksheetNormMessage)
    })

    return worksheetNormMessages
  },
)

const getCurrentWorksheetNormRefusalMessages = createSelector(
  [autoWorksheetSelectorsPartial.getCurrent, appSelectors.getCurrentLang],
  (currentWorksheet) => {
    const worksheetNormMessages: NormsMessage[] = []
    currentWorksheet?.normsDecision?.refusalMessages.forEach((wr) => {
      const builtMessage = getParameterizedNormMessage(wr)
      const worksheetNormMessage: NormsMessage = {
        id: wr.id,
        message: builtMessage,
        applicantType: null,
      }
      worksheetNormMessages.push(worksheetNormMessage)
    })

    return worksheetNormMessages
  },
)

export const autoWorksheetSelectors = {
  ...autoWorksheetSelectorsPartial,
  getCurrentWorksheetNormRefusalAndWarningMessages,
  getCurrentWorksheetNormWarningMessages,
  getCurrentWorksheetNormRefusalMessages,
}
