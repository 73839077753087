import { EDocumentType, ERotationType } from '../../data/types'
import { DocumentPageDisplayInfo } from '../../data/types/DocumentPageDisplayInfo'

export const flip = (rotation: ERotationType) => {
  if (rotation === ERotationType.UPRIGHT || !rotation) {
    return ERotationType.UPSIDEDOWN
  }
  if (rotation === ERotationType.QUARTERTURN) {
    return ERotationType.THREEQUARTERTURN
  }
  if (rotation === ERotationType.UPSIDEDOWN) {
    return ERotationType.UPRIGHT
  }

  return ERotationType.QUARTERTURN
}

export const rotateLeft = (rotation: ERotationType) => {
  if (rotation === ERotationType.UPRIGHT || !rotation) {
    return ERotationType.THREEQUARTERTURN
  }
  if (rotation === ERotationType.QUARTERTURN) {
    return ERotationType.UPRIGHT
  }
  if (rotation === ERotationType.UPSIDEDOWN) {
    return ERotationType.QUARTERTURN
  }
  return ERotationType.UPSIDEDOWN
}

export const rotateRight = (rotation: ERotationType) => {
  if (rotation === ERotationType.UPRIGHT || !rotation) {
    return ERotationType.QUARTERTURN
  }
  if (rotation === ERotationType.QUARTERTURN) {
    return ERotationType.UPSIDEDOWN
  }
  if (rotation === ERotationType.UPSIDEDOWN) {
    return ERotationType.THREEQUARTERTURN
  }
  return ERotationType.UPRIGHT
}

export const flipAll = (pages: DocumentPageDisplayInfo[]) => {
  pages.forEach((page, index) => {
    pages[index].rotation = flip(page.rotation)
  })
  return pages
}

export const rotateRightAll = (pages: DocumentPageDisplayInfo[]) => {
  pages.forEach((page, index) => {
    pages[index].rotation = rotateRight(page.rotation)
  })
  return pages
}

export const rotateLeftAll = (pages: DocumentPageDisplayInfo[]) => {
  pages.forEach((page, index) => {
    pages[index].rotation = rotateLeft(page.rotation)
  })
  return pages
}

export const DocumentRequiredApplicantType = [
  EDocumentType.ProofOfResidence,
  EDocumentType.LastPayStubJob,
  EDocumentType.LastPayStubsJobTwo,
  EDocumentType.BandCouncilCard,
  EDocumentType.SecondIdWithPhoto,
  EDocumentType.ThreeMonthsPersonalBankStatements,
  EDocumentType.EmployerForm,
  EDocumentType.LetterOfEmployment,
  EDocumentType.ProofOfBenefits,
  EDocumentType.ProofOfSourceOfFunds,
  EDocumentType.MaternityLeaveBenefit,
  EDocumentType.SinCardOrOfficialDocumentWithSin,
  EDocumentType.T4E,
  EDocumentType.T4,
  EDocumentType.AlimonyCourtJudgment,
  EDocumentType.SixMonthsPersonalBankStatements,
  EDocumentType.ValidDriverLicense,
  EDocumentType.WorkPermit,
  EDocumentType.AuthorizationForm,
  EDocumentType.PreAuthorizedDebitForm,
  EDocumentType.ChildTaxBenefitStatement,
  EDocumentType.EmploymentInsuranceStatement,
  EDocumentType.EmploymentContract,
  EDocumentType.ExtraPaymentAuthorization,
  EDocumentType.InvoiceForPersonalService,
  EDocumentType.NoticeOfAssessment,
  EDocumentType.ProofOfIncome,
  EDocumentType.ProofOfPayment,
  EDocumentType.RentalAgreement,
  EDocumentType.RecordOfEmployment,
  EDocumentType.T1,
  EDocumentType.T5,
  EDocumentType.UtilityBill,
  EDocumentType.EquifaxOrTransUnionNotice,
  EDocumentType.ValidPhotoId,
]

export function requiredApplicant(docTypeId: number) {
  return (DocumentRequiredApplicantType as number[]).includes(docTypeId)
}
