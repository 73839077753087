import * as React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import { FormHelperText } from '@mui/material'
import { FieldError } from 'react-hook-form'
import translate from '@src/services/translate'
import translateErrorCode from './TranslateError'
import { SelectValueListItem } from '../data/types'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type Props = {
  title: string
  content: Array<SelectValueListItem>
  error?: FieldError
  defaultValues?: string[] | number[]
  disabled?: boolean
  onValueChanged?: (value: string[] | number[]) => void
}

const MultipleSelectCheckmarks = React.forwardRef<HTMLSelectElement, Props>(
  ({ title, content, error, defaultValues = [], disabled = false, onValueChanged, ...otherProps }: Props, ref) => {
    const [selectedElements, setSelectedElements] = React.useState<string[] | number[]>(defaultValues ?? [])

    React.useEffect(() => {
      if (defaultValues) {
        setSelectedElements(defaultValues)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (event: SelectChangeEvent<typeof selectedElements>) => {
      const {
        target: { value },
      } = event
      const values = typeof value === 'string' ? value.split(',') : value

      setSelectedElements(
        // On autofill we get a stringified value.
        values,
      )

      if (onValueChanged) onValueChanged(values)
    }

    return (
      <div>
        <FormControl fullWidth sx={{ minWidth: 120 }} disabled={disabled}>
          <InputLabel id="multiple-checkbox-label" shrink>
            {title}
          </InputLabel>
          <Select
            inputRef={ref}
            inputProps={{ ...otherProps }}
            labelId="multiple-checkbox-label"
            id="multiple-checkbox"
            multiple
            value={selectedElements}
            onChange={handleChange}
            input={<OutlinedInput notched label={title} />}
            renderValue={(selected) => {
              const labels: string[] = []
              selected.forEach((item: string | number) => {
                labels.push(translate(content.find((x) => x.value === item)!.label as string))
              })
              return labels.join(', ')
            }}
            MenuProps={MenuProps}
          >
            {content.map((element) => (
              <MenuItem key={element.value} value={element.value}>
                <Checkbox checked={selectedElements.findIndex((x: string | number) => x === element.value) > -1} />
                <ListItemText primary={translate(`${element.label}`)} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{translateErrorCode(error)}</FormHelperText>
        </FormControl>
      </div>
    )
  },
)

export default MultipleSelectCheckmarks
