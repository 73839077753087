import React, { ReactElement } from 'react'
import { IconButton, Tooltip } from '@mui/material'

type Props = {
  tooltip: string
  icon: ReactElement
  onClick?: React.MouseEventHandler | undefined
  disabled?: boolean
  visibility?: string
  color?: string | null
}

const IconButtonWithTooltip = ({
  tooltip,
  onClick,
  icon,
  disabled = false,
  visibility = 'visible',
  color = null,
  ...ohterProps
}: Props): JSX.Element => {
  return (
    <Tooltip title={tooltip} arrow placement="top" disableHoverListener={visibility === 'hidden'}>
      <span>
        <IconButton
          {...ohterProps}
          disabled={disabled}
          onClick={onClick}
          sx={{ visibility: visibility || 'visible', color: color || null }}
        >
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default IconButtonWithTooltip
