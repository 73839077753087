import { AxiosInstance } from 'axios'
import allApi from '../../api'

const reportBenoitEffect = {
  getReportBenoit: () => {
    return async (apiClient: AxiosInstance): Promise<void> => {
      const ret = await allApi.reportBenoit.getReportBenoit(apiClient)
      return ret
    }
  },
}

export default reportBenoitEffect
