import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { findIndex } from 'lodash-es'
import type { TRootState } from '..'
import { apiStateSelectors } from '../ApiStateStore'
import { CreditTaskDashboardEntry } from '../../types/CreditTaskDashboardEntry'
import { CreditApplicationReservation } from '../../types/CreditApplicationReservation'
import { StatusCount } from '../../types'

export const creditTaskDashboardEntrySlice = createSlice({
  name: 'creditTaskDashBoardEntry',
  initialState: {
    taskList: [] as CreditTaskDashboardEntry[],
    statusCount: null as StatusCount | null,
    trusteeNames: [] as string[],
  },
  reducers: {
    setTaskList: (state, action: PayloadAction<CreditTaskDashboardEntry[]>) => {
      state.taskList = action.payload
    },
    setStatusCount: (state, action: PayloadAction<StatusCount>) => {
      state.statusCount = action.payload
    },
    setTrusteeNames: (state, action: PayloadAction<string[]>) => {
      state.trusteeNames = action.payload
    },
    setReserve: (state, action: PayloadAction<CreditApplicationReservation>) => {
      const index = findIndex(state.taskList, { id: action.payload.creditApplicationId })
      const updated = state.taskList[index]
      updated.reservedUntil = action.payload.expiresOn
      updated.reservedByUserId = action.payload.userId
      updated.reservedByUserName = action.payload.userName
      state.taskList.splice(index, 1, updated)
    },
  },
})

export default creditTaskDashboardEntrySlice.reducer

export const creditTaskDashboardEntryActions = creditTaskDashboardEntrySlice.actions

export const GET_CREDIT_TASKS_DASHBOARD_ENTRY = 'creditTaskDashboard/getByFilters'
export const GET_STATUS_COUNT = 'creditTaskDashboard/getStatusCount'
export const RESERVE_CREDIT_TASK = 'creditTaskDashboard/reserve'
export const GET_TRUSTEE_NAMES_LIST = 'creditTaskDashboard/BankruptcyTrusteeNames'

const tasksSelectorsPartial = {
  isLoadingTaskList: (state: TRootState) => apiStateSelectors.isLoading(state, GET_CREDIT_TASKS_DASHBOARD_ENTRY),
  getTaskList: (state: TRootState) => state.creditTaskDashBoardEntry.taskList,
  getStatusCount: (state: TRootState) => state.creditTaskDashBoardEntry.statusCount,
  getTrusteeNames: (state: TRootState) => state.creditTaskDashBoardEntry.trusteeNames,
  getTrusteeNamesWithEmptyOption: (state: TRootState) => ['', ...state.creditTaskDashBoardEntry.trusteeNames],
}

export const creditTaskDashboardEntrySelectors = {
  ...tasksSelectorsPartial,
}
