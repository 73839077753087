import React, { useCallback } from 'react'
import { Button, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Applicant, EFinancingProgram } from '../../../data/types'
import CustomerAccountDialog from './customerAccountDialog'

type Props = {
  applicant: Applicant
  userCanApproveCreditApplication: boolean
  creditApplicationId: string
  financingProgramId: EFinancingProgram
  isApplicant: boolean
  editDisabled: boolean
}

const CustomerAccountInformation = ({
  applicant,
  userCanApproveCreditApplication,
  creditApplicationId,
  financingProgramId,
  isApplicant,
  editDisabled,
}: Props) => {
  const { t } = useTranslation()
  const [openCustomerAccountDialog, setOpenCustomerAccountDialog] = React.useState<boolean>(false)
  const theme = useTheme()

  const onCancel = useCallback(() => {
    setOpenCustomerAccountDialog(false)
  }, [])

  return (
    <>
      {!applicant?.relationWithApplicant && <p>{t('common.applicant')} : </p>}
      {applicant?.relationWithApplicant && <p>{t('common.coApplicant')} : </p>}
      {!applicant?.existingCustomerReference && (
        <Typography
          variant="subtitle1"
          sx={{ backgroundColor: theme.palette.action.selected, marginBottom: 3 }}
          component="p"
        >
          {t('customerAccount.agentMustSelect')}
        </Typography>
      )}
      {applicant?.existingCustomerReference?.customerId === '' && (
        <Typography
          variant="subtitle1"
          sx={{ backgroundColor: theme.palette.action.selected, marginBottom: 3 }}
          component="p"
        >
          {t('customerAccount.noAccountCorresponds')}
        </Typography>
      )}
      {applicant?.existingCustomerReference?.customerId && (
        <Typography
          variant="subtitle1"
          sx={{ backgroundColor: theme.palette.action.selected, marginBottom: 3 }}
          component="p"
        >
          {t('customerAccount.existingCustomerAssociated')}
        </Typography>
      )}
      {userCanApproveCreditApplication && (
        <Button
          id="btnSelectApplicant"
          variant="contained"
          size="large"
          sx={{
            mt: 2,
          }}
          disabled={editDisabled}
          onClick={() => {
            setOpenCustomerAccountDialog(true)
          }}
        >
          {isApplicant ? t('customerAccount.selectApplicant') : t('customerAccount.selectCoApplicant')}
        </Button>
      )}
      <CustomerAccountDialog
        open={openCustomerAccountDialog}
        onCancel={onCancel}
        applicant={applicant}
        creditApplicationId={creditApplicationId}
        financingProgramId={financingProgramId}
        title={`${t('customerAccount.associateCustomer')}`}
        isApplicant={isApplicant}
      />
    </>
  )
}

export default React.memo(CustomerAccountInformation)
