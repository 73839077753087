import { Button, Grid, Paper, Stack } from '@mui/material'
import { Breadcrumb, PageError } from '@src/components'
import { useAppDispatch, useAppSelector } from '@src/data/store'
import { creditSelectors } from '@src/data/store/CreditApplication'
import { useTranslation } from 'react-i18next'
import { FieldError, FieldErrorsImpl, Merge, useForm } from 'react-hook-form'
import { autoRslaWorksheetSelectors } from '@src/data/store/AutoRslaWorksheet/auto-rsla-worksheet-store'
import { yupResolver } from '@hookform/resolvers/yup'
import { EditAutoRslaWorksheetDto, EditAutoRslaWorksheetDtoSchema } from '@src/data/types/AutoRslaWorksheet'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { useNavigate } from 'react-router-dom'
import { reportErrorToConsole } from '@src/services/error-logger'
import autoRslaWorksheetEffects from '@src/data/store/AutoRslaWorksheet/auto-rsla-worksheet-effects'
import { fullCreditApplicationEffects } from '@src/data/store/FullCreditApplication/full-credit-application-effects'
import { compareAsc } from 'date-fns'
import { normalizeNumber } from '@src/services/Formatter'
import { appActions, appSelectors } from '../../data/store/AppStore'
import { EFinancingProgram, Vehicle, EWorksheetStatus } from '../../data/types'
import VehicleForm from './components/VehicleForm'
import TransactionForm from './components/TransactionForm'

const EditAutoRslaWorksheetPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const creditApplication = useAppSelector(creditSelectors.getCurrent)
  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm<EditAutoRslaWorksheetDto>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: {} as EditAutoRslaWorksheetDto,
    resolver: yupResolver(EditAutoRslaWorksheetDtoSchema),
  })
  const dispatch = useAppDispatch()
  const merchant = useAppSelector(creditSelectors.getMerchant)
  const dispatchEffect = useSideEffect()
  const breadCrumbs = [
    { path: '/', label: t('breadcrumbs.home') },
    { path: '/Applications/browse', label: t('breadcrumbs.creditApplication') },
    {
      path: `/Applications/${EFinancingProgram.AutoRsla}/${creditApplication?.id}/view`,
      label: (t('breadcrumbs.application') as string).concat(` #${creditApplication?.referenceNumber}`),
    },
    { path: '#', label: t('worksheet.financing') },
  ]
  const error = useAppSelector(appSelectors.getBusinessError)
  const worksheet = useAppSelector(autoRslaWorksheetSelectors.getCurrent)
  const easternTime = new Date().toLocaleString('en-US', { timeZone: 'America/Toronto' })
  const currentDateAsEasternTimeZone = new Date(easternTime)
  const onFormSubmitted = async (data: EditAutoRslaWorksheetDto) => {
    const expiry = new Date(creditApplication!.expiresOn)
    const amountRequested = normalizeNumber(watch('amountRequested')?.toString()) ?? 0
    const deliveryOnDate = new Date(`${getValues('deliveryOn')}T20:00:00`) // 20PM Local Time
    const deliveryOnUTC = new Date(`${getValues('deliveryOn')}T00:00:00`)
    const deliveryOnDateAsEasternTimeZone = new Date(
      deliveryOnDate.toLocaleString('en-US', { timeZone: 'America/Toronto' }),
    )
    const baseActivationDateAsEasternTimeZone = new Date(
      currentDateAsEasternTimeZone.getFullYear(),
      currentDateAsEasternTimeZone.getMonth(),
      currentDateAsEasternTimeZone.getDate(),
      0,
      0,
      0,
    )

    if (compareAsc(deliveryOnDateAsEasternTimeZone, baseActivationDateAsEasternTimeZone) === -1) {
      dispatch(appActions.setBusinessErrors([{ message: 'worksheetCommon.activationDateInPast' }]))
    } else if (compareAsc(deliveryOnUTC, expiry) === 1) {
      dispatch(appActions.setBusinessErrors([{ message: 'worksheetCommon.deliveryOnEarlierExpiration' }]))
    } else {
      data.amountRequested = amountRequested
      data.status = EWorksheetStatus.Active
      data.vehicle.pricingCategory = 'average'
      const adjustedWholePrices = data.vehicle.pricing.adjustedWholePrices
      if (adjustedWholePrices.avg > 0) {
        data.vehicle.thirdPartyEvaluationValue = adjustedWholePrices.avg
      }
      await dispatchEffect(autoRslaWorksheetEffects.update(data)).then(() =>
        dispatchEffect(fullCreditApplicationEffects.getById(creditApplication!.id, EFinancingProgram.AutoRsla)).then(
          () =>
            navigate(`/Applications/${EFinancingProgram.AutoRsla}/${creditApplication!.id}/view`, { replace: true }),
        ),
      )
    }
  }

  const isLoading = useAppSelector(autoRslaWorksheetSelectors.isSavingPersonalLoanWorksheet)

  return (
    <div>
      <Breadcrumb trees={breadCrumbs} />
      <form onSubmit={handleSubmit(onFormSubmitted, reportErrorToConsole)}>
        <div style={{ height: '100%' }}>
          <Paper>
            <PageError errors={error} />
            <input type="hidden" value=" " {...register('id')} />
            <Grid item xs={6} md={3.9} sx={{ flexDirection: 'row' }}>
              <Stack direction="row" justifyContent="flex-end" spacing={3}>
                <div style={{ marginTop: '1%' }}>
                  <Button variant="contained" type="submit" disabled={isLoading}>
                    {t('common.save')}
                  </Button>
                </div>
              </Stack>
            </Grid>
            <VehicleForm
              defaultVehicle={worksheet!.vehicle}
              merchant={merchant}
              control={control}
              getValues={getValues}
              setValue={setValue}
              register={register}
              watch={watch}
              errors={errors.vehicle as Merge<FieldError, FieldErrorsImpl<Vehicle>>}
              title={t('worksheet.vehicle')}
            />
            <TransactionForm
              control={control}
              currentCreditApplication={creditApplication!}
              reset={reset}
              register={register}
              worksheet={worksheet!}
              errors={errors as Merge<FieldError, FieldErrorsImpl<EditAutoRslaWorksheetDto>>}
              creditApplicationInterestRate={creditApplication!.finalCreditDecision.interestRate}
            />
          </Paper>
        </div>
      </form>
    </div>
  )
}

export default EditAutoRslaWorksheetPage
